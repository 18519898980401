import React from "react";
import { connect } from "react-redux";
import { login, checkAuthSession } from "../redux/actions";
import { Redirect } from "react-router-dom";

class LoginScreen extends React.Component {
  componentDidMount() {
    this.props.checkAuthSession();
  }

  onSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    const email = form[0].value;
    const pwd = form[1].value;
    this.props.login(email, pwd);
  };

  render() {
    return (
      <section>
        <h2>Se connecter</h2>
        <form onSubmit={this.onSubmit}>
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" />

          <label htmlFor="password">Mot de passe</label>
          <input type="password" name="password" id="password" />

          <button
            type="submit"
            disabled={this.props.auth.isFetching ? "disabled" : ""}
          >
            Envoyer
          </button>
        </form>
        <p>{this.props.auth.error ? this.props.auth.error.message : ""}</p>

        {this.props.auth.user ? <Redirect to="/" /> : ""}
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    checkAuthSession: () => dispatch(checkAuthSession()),
    login: (email, password) => dispatch(login(email, password))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen);
