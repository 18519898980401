// Render Prop
import React from "react";

export default class Infos extends React.Component {
  render() {
    const json = JSON.stringify(this.props.model, null, 2);
    return (
      <section>
        <textarea name="" id="" cols="60" rows="20" value={json} readOnly />
      </section>
    );
  }
}
