const items = {
  "0": {
    id: "0",
    type: "normal",
    title: "Quel est votre problème ?",
    propositions: [
      {
        content: "WC",
        nextStepId: "1",
        id: "p1"
      },
      {
        content: "Lavabo / Evier (y compris robinetterie)",
        nextStepId: "11",
        id: "p2"
      },
      {
        content: "Douche / baignoire (y compris robinetterie)",
        nextStepId: "18",
        id: "p3"
      },
      {
        content:
          "Tuyauterie et canalisation (pas de lien direct à votre w,lavabo,douche ou baignoire)",
        nextStepId: "22",
        id: "p4"
      },
      {
        content: "Tuyau de la machine à laver ou du lave-vaisselle",
        nextStepId: "140",
        id: "p5"
      },
      {
        content: "Chaudière,chauffe-eau, et ballon d'eau chaude",
        nextStepId: "23",
        id: "p6"
      },
      {
        content: "Colonne générale d'immeuble",
        nextStepId: "185",
        id: "p7"
      },
      {
        content: "Autres",
        nextStepId: "186",
        id: "p8"
      }
    ]
  },
  "1": {
    id: "1",
    type: "normal",
    title: "Quel est votre besoin ?",
    propositions: [
      {
        content: "Engorgement (WC bouché ?)",
        nextStepId: "2",
        id: "p9"
      },
      {
        content: "Fuite",
        nextStepId: "5",
        id: "p10"
      },
      {
        content: "Problème de fonctionnement (cuvette ...)",
        nextStepId: "8",
        id: "p11"
      },
      {
        content: "Changement de WC",
        nextStepId: "114",
        id: "p12"
      }
    ]
  },
  "2": {
    id: "2",
    type: "normal",
    title: "Votre WC possède-t-il un sanibroyeur ?",
    propositions: [
      {
        content: "Oui",
        nextStepId: "101",
        id: "p13"
      },
      {
        content: "Non",
        nextStepId: "102",
        id: "p14"
      }
    ]
  },
  "5": {
    id: "5",
    type: "normal",
    title: "quel type de WC avez-vous",
    propositions: [
      {
        content: "WC simple",
        nextStepId: "6",
        id: "p15"
      },
      {
        content: "WC suspendu",
        nextStepId: "109",
        id: "p16"
      },
      {
        content: "Autres fuites non localisées",
        nextStepId: "110",
        id: "p17"
      }
    ]
  },
  "6": {
    id: "6",
    type: "normal",
    title: "D'ou coule l'eau?",
    propositions: [
      {
        content: "près du robinet  d'arrêt ?",
        nextStepId: "103",
        id: "p18"
      },
      {
        content: "Près de la pipe de sortie?",
        nextStepId: "7",
        id: "p19"
      },
      {
        content: "Entre la cuvette et le réservoir?",
        nextStepId: "104",
        id: "p20"
      },
      {
        content: "socle du WC?",
        nextStepId: "107",
        id: "p21"
      },
      {
        content: "Vous ne savez pas?",
        nextStepId: "108",
        id: "p22"
      }
    ]
  },
  "7": {
    id: "7",
    type: "normal",
    title: "Votre WC possède-t-il un sanibroyeur ?",
    propositions: [
      {
        content: "Oui",
        nextStepId: "105",
        id: "p23"
      },
      {
        content: "Non",
        nextStepId: "106",
        id: "p24"
      }
    ]
  },
  "8": {
    id: "8",
    type: "normal",
    title: "quel type de WC avez-vous",
    propositions: [
      {
        content: "WC simple",
        nextStepId: "9",
        id: "p25"
      },
      {
        content: "WC suspendu",
        nextStepId: "9",
        id: "p26"
      },
      {
        content: "Autres fuites non localisées",
        nextStepId: "187",
        id: "p27"
      }
    ]
  },
  "9": {
    id: "9",
    type: "normal",
    title: "Quel est votre problème ?",
    propositions: [
      {
        content:
          "La chasse d'eau ne se tire plus/le bonton de la chasse d'eau est cassé",
        nextStepId: "10",
        id: "p28"
      },
      {
        content: "De l'eau coule à l'intérieur de la cuvette sans arrêt",
        nextStepId: "10",
        id: "p29"
      },
      {
        content: "Autres problème avec le réservoir",
        nextStepId: "10",
        id: "p30"
      },
      {
        content: "Autre",
        nextStepId: "113",
        id: "p31"
      }
    ]
  },
  "10": {
    id: "10",
    type: "normal",
    title: "Est-ce que votre WC est de marque Geberit ?",
    propositions: [
      {
        content: "Oui",
        nextStepId: "111",
        id: "p32"
      },
      {
        content: "Non",
        nextStepId: "112",
        id: "p33"
      }
    ]
  },
  "11": {
    id: "11",
    type: "normal",
    title: "Quelle est la nature de votre problème ?",
    propositions: [
      {
        content: "Engorgement (lavabo/évier bouché)",
        nextStepId: "12",
        id: "p34"
      },
      {
        content: "Fuite/Robinetterie",
        nextStepId: "13",
        id: "p35"
      },
      {
        content: "Autre",
        nextStepId: "16",
        id: "p36"
      }
    ]
  },
  "12": {
    id: "12",
    type: "normal",
    title: "Votre lavabo possède-t-il un sanispeed ?",
    propositions: [
      {
        content: "Oui",
        nextStepId: "115",
        id: "p37"
      },
      {
        content: "Non",
        nextStepId: "116",
        id: "p38"
      }
    ]
  },
  "13": {
    id: "13",
    type: "normal",
    title: "D’où coule l’eau ?",
    propositions: [
      {
        content: "Autour du robinet",
        nextStepId: "14",
        id: "p39"
      },
      {
        content: "Directement du robinet",
        nextStepId: "14",
        id: "p40"
      },
      {
        content: "Au niveau des flexibles",
        nextStepId: "120",
        id: "p41"
      },
      {
        content: "Au niveau du siphon",
        nextStepId: "15",
        id: "p42"
      }
    ]
  },
  "14": {
    id: "14",
    type: "normal",
    title: "Quel type de robinet possédez-vous ?",
    propositions: [
      {
        content: "Robinet mitigeur standard",
        nextStepId: "117",
        id: "p43"
      },
      {
        content: "Robinet mélangeur standard",
        nextStepId: "118",
        id: "p44"
      },
      {
        content: "Robinet mitigeur thermostatique standard",
        nextStepId: "119",
        id: "p45"
      }
    ]
  },
  "15": {
    id: "15",
    type: "normal",
    title: "Quel type de siphon possédez-vous ?",
    propositions: [
      {
        content: "Siphon en plastique (PVC)",
        nextStepId: "121",
        id: "p46"
      },
      {
        content: "Siphon en laiton (Métallique)",
        nextStepId: "122",
        id: "p47"
      }
    ]
  },
  "16": {
    id: "16",
    type: "normal",
    title: "Voulez-vous effectuer :",
    propositions: [
      {
        content: "Changement de lavabo/évier",
        nextStepId: "123",
        id: "p48"
      },
      {
        content: "Installation d'une robinet",
        nextStepId: "17",
        id: "p49"
      },
      {
        content: "Autre",
        nextStepId: "124",
        id: "p50"
      }
    ]
  },
  "17": {
    id: "17",
    type: "normal",
    title: "Quel type de robinet voulez-vous installer ?",
    propositions: [
      {
        content: "Robinet mitigeur standard",
        nextStepId: "117",
        id: "p51"
      },
      {
        content: "Robinet mélangeur standard",
        nextStepId: "118",
        id: "p52"
      },
      {
        content: "Robinet mitigeur thermostatique standard",
        nextStepId: "119",
        id: "p53"
      }
    ]
  },
  "18": {
    id: "18",
    type: "normal",
    title: "Quelle est la nature de votre problème ?",
    propositions: [
      {
        content: "Engorgement de la douche (bouchée)",
        nextStepId: "125",
        id: "p54"
      },
      {
        content: "Engorgement de la baignoire (bouchée)",
        nextStepId: "126",
        id: "p55"
      },
      {
        content: "Fuite de douche",
        nextStepId: "19",
        id: "p56"
      },
      {
        content: "Fuite de baignoire",
        nextStepId: "20",
        id: "p57"
      },
      {
        content: "Autre",
        nextStepId: "21",
        id: "p58"
      }
    ]
  },
  "19": {
    id: "19",
    type: "normal",
    title: "D’où coule l’eau ?",
    propositions: [
      {
        content: "Du robinet",
        nextStepId: "14",
        id: "p59"
      },
      {
        content: "Du tuyau d'évacuation (sous la douche)",
        nextStepId: "127",
        id: "p60"
      },
      {
        content: "Inconnu",
        nextStepId: "128",
        id: "p61"
      }
    ]
  },
  "20": {
    id: "20",
    type: "normal",
    title: "D’où coule l’eau ?",
    propositions: [
      {
        content: "Du robinet",
        nextStepId: "14",
        id: "p62"
      },
      {
        content: "Du tuyau d'évacuation (sous la baignoire) pas encastré",
        nextStepId: "129",
        id: "p63"
      },
      {
        content: "Du tuyau d'évacuation (sous la baignoire) encastré",
        nextStepId: "130",
        id: "p64"
      },
      {
        content: "Inconnu",
        nextStepId: "131",
        id: "p65"
      }
    ]
  },
  "21": {
    id: "21",
    type: "normal",
    title: "Voulez-vous effectuer :",
    propositions: [
      {
        content: "Installation de bac à douche",
        nextStepId: "132",
        id: "p66"
      },
      {
        content: "Installation de baignoire",
        nextStepId: "133",
        id: "p67"
      },
      {
        content: "Autre problème avec la douche",
        nextStepId: "134",
        id: "p68"
      },
      {
        content: "Autre problème avec la baignoire",
        nextStepId: "135",
        id: "p69"
      }
    ]
  },
  "22": {
    id: "22",
    type: "normal",
    title: "Vous avez une fuite au niveau :",
    propositions: [
      {
        content: "D'un tuyau d'évacuation (tuyau pvc)",
        nextStepId: "136",
        id: "p70"
      },
      {
        content:
          "De la vanne d'entrée (directement la vanne ou tout près).c'est une vanne qui coupe l'eau dans tout l'appartement",
        nextStepId: "137",
        id: "p71"
      },
      {
        content: "D'un autre tuyau",
        nextStepId: "138",
        id: "p72"
      },
      {
        content: "Je ne vois pas (recherche de fuite)",
        nextStepId: "139",
        id: "p73"
      }
    ]
  },
  "23": {
    id: "23",
    type: "normal",
    title: "Quel est votre besoin ?",
    propositions: [
      {
        content: "Réparation d'une panne",
        nextStepId: "24",
        id: "p74"
      },
      {
        content: "Souscription d'un contrat d'entretien",
        nextStepId: "168",
        id: "p75"
      },
      {
        content: "Réparation d'une fuite du ballon d'eau chaude",
        nextStepId: "169",
        id: "p76"
      },
      {
        content: "Installation",
        nextStepId: "36",
        id: "p77"
      },
      {
        content: "Autre",
        nextStepId: "184",
        id: "p78"
      }
    ]
  },
  "24": {
    id: "24",
    type: "normal",
    title: "Votre problème est lié :",
    propositions: [
      {
        content: "Au chauffage",
        nextStepId: "25",
        id: "p79"
      },
      {
        content: "Au chauffage",
        nextStepId: "32",
        id: "p80"
      },
      {
        content: "Au chauffage et à l'eau chaude",
        nextStepId: "27",
        id: "p81"
      }
    ]
  },
  "25": {
    id: "25",
    type: "normal",
    title: "Votre problème concerne-t-il :",
    propositions: [
      {
        content: "Radiateur éléctriques",
        nextStepId: "26",
        id: "p82"
      },
      {
        content: "Chaudière",
        nextStepId: "27",
        id: "p83"
      }
    ]
  },
  "26": {
    id: "26",
    type: "normal",
    title: "Vous avez besoin de :",
    propositions: [
      {
        content:
          "Réparation d'un convecteur/radiateur électrique ou d'un sèche-serviettes",
        nextStepId: "141",
        id: "p84"
      },
      {
        content:
          "Remplacement d'un convecteur/radiateur électrique ou d'un sèche-serviette",
        nextStepId: "142",
        id: "p85"
      },
      {
        content:
          "Installation d'un convecteur/radiateur électrique ou d'une sèche-serviettes",
        nextStepId: "143",
        id: "p86"
      }
    ]
  },
  "27": {
    id: "27",
    type: "normal",
    title: "Votre appareil à chauffer est-il :",
    propositions: [
      {
        content: "central",
        nextStepId: "28",
        id: "p87"
      },
      {
        content: "individuel",
        nextStepId: "29",
        id: "p88"
      }
    ]
  },
  "28": {
    id: "28",
    type: "normal",
    title: "Possédez-vous un robinet à boisseau ?",
    propositions: [
      {
        content: "Oui",
        nextStepId: "144",
        id: "p89"
      },
      {
        content: "Non",
        nextStepId: "145",
        id: "p90"
      },
      {
        content: "je ne sais pas",
        nextStepId: "145",
        id: "p91"
      }
    ]
  },
  "29": {
    id: "29",
    type: "normal",
    title: "Vous vous chauffez :",
    propositions: [
      {
        content: "Au gaz",
        nextStepId: "30",
        id: "p92"
      },
      {
        content: "A l'électrique",
        nextStepId: "31",
        id: "p93"
      },
      {
        content: "Autres (pa exemple au fioul)",
        nextStepId: "155",
        id: "p94"
      }
    ]
  },
  "30": {
    id: "30",
    type: "nQuelle est la marque de votre chaudière ?ormal",
    title: "",
    propositions: [
      {
        content: "ELM Leblanc",
        nextStepId: "146",
        id: "p95"
      },
      {
        content: "Frisquet",
        nextStepId: "147",
        id: "p96"
      },
      {
        content: "Saunier Duval",
        nextStepId: "148",
        id: "p97"
      },
      {
        content: "Chafforteaux",
        nextStepId: "149",
        id: "p98"
      },
      {
        content: "De dietrich",
        nextStepId: "150",
        id: "p99"
      },
      {
        content: "Autre",
        nextStepId: "151",
        id: "p100"
      }
    ]
  },
  "31": {
    id: "31",
    type: "normal",
    title: "Quelle est la marque de votre chaudière ?",
    propositions: [
      {
        content: "ELM Leblanc",
        nextStepId: "152",
        id: "p101"
      },
      {
        content: "Saunier Duval",
        nextStepId: "153",
        id: "p102"
      },
      {
        content: "Autre",
        nextStepId: "154",
        id: "p103"
      }
    ]
  },
  "32": {
    id: "32",
    type: "normal",
    title: "Quel appareil utilisez-vous pour chauffer l'eau ?",
    propositions: [
      {
        content: "Chaudière",
        nextStepId: "27",
        id: "p104"
      },
      {
        content: "ballon d'eau chaude",
        nextStepId: "33",
        id: "p105"
      },
      {
        content: "Chauffe-eau/chauffe-bain",
        nextStepId: "34",
        id: "p106"
      },
      {
        content: "Autre",
        nextStepId: "167",
        id: "p107"
      }
    ]
  },
  "33": {
    id: "33",
    type: "normal",
    title: "Quelle est la marque de votre de ballon d'eau chaude ? ",
    propositions: [
      {
        content: "Sauter",
        nextStepId: "156",
        id: "p108"
      },
      {
        content: "Fleck",
        nextStepId: "157",
        id: "p109"
      },
      {
        content: "Thermor",
        nextStepId: "158",
        id: "p110"
      },
      {
        content: "Chafforteaux",
        nextStepId: "159",
        id: "p111"
      },
      {
        content: "De dietrich",
        nextStepId: "160",
        id: "p112"
      },
      {
        content: "Autre",
        nextStepId: "161",
        id: "p113"
      }
    ]
  },
  "34": {
    id: "34",
    type: "normal",
    title: "Votre chauffe-eau/chauffe-bain est-il ?",
    propositions: [
      {
        content: "Au gaz",
        nextStepId: "35",
        id: "p114"
      },
      {
        content: "Autre",
        nextStepId: "167",
        id: "p115"
      }
    ]
  },
  "35": {
    id: "35",
    type: "normal",
    title: "Quelle est la marque de votre chauffe-eau / chauffe-bain ? ",
    propositions: [
      {
        content: "ELM Leblanc",
        nextStepId: "162",
        id: "p116"
      },
      {
        content: "Saunier Duval",
        nextStepId: "163",
        id: "p117"
      },
      {
        content: "Chafforteaux",
        nextStepId: "164",
        id: "p118"
      },
      {
        content: "De dietrich",
        nextStepId: "165",
        id: "p119"
      },
      {
        content: "Autre",
        nextStepId: "166",
        id: "p120"
      },
      {
        content: "Autre",
        nextStepId: "166",
        id: "p121"
      }
    ]
  },
  "36": {
    id: "36",
    type: "normal",
    title: "Quel objet est concerné par votre demande ?",
    propositions: [
      {
        content: "Chaudière",
        nextStepId: "37",
        id: "p122"
      },
      {
        content: "Chauffe-eau/chauffe-bain",
        nextStepId: "42",
        id: "p123"
      },
      {
        content: "ballon d'eau chaude",
        nextStepId: "45",
        id: "p124"
      },
      {
        content: "Radiateur éléctrique/èche-serviette",
        nextStepId: "46",
        id: "p125"
      }
    ]
  },
  "37": {
    id: "37",
    type: "normal",
    title: "Quel type de chaudière souhaitez-vous faire poser ?",
    propositions: [
      {
        content: "Chaudière murale au gaz",
        nextStepId: "38",
        id: "p126"
      },
      {
        content: "Chaudière murale à l'électricité",
        nextStepId: "39",
        id: "p127"
      },
      {
        content: "Chaudière au sol au gaz",
        nextStepId: "40",
        id: "p128"
      },
      {
        content: "Chaudière au sol à l'électricité",
        nextStepId: "41",
        id: "p129"
      }
    ]
  },
  "38": {
    id: "38",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "170",
        id: "p130"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "171",
        id: "p131"
      }
    ]
  },
  "39": {
    id: "39",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "172",
        id: "p132"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "173",
        id: "p133"
      }
    ]
  },
  "40": {
    id: "40",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "174",
        id: "p134"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "175",
        id: "p135"
      }
    ]
  },
  "41": {
    id: "41",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "176",
        id: "p136"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "177",
        id: "p137"
      }
    ]
  },
  "42": {
    id: "42",
    type: "normal",
    title:
      "Quel type de chauffe-eau / chauffe-bain souhaitez-vous faire poser ?",
    propositions: [
      {
        content: "Chauffe-eau/chauffe-bain au gaz",
        nextStepId: "43",
        id: "p138"
      },
      {
        content: "Chauffe-eau/chauffe-bain à l'électricité",
        nextStepId: "44",
        id: "p139"
      }
    ]
  },
  "43": {
    id: "43",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "178",
        id: "p140"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "179",
        id: "p141"
      }
    ]
  },
  "44": {
    id: "44",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "180",
        id: "p142"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "181",
        id: "p143"
      }
    ]
  },
  "45": {
    id: "45",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "182",
        id: "p144"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "183",
        id: "p145"
      }
    ]
  },
  "46": {
    id: "46",
    type: "normal",
    title: "S'agit il ?",
    propositions: [
      {
        content:
          "Uniquement de la pose (vous disposez de tout le matériel nécessaire)",
        nextStepId: "143",
        id: "p146"
      },
      {
        content: "Fourniture et pose",
        nextStepId: "142",
        id: "p147"
      }
    ]
  },
  "101": {
    id: "101",
    type: "final",
    title: "Débouchage de WC sans sanibroyeur",
    priceMin: 90,
    priceMax: 180
  },
  "102": {
    id: "102",
    type: "final",
    title: "Débouchage de WC avec sanibroyeur",
    priceMin: 150,
    priceMax: 250
  },
  "103": {
    id: "103",
    type: "final",
    title: "Réparation d'une fuite du robinet d'arrêt du WC",
    priceMin: 90,
    priceMax: 150
  },
  "104": {
    id: "104",
    type: "final",
    title: "Réparation d'une fuite entre la cuvette et le réservoir du WC",
    priceMin: 100,
    priceMax: 200
  },
  "105": {
    id: "105",
    type: "final",
    title: "Réparation d'une fuite de la pipe de sortie du WC avec sanibroyeur",
    priceMin: 120,
    priceMax: 200
  },
  "106": {
    id: "106",
    type: "final",
    title: "Réparation d'une fuite de la pipe de sortie du WC sans sanibroyeur",
    priceMin: 120,
    priceMax: 200
  },
  "107": {
    id: "107",
    type: "final",
    title: "Scellement WC",
    priceMin: 150,
    priceMax: 200
  },
  "108": {
    id: "108",
    type: "final",
    title: "Recherche et réparation d'une fuite pour un WC simple",
    priceMin: 90,
    priceMax: 200
  },
  "109": {
    id: "109",
    type: "final",
    title: "Recherche et réparation d'une fuite pour un WC suspendu",
    priceMin: 150,
    priceMax: 300
  },
  "110": {
    id: "110",
    type: "final",
    title: "Recherche et réparation d'une fuite pour un WC non standard",
    priceMin: 150,
    priceMax: 250
  },
  "111": {
    id: "111",
    type: "final",
    title: "Réparation d'une panne de chasse d'eau d'un WC simple Geberit",
    priceMin: 150,
    priceMax: 170
  },
  "112": {
    id: "112",
    type: "final",
    title: "Réparation d'une panne de chasse d'eau d'un WC simple",
    priceMin: 90,
    priceMax: 150
  },
  "113": {
    id: "113",
    type: "final",
    title: "Problème inconnu de WC simple",
    priceMin: 100,
    priceMax: 450
  },
  "114": {
    id: "114",
    type: "final",
    title: "Fourniture et pose d'un WC simple",
    priceMin: 350,
    priceMax: 600
  },
  "115": {
    id: "115",
    type: "final",
    title: "Débouchage d'un lavabo / évier avec sanispeed",
    priceMin: 90,
    priceMax: 250
  },
  "116": {
    id: "116",
    type: "final",
    title: "Débouchage d'un lavabo / évier sans sanispeed",
    priceMin: 150,
    priceMax: 180
  },
  "117": {
    id: "117",
    type: "final",
    title: "Fourniture et pose d'un robinet mitigeur (lavabo / évier)",
    priceMin: 150,
    priceMax: 200
  },
  "118": {
    id: "118",
    type: "final",
    title: "Fourniture et pose d'un robinet mélangeur (lavabo / évier)",
    priceMin: 150,
    priceMax: 200
  },
  "119": {
    id: "119",
    type: "final",
    title:
      "Fourniture et pose d'un robinet mitigeur thermostatique (lavabo / évier)",
    priceMin: 200,
    priceMax: 300
  },
  "120": {
    id: "120",
    type: "final",
    title: "Changement des flexibles (robinet de lavabo / évier)",
    priceMin: 90,
    priceMax: 150
  },
  "121": {
    id: "121",
    type: "final",
    title: "Changement du siphon (lavabo / évier) en PVC",
    priceMin: 90,
    priceMax: 150
  },
  "122": {
    id: "122",
    type: "final",
    title: "Changement du siphon (lavabo / évier) en laiton",
    priceMin: 90,
    priceMax: 160
  },
  "123": {
    id: "123",
    type: "final",
    title: "Fourniture et pose d'un lavabo / évier",
    priceMin: 200,
    priceMax: 400
  },
  "124": {
    id: "124",
    type: "final",
    title: "Problème inconnu lavabo / évier",
    priceMin: 100,
    priceMax: 300
  },
  "125": {
    id: "125",
    type: "final",
    title: "Débouchage de douche",
    priceMin: 90,
    priceMax: 200
  },
  "126": {
    id: "126",
    type: "final",
    title: "Débouchage de baignoire",
    priceMin: 90,
    priceMax: 200
  },
  "127": {
    id: "127",
    type: "final",
    title: "Réparation d'une fuite du tuyau d'évacuation de la douche",
    priceMin: 90,
    priceMax: 200
  },
  "128": {
    id: "128",
    type: "final",
    title: "Recherche d'une fuite douche / baignoire",
    priceMin: 100,
    priceMax: 150
  },
  "129": {
    id: "129",
    type: "final",
    title:
      "Réparation d'une fuite du tuyau d'évacuation de la baignoire non encastrée",
    priceMin: 90,
    priceMax: 200
  },
  "130": {
    id: "130",
    type: "final",
    title:
      "Réparation d'une fuite du tuyau d'évacuation de la baignoire encastrée",
    priceMin: 150,
    priceMax: 250
  },
  "131": {
    id: "131",
    type: "final",
    title: "Recherche d'une fuite douche / baignoire",
    priceMin: 90,
    priceMax: 150
  },
  "132": {
    id: "132",
    type: "final",
    title: "Fourniture et pose d'un bac à douche",
    priceMin: 400,
    priceMax: 550
  },
  "133": {
    id: "133",
    type: "final",
    title: "Fourniture et pose d'une baignoire",
    priceMin: 600,
    priceMax: 900
  },
  "134": {
    id: "134",
    type: "final",
    title: "Problème invisible douche",
    priceMin: 150,
    priceMax: 300
  },
  "135": {
    id: "135",
    type: "final",
    title: "Problème invisible baignoire",
    priceMin: 150,
    priceMax: 300
  },
  "136": {
    id: "136",
    type: "final",
    title: "Réparation d'une fuite du tuyau d'évacuation",
    priceMin: 150,
    priceMax: 200
  },
  "137": {
    id: "137",
    type: "final",
    title:
      "Réparation d'une fuite de la vanne principale de l'appartement ou avant la vanne",
    priceMin: 150,
    priceMax: 300
  },
  "138": {
    id: "138",
    type: "final",
    title:
      "Réparation d'une fuite du tuyau après la vanne principale de l'appartement",
    priceMin: 120,
    priceMax: 200
  },
  "139": {
    id: "139",
    type: "final",
    title: "Recherche de fuite simple",
    priceMin: 90,
    priceMax: 200
  },
  "140": {
    id: "140",
    type: "final",
    title:
      "Réparation d'une fuite du tuyau de la machine à laver / lave-vaisselle",
    priceMin: 130,
    priceMax: 150
  },
  "141": {
    id: "141",
    type: "final",
    title: "Réparation d'une panne de radiateurs / sèche-serviette électrique",
    priceMin: 120,
    priceMax: 180
  },
  "142": {
    id: "142",
    type: "final",
    title:
      "Fourniture et pose d'un radiateur électrique ou d'un sèche-serviettes",
    priceMin: 300,
    priceMax: 450
  },
  "143": {
    id: "143",
    type: "final",
    title: "Pose d'un convecteur/radiateur électrique ou d'un sèche-serviettes",
    priceMin: 100,
    priceMax: 150
  },
  "144": {
    id: "144",
    type: "final",
    title: "Réparation du chauffage collectif (avec le robinet à boisseau)",
    priceMin: 150,
    priceMax: 250
  },
  "145": {
    id: "145",
    type: "final",
    title: "voir conseil",
    priceMin: 0,
    priceMax: 0
  },
  "146": {
    id: "146",
    type: "final",
    title: "Réparation d'une chaudière au gaz de marque ELM le Blanc",
    priceMin: 149,
    priceMax: 260
  },
  "147": {
    id: "147",
    type: "final",
    title: "Réparation d'une chaudière au gaz de marque Frisquet",
    priceMin: 149,
    priceMax: 260
  },
  "148": {
    id: "148",
    type: "final",
    title: "Réparation d'une chaudière au gaz de marque Saunier Duval",
    priceMin: 149,
    priceMax: 260
  },
  "149": {
    id: "149",
    type: "final",
    title: "Réparation d'une chaudière au gaz de marque Chaffoteaux",
    priceMin: 149,
    priceMax: 260
  },
  "150": {
    id: "150",
    type: "final",
    title: "Réparation d'une chaudière au gaz de marque De Dietrich",
    priceMin: 149,
    priceMax: 260
  },
  "151": {
    id: "151",
    type: "final",
    title: "Réparation d'une chaudière au gaz marque inconnue",
    priceMin: 149,
    priceMax: 260
  },
  "152": {
    id: "152",
    type: "final",
    title: "Réparation d'une chaudière à l'électricité de marque ELM le Blanc",
    priceMin: 180,
    priceMax: 260
  },
  "153": {
    id: "153",
    type: "final",
    title: "Réparation d'une chaudière à l'électricité de marque Saunier Duval",
    priceMin: 180,
    priceMax: 260
  },
  "154": {
    id: "154",
    type: "final",
    title: "Réparation d'une chaudière à l'électricité marque inconnue",
    priceMin: 180,
    priceMax: 260
  },
  "155": {
    id: "155",
    type: "final",
    title: "Réparation de chaudière autre qu'au gaz et qu'à l'électricité",
    priceMin: 200,
    priceMax: 300
  },
  "156": {
    id: "156",
    type: "final",
    title:
      "Réparation d'un ballon d'eau chaude à l'électricité de marque Sauter",
    priceMin: 180,
    priceMax: 300
  },
  "157": {
    id: "157",
    type: "final",
    title:
      "Réparation d'un ballon d'eau chaude à l'électricité de marque Fleck",
    priceMin: 180,
    priceMax: 300
  },
  "158": {
    id: "158",
    type: "final",
    title:
      "Réparation d'un ballon d'eau chaude à l'électricité de marque Thermor",
    priceMin: 180,
    priceMax: 300
  },
  "159": {
    id: "159",
    type: "final",
    title:
      "Réparation d'un ballon d'eau chaude à l'électricité de marque Chaffoteaux",
    priceMin: 180,
    priceMax: 300
  },
  "160": {
    id: "160",
    type: "final",
    title:
      "Réparation d'un ballon d'eau chaude à l'électricité de marque De Dietrich",
    priceMin: 180,
    priceMax: 300
  },
  "161": {
    id: "161",
    type: "final",
    title:
      "Réparation d'un ballon d'eau chaude à l'électricité marque inconnue",
    priceMin: 180,
    priceMax: 300
  },
  "162": {
    id: "162",
    type: "final",
    title:
      "Réparation d'un chauffe-eau / chauffe-bain au gaz de marque ELM le Blanc",
    priceMin: 180,
    priceMax: 260
  },
  "163": {
    id: "163",
    type: "final",
    title:
      "Réparation d'un chauffe-eau / chauffe-bain au gaz de marque Saunier Duval",
    priceMin: 180,
    priceMax: 260
  },
  "164": {
    id: "164",
    type: "final",
    title:
      "Réparation d'un chauffe-eau / chauffe-bain au gaz de marque Chaffoteaux",
    priceMin: 180,
    priceMax: 260
  },
  "165": {
    id: "165",
    type: "final",
    title:
      "Réparation d'un chauffe-eau / chauffe-bain au gaz de marque De Dietrich",
    priceMin: 180,
    priceMax: 200
  },
  "166": {
    id: "166",
    type: "final",
    title: "Réparation d'un chauffe-eau / chauffe-bain au gaz marque inconnue",
    priceMin: 180,
    priceMax: 260
  },
  "167": {
    id: "167",
    type: "final",
    title: "Réparation d'un appareil de chauffage inconnu",
    priceMin: 180,
    priceMax: 350
  },
  "168": {
    id: "168",
    type: "final",
    title: "Souscription d'un contrat d'entretien de chauffage",
    priceMin: 150,
    priceMax: 180
  },
  "169": {
    id: "169",
    type: "final",
    title: "Réparation d'une fuite du ballon d'eau chaude",
    priceMin: 150,
    priceMax: 300
  },
  "170": {
    id: "170",
    type: "final",
    title: "Pose d'une chaudière murale au gaz",
    priceMin: 300,
    priceMax: 500
  },
  "171": {
    id: "171",
    type: "final",
    title: "Fourniture et pose d'une chaudière murale au gaz",
    priceMin: 1200,
    priceMax: 2500
  },
  "172": {
    id: "172",
    type: "final",
    title: "Pose d'une chaudière murale à l'électricité",
    priceMin: 300,
    priceMax: 500
  },
  "173": {
    id: "173",
    type: "final",
    title: "Fourniture et pose d'une chaudière murale à l'électricité",
    priceMin: 1800,
    priceMax: 3000
  },
  "174": {
    id: "174",
    type: "final",
    title: "Pose d'une chaudière au sol au gaz",
    priceMin: 400,
    priceMax: 600
  },
  "175": {
    id: "175",
    type: "final",
    title: "Fourniture et pose d'une chaudière au sol au gaz",
    priceMin: 1800,
    priceMax: 3000
  },
  "176": {
    id: "176",
    type: "final",
    title: "Pose d'une chaudière au sol à l'électricité",
    priceMin: 400,
    priceMax: 600
  },
  "177": {
    id: "177",
    type: "final",
    title: "Fourniture et pose d'une chaudière au sol à l'électricité",
    priceMin: 2000,
    priceMax: 3500
  },
  "178": {
    id: "178",
    type: "final",
    title: "Pose d'un chauffe-eau / chauffe-bain au gaz",
    priceMin: 300,
    priceMax: 500
  },
  "179": {
    id: "179",
    type: "final",
    title: "Fourniture et pose d'un chauffe-eau / chauffe-bain au gaz",
    priceMin: 600,
    priceMax: 1000
  },
  "180": {
    id: "180",
    type: "final",
    title: "Pose d'un chauffe-eau / chauffe-bain à l'électricité",
    priceMin: 300,
    priceMax: 500
  },
  "181": {
    id: "181",
    type: "final",
    title: "Fourniture et pose d'un chauffe-eau / chauffe-bain à l'électricité",
    priceMin: 600,
    priceMax: 800
  },
  "182": {
    id: "182",
    type: "final",
    title: "Pose d'un ballon d'eau chaude",
    priceMin: 250,
    priceMax: 350
  },
  "183": {
    id: "183",
    type: "final",
    title: "Fourniture et pose d'un ballon d’eau chaude",
    priceMin: 600,
    priceMax: 1000
  },
  "184": {
    id: "184",
    type: "final",
    title: "Problème de chauffage inconnu",
    priceMin: 200,
    priceMax: 400
  },
  "185": {
    id: "185",
    type: "final",
    title: "Débouchage de colonne générale",
    priceMin: 700,
    priceMax: 900
  },
  "186": {
    id: "186",
    type: "final",
    title: "Problème de plomberie inconnu",
    priceMin: 200,
    priceMax: 500
  },
  "187": {
    id: "187",
    type: "final",
    title: "contacter le plombier disponible",
    priceMin: 0,
    priceMax: 0
  }
};

const contents = [
  {
    id: "101",
    content: `
    L’engorgement des WC est un des problèmes les plus courants dans le dépannage de plomberie sanitaire.
    La cause de ce « bouchage » de WC est multiple, cela peut provenir de votre bloc anti-tartre qui est tout simplement tombé dans votre cuvette, ou d’un objet ou d’une accumulation d’insalubrité.
    Le débouchage des WC est une intervention d'urgence car vous n'arrivez pas à utiliser vos toilettes...
\n

    La réparation de plomberie va varier selon le niveau d’engorgement de votre WC et il existe différentes possibilités pour procéder à un dégorgement. Cela se fait normalement avec un système de pompe manuelle, mais aussi avec le dépôt d’un acide adapté qui doit être réalisé dans les règles de l’art par un plombier car il s’agit d’un produit puissant que l’on ne trouve que chez les revendeurs professionnels de la plomberie.
    Le temps de réparation va aussi varier allant de 30 minutes à 2 heures.
    Ne mettez pas des produits « déboucheurs » que l’on trouve dans les grandes surfaces ou dans les magasins de bricolage rayon plomberie car ce type de produit est utile seulement pour la prévention de bouchage. Si vos WC sont déjà bouchés, ce produit va se déposer sur votre canalisation, durcir et engorger davantage votre tuyauterie, ce qui nécessitera un dégorgement plus complexe au final par un plombier. Nous le précisons car la majorité de notre clientèle commet cette erreur en règle générale avant de nous appeler, ce qui en fin de compte génère plus de frais avec l’achat du produit, plutôt que d’appeler directement notre service plomberie pour le dépannage.
    Si vous constatez que, en plus de vos WC, votre évier, lavabo ou votre douche sont bouchés, cela peut provenir de la colonne générale de votre immeuble. Le dégorgement de la colonne générale est à effectuer en faisant appel au camion hydrocureur, les frais associés sont beaucoup plus importants. Référez-vous au cas "Débouchage de colonne générale".
    `
  },

  {
    id: "102",
    content: `Problème de WC avec sanibroyeur
    L’engorgement d’un WC sanibroyeur est malheureusement très fréquent en terme de dépannage de plomberie sanitaire. Ne mettez pas des produits « déboucheurs » que l’on trouve facilement dans les commerces au rayon plomberie.
    Lorsque vous avez un engorgement de votre WC sanibroyeur appelez un plombier car cela est complexe.
    La cause du « bouchage » de votre WC sanibroyeur peut être multiple, cela peut provenir de votre bloc anti-tartre qui est tout simplement tombé dans votre cuvette, ou d’un objet, d’une accumulation d’insalubrité, mais aussi d’une accumulation de papier hygiénique. Lorsque vous verrez qu’une fois la chasse d’eau tirée, l’eau monte et que les déjections restent dans votre cuvette, aucun doute possible, votre WC sanibroyeur est bouché ! Il se peut que vous entendiez un bruit inhabituel: c’est le moteur de votre sanibroyeur. Dans tous les cas ne touchez à rien, si ce n’est de débrancher la prise et appelez un plombier.
    
    Bien que certaines pièces détachées soient vendues dans les magasins de bricolage, nous vous recommandons de ne pas essayer d’effectuer les réparations seul si vous ne connaissez pas la plomberie.
    La réparation de plomberie va varier selon la panne que vous rencontrez. Si c’est un dégorgement simple, ou si votre moteur est en panne ou encore si votre sanibroyeur est hors service.
    Dans les deux derniers cas, une réparation ou un remplacement de votre sanibroyeur sont les seuls recours possibles.
    Si vous vous trouvez dans cette situation, un opérateur de MesDépanneurs.fr vous rappellera afin de vous guider dans la prise de décision.
    Informations utiles pour le "débouchage de WC avec sanibroyeur"
    Le temps de réparation peut aller de 1 heure à 2 heures en moyenne.`
  },
  {
    id: "103",
    content: `Vous constatez une fuite au niveau du robinet d'arrêt de vos WC.
    La fuite d’un robinet d’arrêt sur un WC est une panne très courante.
    La cause de cette fuite peut provenir du joint du robinet d’arrêt de votre WC, cela peut aussi arriver lorsque vous ne l’avez pas touché durant longtemps.
    Il existe plusieurs types de robinets WC: robinet d’arrêt WC équerre, robinet d’arrêt WC quart de tour, robinet d’arrêt WC clapet équerre et plusieurs diamètres.
    Les joints à changer ou le robinet à remplacer
    La réparation de plomberie va varier si le joint est à remplacer ou si le robinet d’arrêt du WC est grippé ou bien vétuste et dans ce cas, il faut le remplacer.
    La réparation ou le remplacement de robinet d’arrêt est une intervention dont la durée est comprise entre 30 minutes et 1 heure en moyenne.
    Information utile pour le cas "Réparation d'une fuite du robinet d'arrêt du WC"
    Bien que jamais abondante, la fuite du robinet d'arrêt est à traiter rapidement. En effet, un simple goutte à goutte peut augmenter votre consommation d’eau donc mieux vaut prévoir une réparation.`
  },
  {
    id: "104",
    content: `Vous constatez une fuite dans vos WC, entre le réservoir et la cuvette.
    Cette fuite peut se produire lorsque vous actionnez la chasse d’eau, au moment du tirage de la chasse ou même sans tirer votre chasse d’eau.
    La fuite peut venir du joint d’étanchéité entre la cuvette de vos WC et le réservoir, de la chasse d’eau, ou de condensation de votre cuvette….
    Si cela vient du joint d’étanchéité, un plombier de MesDépanneurs.fr va démonter votre réservoir, et bien sur couper l’eau et vider le réservoir. Une fois la cuve retirée, le vieux joint en mousse sera aussi retiré, et l’écrou en PVC desserré.
    Le temps de réparation va varier entre 1 heure et 2 heures.`
  },
  {
    id: "105",
    content: `"La pipe d’évacuation de votre WC sanibroyeur fuit. Il est toujours délicat d’intervenir sur un WC broyeur si vous n’êtes pas un professionnel de la plomberie.
    Cette fuite sur votre pipe de sortie peut provenir de la pipe en elle-même qui est pliée. Sur un WC sanibroyeur, il se peut que vous pensiez que le souci vient de la pipe et en fait il se trouve ailleurs ! Dans de très rares cas il peut arriver que votre broyeur est hors service. Dans ce cas, une réparation ou un remplacement de votre sanibroyeur est un recours possible. Si vous vous trouvez dans cette situation, un opérateur de MesDépanneurs.fr vous rappellera afin de vous guider dans la prise de décision car le remplacement de broyeur représente un travail coûteux.
    Le temps de réparation estimé peut varier de 1 heure et demie à 3 heures.
    "`
  },
  {
    id: "106",
    content: `"Vous constatez une fuite de vos WC...
    La pipe d’évacuation de votre WC fuit. Ne paniquez pas, cela arrive bien plus souvent que vous ne pouvez le penser.
    Ce problème de fuite de la pipe d’évacuation du WC peut être dû à du calcaire sur la lèvre de la pipe ou à la sortie de la cuvette, cela peut aussi être la pipe qui est à remplacer si vous constatez que la fuite vient bien en dessous de la pipe.
    Il est possible que le problème vienne du joint d’évacuation des WC. Il arrive que le joint du tuyau d'évacuation des WC s’abîme. Si votre joint de tuyau d’évacuation des WC est défectueux, il est nécessaire de le remplacer rapidement.
    Intervention d'un plombier sur la pipe de sortie des toilettes
    Si cela fuit au dessus de la pipe, dans ce cas le dépannage va s’orienter vers le réservoir, la chasse d’eau… Si de l’eau “propre” (à l’inverse des eaux sales ou eaux usées de vos toilettes et douche, par exemple) s’écoule entre votre sol et la cuvette de vos WC, il est possible que votre réservoir ne soit plus étanche. Dans ce cas, il faudra soit boucher avec du mastic si la fissure le permet, soit remplacer vos toilettes s’il est trop abîmé.
    Ce type de fuite peut nécessiter la dépose de votre WC pour la réparation. Le plombier procédera à la recherche de fuite, trouvera son origine et vous proposera une réparation adéquate. Il changera les joints, bouchera les fissures ou vous recommandera de changer votre chasse d'eau ou votre WC. 
    Le temps de réparation estimé peut varier de 30 minutes à 2 heures.
    Infos pour la ""Réparation fuite de pipe de sortie du WC sans sanibroyeur""
    Lorsque vous vous rendez compte que vous avez une fuite sur vos toilettes, agissez rapidement afin de ne pas laisser la fuite grandir et vous causer de plus gros dégâts ainsi qu’à votre voisinage. Une petite fuite d’eau non traitée peut à terme créer un dégât des eaux chez vos voisins, ainsi que chez vous. C’est aussi un facteur de danger, puisque l’eau va pénétrer les murs, et créer de l’humidité dans votre domicile sans parler de votre facture de consommation."`
  },
  {
    id: "107",
    content: `Votre WC est descellé, la cuvette de vos toilettes bouge et n’est pas bien fixée.
    Que vous ayez un WC simple, un WC à réservoir suspendu ou même un WC non standard, nous pouvons vous dépanner.
    Cette panne peut se produire lorsque les fixations de votre WC ne sont pas bien serrées ou si le sol n’est pas stable et à niveau.
    Dans ce cas, la dépose du WC s’impose car il faut que le sol soit stable et lisse. Une fois le dépannage réalisé dans les règles de l’art, votre cuvette sera reposée et étanche.
    Le temps de réparation est compris entre 1 heure et 2 heures , en moyenne.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "108",
    content: `Vous avez une fuite WC mais ne savez pas d’où vos toilettes fuient exactement. Il se peut aussi que votre voisin du dessous soit venu vous rencontrer pour vous expliquer qu’une fuite vient de chez vous mais vous vous ne la voyez pas ! A votre niveau aucune fuite à l’horizon.
    Ne paniquez pas, ce genre de dépannage de plomberie est plus que fréquent.
    Dans un premier temps, contactez votre compagnie d’assurance afin de signaler le problème. Celle-ci va vous demander de faire effectuer une recherche de fuite par un plombier.
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite qui, sur un WC simple, ne nécessite bien sur pas en règle générale de casser quoi que ce soit.
    La fuite peut provenir de votre chasse d’eau, de votre réservoir, de la cuvette de vos WC, de divers joints dont le joint d’étanchéité principal, de votre robinet, de la pipe d’évacuation du WC, du carrelage, enfin beaucoup d’origines sont possibles pour ce problème.
    Une  fois la fuite déterminée, le plombier pourra effectuer les réparations.
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 3 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.`
  },
  {
    id: "109",
    content: `Vous avez un WC qui n’est pas un modèle standard (nous considérons comme standard les WC simple, WC suspendu, WC à réservoir suspendu) et vous constatez qu’il y a une fuite d’eau, simplement vous ne voyez pas d’où elle vient. Il est possible qu’un voisin vous apprenne qu’il a des infiltrations d’eau qui proviennent de chez vous sans que vous ayez remarqué quoi que ce soit. Cela est très fréquent.
    La démarche à suivre est de contacter votre compagnie d’assurance et de signaler que vous avez une fuite invisible pour vous. Votre assureur va alors vous demander de faire effectuer une recherche de fuite par un plombier.
    La seconde chose à faire est de nous contacter, afin que la plateforme de MesDépanneurs.fr vous mette en relation avec un plombier qui va procéder à cette recherche de fuite et ainsi en déterminer l’origine afin d’effectuer les réparations.
    La fuite d’eau peut venir de votre réservoir, de votre chasse d’eau, de la cuvette de vos toilettes, des joints dont le joint d’étanchéité de votre cuvette mais aussi de votre carrelage, de votre robinet et encore d’autres pannes possibles.
    Lorsque le plombier aura trouvé l’origine de votre fuite, il pourra la réparer. Pour trouver d’où vient la fuite d’eau, il arrive que les plombiers soient obligés de casser une partie de votre mur.
    Le temps estimé entre la recherche de fuite et la réparation de plomberie peut varier de 1 heure et demie à 3 heures en règle générale et bien sur ceci sera à déterminer avec précision une fois notre plombier sur place.`
  },
  {
    id: "110",
    content: `Vos WC suspendus fuient
    Vous avez un WC suspendu et vous constatez que vous avez une fuite sur ce WC mais ne savez pas exactement où est située cette fuite d’eau. Il se peut même que vous n’ayez constaté aucune fuite, et votre voisin du dessous vient vous expliquer qu’une fuite vient de chez vous.
    C’est un problème très courant. La fuite peut provenir de votre chasse d’eau, de votre réservoir, de la cuvette de vos WC, de divers joints dont le joint d’étanchéité principal, de votre robinet, du carrelage. Beaucoup d’origines peuvent être la cause de cette fuite. Un WC suspendu possède des canalisations encastrées dans le mur, il peut donc être difficile de définir l'origine de la fuite vous-même. Il est préférable de faire appel à un plombier.
    Le plombier effectue la recherche de fuite et sa réparation
    Autant notre plombier pourra trouver la fuite sans encombre, autant il se peut qu’il soit obligé de casser une partie de votre mur afin d’effectuer une recherche de fuite approfondie pour déterminer l’origine de la fuite.
    Une trappe d’accès à votre mécanisme peut être disponible sur certains modèles ce qui facilite les réparations nécessaires. Cette trappe n’est pas disponible sur tous les modèles, ce qui risque de compliquer la tâche du plombier en cas de soucis. En effet, s’il n’y a pas de trappe sur vos toilettes, il faudra casser le mur ou le carrelage de vos toilettes pour y accéder. Ce travail nécessite ’intervention de différents corps du métier de l’habitat (carreleur, plombier voire maçon s’il faut refaire le mur) et prendra beaucoup plus de temps.
    Une fois la fuite déterminée, le plombier pourra effectuer les réparations.
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 2 heures à 4 heures,
    mais bien sûr à déterminer avec précision une fois le plombier sur place.
    Infos utiles pour une "Recherche et réparation de fuite pour un WC suspendu"
    Le prix de MesDépanneurs.fr indiqué à droite n'inclut pas la recherche de fuite approfondie dans les cas où vos WC ne possèdent pas d'une trappe d'accès. Le plombier vous établira dans ce cas là un devis personnalisé sur place.`
  },
  {
    id: "111",
    content: `Votre chasse d’eau fuit ou ne marche plus. La marque de votre système est GEBERIT.
    Vous constatez que votre chasse d’eau fuit, que de l’eau s’écoule dans la cuvette de vos toilettes, ou bien que votre réservoir n’arrive plus à se remplir.
    Sachez avant tout que la fiabilité de la marque GEBERIT n’est plus à démontrer au niveau de la qualité des produits.
    La panne que vous rencontrez peut provenir du mécanisme de chasse, du robinet flotteur, du joint, du flexible….
    Une chasse d’eau comprend deux parties, une servira à remplir l’eau avec le flotteur, la seconde à vider l’eau.
    Pour résumer une chasse d’eau est équipée:
    D’un système de remplissage muni d’un flotteur dont le rôle est de stopper l’arrivée de l’eau quand le réservoir est plein
    D’un système de vidage de l’eau autrement appelé « cloche » dont le rôle est de libérer l’eau lorsque vous tirez la chasse d’eau.
    L’usure et le calcaire peuvent entraîner des détériorations de votre matériel.
    Une réparation est possible surtout avec la marque GEBERIT mais il arrive qu’un remplacement de mécanisme par exemple soit nécessaire.
    Le temps de réparation va varier entre 1 heure et 1 heure et demie.`
  },
  {
    id: "112",
    content: `Votre chasse d’eau fuit ou ne marche plus.
    Vous constatez que votre chasse d’eau fuit, que de l’eau s’écoule dans la cuvette de vos toilettes, ou bien que votre réservoir n’arrive plus à se remplir. Ce problème peut provenir de l’ensemble de la chasse d’eau ou du mécanisme ou encore du robinet flotteur, d’un serrage de joint, d’un réglage du flotteur, du joint à remplacer, du remplisseur d’eau …
    Une chasse d’eau comprend deux parties, une servira à remplir l’eau avec le flotteur, la seconde à vider l’eau. Parfois, notre plombier réglera simplement le flotteur afin que le niveau de remplissage de l’eau ne dépasse pas la hauteur de sécurité.
    Pour résumer une chasse d’eau est équipée:
    D’un système de remplissage muni d’un flotteur dont le rôle est de stopper l’arrivée de l’eau quand le réservoir est plein
    D’un système de vidage de l’eau autrement appelé « cloche » dont le rôle est de libérer l’eau lorsque vous tirez la chasse d’eau
    L’usure et le calcaire peuvent entraîner des détériorations de votre matériel.
    Plusieurs solutions peuvent résoudre votre problème allant d’une simple réparation à un remplacement de pièce.
    Le temps de réparation va varier entre 1 heure et 1 heure et demie.`
  },
  {
    id: "113",
    content: `Vous avez un problème avec votre WC simple et vous ne savez pas lequel.
    Dans ce cas précis, nous n’avons pas assez d’information de votre part afin de pouvoir définir votre panne et vous guider.
    Nous avons bien compris que votre problème concerne votre WC mais veuillez nous donner davantage de précisions, notamment si le dépannage que vous souhaitez concerne le fonctionnement de vos toilettes, ou s’il s’agit d’un engorgement de WC, d’une fuite ou d’un autre souci`
  },
  {
    id: "114",
    content: `Vous voulez installer de nouveaux WC
    Vous souhaiteriez remplacer votre WC simple mais vous ne savez pas quel matériel choisir. Faites appel aux plombiers de MesDépanneurs.fr afin qu’ils vous fournissent et installent vos nouveaux sanitaires!
    Un WC simple demande un entretien simple de vos toilettes et facilite les réparations afférentes. En effet, à l’inverse d’un WC suspendu ou d’un WC avec sanibroyeur, le format de votre WC simple facilitera les interventions des dépanneurs, et vous évitera des réparations coûteuses, tel que casser le coffrage pour un WC suspendu.
    Les grandes surfaces et les magasins professionnels vous proposent un vaste choix de modèles de marques différentes adaptés à tout budget.
    Le plombier vous conseillera sur le choix de vos WC et les installera
    Votre plombier vous en parlera lors de sa pré-visite chez vous et vous pourrez alors choisir ensemble vos nouveaux WC. Il s’occupera de l’achat du matériel et viendra vous l’installer.
    Il va tout d’abord enlever vos anciennes toilettes. Ensuite il installera les nouvelles et posera les joints sur les tuyaux d’arrivée et de sortie des eaux usées.
    L’installation d’un WC simple dure entre 1h et 2h, sans inclure le temps de pré-visite et la dépose de votre appareil précédent si besoin.
    Information utile pour le cas “Fourniture et pose d’un WC simple”
    Le prix indiqué concerne une installation de WC à l’identique. Si vous possédiez un autre système de WC auparavant, notre prestataire établira un devis correspondant à la dépose de votre matériel précédent, ainsi qu’à l’installation à effectuer.`
  },
  {
    id: "115",
    content: `Problème de lavabo bouché
    Vous avez un évier ou un lavabo qui est bouché. Votre installation est équipée d’un sanispeed.
    L’engorgement d’un évier ou l’engorgement d’un lavabo sont des pannes très courantes dans les dépannages de plomberie sanitaire.
    Sachez qu’il ne vaut mieux pas que vous mettiez des produits « déboucheurs » que vous pouvez trouver dans les commerces au rayon plomberie car ce type de produit va se déposer sur votre tuyauterie puis durcir et engorger davantage votre canalisation, ce qui va découler sur un dégorgement plus complexe au final par un plombier.
    Nous vous le précisons car un certain nombre de nos clients commettent cette erreur avant de nous appeler ce qui au final leur aura généré plus de frais entre l’achat du produit qui aura aggravé la situation, et le coût de le dépannage d’un plombier.
    Débouchage de lavabo ou d' évier avec sanispeed
    La cause du «bouchage» de votre évier peut provenir d’une accumulation d’aliments, d’insalubrités, de votre siphon….
    La cause du «bouchage» de votre lavabo peut quant à elle provenir d’une accumulation de cheveux, du siphon….
    La réparation de plomberie va varier selon le niveau d’engorgement de vos appareils sanitaires et pour effectuer un débouchage, le plombier aura différentes possibilités selon le niveau d’engorgement.
    Informations utiles pour le "Débouchage d'un lavabo / évier avec sanispeed"
    Le temps de réparation va aussi varier allant de 1 heure à 2 heures dans les cas les plus complexes.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "116",
    content: `Problème de lavabo bouché
    Vous avez un évier ou un lavabo qui est bouché.
    L’engorgement d’un évier ou l’engorgement d’un lavabo sont des pannes très courantes dans les dépannages de plomberie sanitaire.
    Sachez qu’il ne vaut mieux pas que vous mettiez des produits « déboucheurs » que vous pouvez trouver dans les commerces au rayon plomberie car ce type de produit va se déposer sur votre tuyauterie puis durcir et engorger davantage votre canalisation, ce qui va découler sur un dégorgement plus complexe au final par un plombier.
    Nous vous le précisons car un certain nombre de nos clients commettent cette erreur avant de nous appeler ce qui leur aura généré plus de frais entre l’achat du produit qui aura aggravé la situation, et le coût du dépannage d’un plombier.
    Intervention pour débouchage de lavabo / évier sans sanispeed
    Evier:
    La cause du « bouchage » de votre évier peut provenir d’une accumulation d’aliments, d’insalubrités, de votre siphon, d’un curage à effectuer sur votre tuyauterie, de la colonne générale de votre immeuble….
    Lavabo:
    La cause du « bouchage » de votre lavabo peut quand à elle provenir d’une accumulation de cheveux, du siphon, d’un curage à réaliser sur votre canalisation, cela peut aussi provenir de la colonne générale de votre immeuble….
    Infos utiles pour le "débouchage de lavabo / évier sans sanispeed"
    La réparation de plomberie va varier selon le niveau d’engorgement de vos appareils sanitaires et pour effectuer un débouchage, le plombier aura différentes possibilités selon le niveau d’engorgement. Cela peut se faire avec un système de pompe, mais aussi avec le dépôt d’un acide adapté qui doit être réalisé dans les règles de l’art par un plombier car en général il s’agit d’un produit très fort que l’on ne trouve que chez les revendeurs professionnels de la plomberie. Le passage d’un furet électrique sur un ou plusieurs niveaux peut aussi être le seul recours possible au dégorgement d'évier ou de lavabo, enfin s’il s’agit d’un engorgement général de la canalisation de l’immeuble qui se situe à votre niveau, cela nécessitera l’intervention d’un camion hydrocureur à haute pression.
    Le temps de réparation peut varier allant de 30 minutes à 2 heures dans les cas les plus complexes.`
  },
  {
    id: "117",
    content: `Vous souhaitez l’installation d’un mitigeur d’évier ou de lavabo ou vous rencontrez une fuite avec votre robinet mitigeur.
    Si une réparation de mitigeur n’est pas possible, notre plombier va vous guider dans le choix de votre robinetterie en fonction de vos souhaits, de votre budget et ainsi procéder à cette installation.
    Un mitigeur est plus pratique qu’un mélangeur, car il permet l’obtention rapide de la température souhaitée.
    Nous installons différentes marques de mitigeurs: GROHE, JACOB DELAFON, IDEAL STANDARD et tant d’autres.
    Notre plombier vous proposera donc le modèle de robinet mitigeur que vous aurez choisi et procèdera ensuite à son installation.
    Le temps de l’installation va varier entre 1 heure et deux heures.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "118",
    content: `L’installation d’un robinet mélangeur de lavabo ou d’évier est une installation que nous faisons tous les jours. Nous réparons et remplaçons aussi les mélangeurs qui fuient ou qui sont en trop mauvais état.
    L’équipe de MesDépanneurs est ravie de vous accompagner pour vous guider au mieux dans votre choix.
    Nous vous guiderons afin de se rapprocher au mieux de vos besoins car il y a un certain nombre de modèles et de marques telles que: GROHE, JACOB DELAFON…
    Notre plombier vous proposera certains modèles et c’est vous qui décidez de la qualité des matériaux, de l’esthétique des robinetteries mais aussi des fonctionnalités que vous souhaitez.
    Il procèdera ensuite à l’installation du nouveau matériel.
    Le temps de l’installation varie entre 1 heure et deux heures.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "119",
    content: `Vous souhaitez l’installation d’un robinet mitigeur thermostatique pour votre lavabo ou votre évier, ou bien votre mitigeur actuel a une fuite d’eau.
    Soit une réparation est possible ou votre robinetterie sera à remplacer.
    Pour information: une robinetterie thermostatique va vous permettre de régler et bloquer la température de l’eau durant toute la durée de son utilisation.
    Nous installons beaucoup de marques dont GROHE, JACOB DELAFON…
    Il y a plusieurs fonctionnalités possibles en fonction du modèle que vous choisirez et de la marque.
    Notre plombier vous proposera donc le modèle de mitigeur thermostatique pour votre évier ou votre lavabo que vous aurez choisi et procèdera ensuite à son installation.
    Le temps de l’installation varie entre 1 heure et deux heures.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "120",
    content: `"Les flexibles de votre évier ou de votre lavabo nécessitent d’être remplacés, et vous avez un robinet mélangeur ou un mitigeur.
    Il est possible de les remplacer sans changer la robinetterie mais parfois c’est votre robinet entier qu’il faudra changer.
    Il existe aussi des modèles de robinetterie d’évier avec douchette. Cette douchette peut aussi être remplacée.
    Ce dépannage n’est pas compliqué, et nos plombiers sont confrontés à ce type de panne très souvent.
    Le temps de réparation est compris entre 30 minutes et 1 heure et demie, en moyenne.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.
    "`
  },
  {
    id: "121",
    content: `Vous avez un lavabo ou un évier et votre siphon en PVC est à remplacer.
    Nos équipes sont là pour vous aider.
    Ce dépannage est courant en plomberie.
    Nos plombiers ont un certain nombre de siphon avec eux, afin d’avoir le modèle adéquat pour vous dépanner immédiatement.
    Le temps de réparation est compris entre 30 minutes et 1 heure, en moyenne.`
  },
  {
    id: "122",
    content: `Changement de siphon
    Le siphon de votre lavabo ou de votre évier fuit ou est défectueux.
    Notre service de plomberie est là pour vous assister et procéder au remplacement de siphon.
    Le siphon de votre évier ou de votre lavabo est destiné à retenir les odeurs, il est situé en dessous de votre appareil sanitaire, et il est toujours rempli d’eau. Il arrive souvent que le siphon soit obstrué par des déchets, insalubrités, cheveux et cela empêche la bonne évacuation de l’eau.
    En général, le siphon que l’on trouve le plus souvent sous les lavabos ou sous les éviers est un siphon « droit ».
    Un plombier pour le changement de siphon lavabo/évier en laiton
    Le siphon d’un évier est souvent installé dans le meuble de la cuisine et est facilement accessible.
    Le siphon d’un lavabo est dans la colonne de votre lavabo, si vous avez un lavabo avec ce modèle, notre plombier devra démonter votre colonne avec précaution car il y a un risque de casse. Si votre lavabo est incorporé dans un meuble, nous y accéderons soit par l’ouverture de la porte de votre meuble ou en le démontant.
    Lors du démontage de votre siphon, des impuretés vont s’écouler, une fois le culot ôté c’est normal.
    Infos utiles pour le "Changement de siphon lavabo / évier en laiton"
    Le temps estimé de la réparation peut varier de 30 minutes à 1 heure, en règle générale.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "123",
    content: `Un lavabo ou un évier à remplacer?

    Votre lavabo / évier est sujet à des fuites, ou vos canalisations ont été mal mises en place, conduisant à des fuites… L’esthétique de votre lavabo / évier ne vous satisfait plus… Bref, vous avez pris la décision de le remplacer.
    Les modèles de lavabo / évier sont multiples. Pour bien choisir votre matériel, prenez en compte plusieurs critères : la superficie de votre espace (salle de bain ou cuisine), l’utilisation prévue pour cet appareil, le design de la pièce, le budget que vous êtes prêt à y accorder. Vous avez la possibilité de choisir également le type de votre lavabo : accroché directement au mur (lavabo suspendu), encastré dans le meuble de salle de bain ou de cuisine, posé sur une colonne...
    Comment choisir et installer votre nouveau lavabo / évier?
    Notre plombier pourra vous conseiller sur le modèle et la marque adaptés à vos besoins lors d’une pré-visite gratuite.
    Une fois que votre choix est fait et que le matériel est reçu par l’artisan, il viendra vous installer votre nouveau lavabo / nouvel évier. Notre plombier ôtera votre ancien appareil et vous installera le modèle que vous avez choisi avec son robinet. Il s’occupera des raccordements des flexibles, de l’évacuation des eaux et du montage du siphon. La pose de votre nouvel évier ou lavabo prendra entre 1h et 3h, plus le temps nécessaire pour la pré-visite initiale et la dépose de votre ancien appareil.
    Le plombier vous donnera les conseils sur l’entretien de votre lavabo / évier et de son utilisation.
    Information utile pour le cas “Fourniture et pose d’un lavabo / évier”
    Si vous n’avez pas choisi le même type d’évier ou de lavabo que vous possédiez précédemment, il est possible que notre plombier soit dans l’obligation d’installer des canalisations adaptées au modèle de votre choix. L’installation de ces nouvelles canalisations fera l’objet d’un nouveau devis.`
  },
  {
    id: "124",
    content: `Vous avez un problème avec votre lavabo ou votre évier WC mais vous ne savez pas exactement d’où vient la panne.
    Nous n’avons pas assez d’information de votre part afin de pouvoir définir votre panne et vous guider.
    Nous avons bien compris que votre problème concerne votre lavabo ou votre évier mais nous avons besoin que vous nous donniez davantage de précisions, notamment si le dépannage que vous souhaitez concerne le fonctionnement de votre lavabos, ou s’il s’agit d’un engorgement de votre évier, d’une fuite ou d’un autre souci.
    Nous vous invitons à remplir le champ ci-dessous afin qu’un opérateur de MesDépanneurs.fr vous contacte très rapidement.`
  },
  {
    id: "125",
    content: `L’eau de votre douche ne s’évacue pas et stagne, cela veut dire que votre douche est bouchée. l’engorgement d’une douche est un des problèmes les plus courants en matière de dépannage plomberie sanitaire.

    Cela ne sert à rien de mettre des produits « déboucheurs » que l'on peut trouver dans les magasins de bricolage ou dans les grandes surfaces au rayon plomberie, en effet ce type de produit va se déposer sur vos canalisations, durcir et engorger encore plus votre tuyauterie, ce qui demandera une intervention pour un dégorgement plus complexe au final par un dépanneur plombier.
    Chez MesDépanneurs,fr, nous pensons qu’il est primordial de vous en informer car la majorité de nos clients commet cette erreur juste avant de nous contacter, ce qui en finalement demandera plus de frais avec l’achat du produit, alors ne perdez pas de temps et appelez directement le plombier professionnel de MesDépanneurs.fr.
    Les solutions débouchage de douche ou baignoire: MesDépanneurs.fr
    La cause du «bouchage» de votre douche peut être d'origines multiples, cela peut provenir d’une accumulation de cheveux, de canalisations bouchées nécessitant un curage de canalisation, cela peut également être causé par un problème de colonne générale dans votre immeuble.
    Le débouchage de douche varie selon le niveau d’engorgement de la douche et le dépanneur plombier a plusieurs possibilités pour réaliser le dégorgement de douche. Il peut utiliser un système de pompe, mais également un acide adapté, cette opération doit de faire dans les règles de l’art par un véritable plombier car généralement il s’agit d’un produit très fort et dangereux que l’on ne peut trouver que chez les revendeurs professionnels de la plomberie. L'utilisation d'un furet électrique sur un ou plusieurs niveaux peut être un recours pour le dégorgement de votre douche et s’il s’agit d’un engorgement général de canalisation de l’ immeuble se situant à votre niveau, cela nécessitera le passage d'un camion hydrocureur (de curage) à haute pression pour un dégorgement de canalisations efficace.
    Infos sur le prix de MesDépanneurs.fr pour déboucher une douche ou baignoire
    Le temps qu'il faut pour le débouchage de douche peut également varier, cela peut prendre de 30 minutes à 2 heures dans les cas les plus complexes.`
  },
  {
    id: "126",
    content: `Vous avez un problème avec votre baignoire, l'eau ne s’évacue plus, elle stagne, votre baignoire est bouchée, ce type de problème arrive souvent et fait partie des dépannages plomberie les plus courants.
    Pour votre information, nous vous déconseillons d'utiliser des produits qu'on dit « déboucheurs » pour déboucher une baignoire ces produits sont facilement accessible dans les grandes surfaces au rayon plomberie.
    Ces produits déboucheurs vont se déposer sur votre canalisation puis durcir avant d'engorger davantage votre tuyauterie, ce qui rendra beaucoup plus compliqué un débouchage, dégorgement qui semblait si simple au départ.
    C'est pour cela qu'il vaut mieux confier votre débouchage de baignoire à un véritable plombier que MesDépanneurs.fr met à votre disposition sur simple demande d'intervention.
    Contactez MesDépanneurs.fr pour votre débouchage de baignoire
    Votre baignoire est bouchée pas d'inquiétude MesDépanneurs.fr détermine la cause du bouchage de votre baignoire qui peut provenir du siphon, de l’évacuation, d’une accumulation de cheveux, d’un curage à réaliser sur votre canalisation, cela peut être également lié à un problème dans la colonne générale de votre immeuble.
    Le matériel utilisé par le dépanneur plombier de MesDépanneurs.fr pour déboucher votre baignoire dépendra du niveau de l’engorgement et il y aura donc plusieurs possibilités pour effectuer ce dégorgement.
    Le plombier professionnel de MesDépanneurs.fr pourra soit utiliser une pompe, un furet électrique, ou faire couler un acide adapté à votre tuyauterie, le passage de l'acide doit être réalisé par un véritable plombier car c’est un produit nocif et très fort. Enfin si le problème est dû à un engorgement de la canalisation générale de l’immeuble et que le bouchon se situe à votre niveau, l’intervention d’un camion hydrocureur (de curage) à haute pression sera nécessaire.
    MesDépanneurs.fr vous informe sur l'intervention de débouchage de baignoire
    Le temps nécessaire pour le débouchage de baignoire va aussi varier et peut aller de 30 minutes à 2 heures dans les cas les plus complexes.`
  },
  {
    id: "127",
    content: `Vous avez un problème ou une fuite d’eau dans votre salle de bains au niveau de l’évacuation de votre douche ou bien vous pensez que le souci vient de là mais vous ne pouvez pas voir car cette tuyauterie est encastrée.
    Selon votre installation, nous allons réparer ce problème par le remplacement de joints ou d’un coude, d’un raccord male ou femelle, par une soudure sur votre tuyauterie ou en remplaçant une partie de votre canalisation si elle est endommagée.
    Ce type de dépannage arrive quotidiennement en plomberie.
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite. Contactez votre compagnie d’assurance pour signaler le problème.
    Si votre tuyau est encastré, il va falloir casser une partie de votre mur ou du coffrage pour accéder à la canalisation.
    Une fois l’origine de la fuite déterminée, le plombier pourra effectuer les réparations.
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 4 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.`
  },
  {
    id: "128",
    content: `Problème de fuite de douche/baignoire
    Vous avez un problème ou une fuite d’eau dans votre salle de bains au niveau de votre douche ou de votre baignoire mais vous n’arrivez pas à trouver d’où cela fuit avec précision.
    Il se peut que vous appreniez l’existence d’une fuite d’eau chez vous par un voisin qui vous informe avoir des infiltrations d’eau sur son plafond ou son mur.
    En règle générale, la première chose à faire est de contacter en urgence votre compagnie d’assurance afin de signaler le problème. Celle-ci va vous demander de faire effectuer une recherche de fuite par un plombier.
    Ne négligez pas ce problème, car vous allez voir votre consommation d’eau grimper, et puis plus vous attendrez plus les dégâts seront conséquents, et en copropriété, cela va très vite.
    Intervention de plombier pour recherche de fuite
    Si vous avez une douche, le problème peut venir du bac à douche, de votre carrelage, de l’étanchéité, de la tuyauterie, de votre robinetterie….
    Si vous avez une baignoire, la fuite peut venir de votre baignoire, de l’étanchéité, du carrelage, de la tuyauterie sous votre baignoire, de la robinetterie…
    Si l’accès n’est pas libre, notre plombier va devoir casser une partie de votre mur ou du coffrage de votre baignoire pour pouvoir déterminer l’origine de la fuite d’eau.
    Une fois l’origine de la fuite trouvée, les réparations peuvent commencer.
    Un opérateur de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite.
    Informations utiles pour la "recherche de fuite douche et baignoire"
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 4 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "129",
    content: `Vous avez un problème ou une fuite d’eau dans votre salle de bains au niveau de l’évacuation de votre baignoire et votre tuyauterie n’est pas encastrée.
    Selon le problème de plomberie que vous rencontrez, la réparation portera sur le remplacement de joints par exemple ou en effectuant une soudure sur votre tuyauterie, en remplaçant une partie de votre canalisation si elle est endommagée, ou encore en changeant un coude ou un raccord male et femelle.
    Ce type de dépannage arrive quotidiennement en plomberie.
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite.
    Cette réparation est rapide en général, surtout avec une tuyauterie apparente,
    Le temps estimé pour cette réparation est de 1 heure à 2 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.`
  },
  {
    id: "130",
    content: `Vous avez un problème ou une fuite d’eau dans votre salle de bains au niveau de l’évacuation de votre baignoire et cette évacuation est encastrée.
    Selon votre installation, nous allons réparer ce problème par le remplacement de joints ou d’un coude, d’un raccord male ou femelle, par une soudure sur votre tuyauterie ou en remplaçant une partie de votre canalisation si elle est endommagée.
    Ce type de dépannage se fait quotidiennement en plomberie.
    Un opérateur de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la réparation.
    Si cette réparation est rapide avec une tuyauterie apparente, c’est plus long avec un tuyau encastré, et si la trappe de visite ne permet l’accès à la réparation, il va falloir casser une partie du coffrage de votre baignoire et donc du carrelage pour y accéder.
    Dans ce cas là, contactez votre compagnie d’assurance pour signaler le problème.
    Une fois que l’accès à la panne sera dégagé, le plombier pourra effectuer les réparations.
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 4 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "131",
    content: `Problème de fuite de douche/baignoire
    Vous avez un problème ou une fuite d’eau dans votre salle de bains au niveau de votre douche ou de votre baignoire mais vous n’arrivez pas à trouver d’où cela fuit avec précision.
    Il se peut que vous appreniez l’existence d’une fuite d’eau chez vous par un voisin qui vous informe avoir des infiltrations d’eau sur son plafond ou son mur.
    En règle générale, la première chose à faire est de contacter en urgence votre compagnie d’assurance afin de signaler le problème. Celle-ci va vous demander de faire effectuer une recherche de fuite par un plombier.
    Ne négligez pas ce problème, car vous allez voir votre consommation d’eau grimper, et puis plus vous attendrez plus les dégâts seront conséquents, et en copropriété, cela va très vite.
    Intervention de plombier pour recherche de fuite
    Si vous avez une douche, le problème peut venir du bac à douche, de votre carrelage, de l’étanchéité, de la tuyauterie, de votre robinetterie….
    Si vous avez une baignoire, la fuite peut venir de votre baignoire, de l’étanchéité, du carrelage, de la tuyauterie sous votre baignoire, de la robinetterie…
    Si l’accès n’est pas libre, notre plombier va devoir casser une partie de votre mur ou du coffrage de votre baignoire pour pouvoir déterminer l’origine de la fuite d’eau.
    Une fois l’origine de la fuite trouvée, les réparations peuvent commencer.
    Un opérateur de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite.
    Informations utiles pour la "recherche de fuite douche et baignoire"
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 4 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "132",
    content: `Votre bac de douche est à changer
    Les bacs de douche vous permettent de récupérer et d'évacuer l'eau. Ils sont souvent victimes de fissures ou de craquelures, liés au temps qui passe, à la mauvaise qualité du matériau ou encore parce qu’un objet assez lourd est tombé dessus. Ce genre d’imperfections, parfois difficilement détectables, peuvent pourtant devenir sources de problèmes bien plus importants, et entraîner des dommages conséquents et des fuites d’eau. Votre bac de douche est abîmé ou vous souhaiteriez le remplacer pour une toute autre raison?
    Il existe différents types de bacs de douche, en acrylique ou en céramique, en résine ou en grès. Ils peuvent être de formes différentes : carré, rectangulaire,.. et de dimensions différentes. Finalement, vous pourrez opter pour un bac de douche posé ou encastré.
    Faites appel au professionnel pour la fourniture et pose de votre matériel !
    Notre plombier saura installer votre nouveau bac à douche, et vous conseiller sur le choix du modèle. Lors d’une pré-visite gratuite, vous choisirez avec le plombier le modèle qui vous plaîra.
    Une fois que vous avez sélectionné votre bac de douche, l’artisan le commande et puis procède à son installation. Notre prestataire devra s’assurer de l’étanchéité de votre sol, ainsi que de vos parois. Si ce n’est pas le cas, notre plombier pourra vous proposer des solutions avant de pratiquer votre installation. Il la finalisera en reliant le bac à l’évacuation des eaux usées. La pose du joint de votre douche sera très importante, afin d’éviter les fuites voire un dégât des eaux. La durée d’installation de votre nouveau bac de douche (en dehors du temps de pré-visite) est comprise entre 2h et 3h, sans inclure le temps nécessaire à la dépose de votre ancien matériel.
    Les plombiers de MesDépanneurs.fr pourront vous conseiller sur l’entretien optimal de votre nouveau bac de douche.
    Information utile pour le cas “Fourniture et pose d’un bac de douche”
    Le prix indiqué concerne l’installation de bac de douche à l’identique. Si vous possédiez un autre type de bac de douche auparavant, notre prestataire établira un devis correspondant à la dépose de votre matériel précédent, ainsi qu’à l’installation à effectuer.`
  },
  {
    id: "133",
    content: `Changer votre baignoire
    Il est possible que votre baignoire précédente soit abîmée, et connaisse des fuites, ou plus simplement qu’elle ne vous convienne plus. Vous souhaitez donc la changer. Grâce à la multitude de modèles déjà existants sur le marché, nous trouverons forcément la baignoire adaptée à vos envies.
    Le plombier est apte à vous conseiller et à poser la baignoire
    Notre plombier effectuera tout d’abord une pré-visite dans votre logement. Lors de ce premier entretien, il vous conseillera sur le modèle à choisir. Plusieurs éléments sont à prendre en compte : la taille de votre future baignoire pour qu’elle soit adaptée à l’espace, le matériau à sélectionner entre le bois, l’émaille, l’acrylique ou encore le toplax ainsi que la forme : ronde, carrée, ovale, encastrée ou surélevée pour assurer l’harmonie avec le décor de votre salle de bain.
    Une fois que votre baignoire est choisie et est achetée par l’artisan, il viendra l’installer. Afin de mettre en place votre baignoire, il faudra au préalable effectuer la dépose de votre ancienne baignoire. Il faudra ensuite vérifier la parfaite étanchéité de votre sol, afin d’éviter tout dégât des eaux. Notre prestataire devra effectuer le réglage de niveau de votre baignoire, l’appuyer contre un mur si nécessaire avec des tasseaux, et pourra vous conseiller sur la qualité de vos raccordements. Il vérifiera par la suite le bon écoulement des eaux usées dans la canalisation adaptée. La durée d’installation de votre nouvelle baignoire est comprise entre 2h et 3h, sans inclure le temps nécessaire à la dépose de votre ancien matériel.
    Le plombier vous conseillera sur l’entretien de votre baignoire et de son utilisation.
    Information utile pour le cas “Fourniture et pose d’une baignoire”
    Le prix indiqué concerne uneinstallation de baignoire à l’identique. Si vous possédiez un autre type de baignoire auparavant, notre prestataire établira un devis personnalisé.`
  },
  {
    id: "134",
    content: `Vous avez un problème ou une fuite d’eau dans votre salle de bains au niveau de votre douche mais vous n’arrivez pas à trouver d’où cela fuit avec précision.
    Selon le modèle de votre bac à douche, il se peut qu’il n’y ait pas de trappe de visite en dessous, tout comme vous pouvez apprendre qu’il y a une fuite d’eau chez vous au niveau de votre salle de bain et c’est votre voisin qui vous l’annonce car il a des infiltrations d’eau chez lui.
    Sachez dans ce cas que ce type de dépannage de plomberie arrive quotidiennement.
    Dans un premier temps, contactez votre compagnie d’assurance afin de signaler le problème. Celle-ci va vous demander de faire effectuer une recherche de fuite par un plombier.
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite.
    Sachez que cette recherche peut être rapide comme longue car l’origine du problème peut être multiple. De plus notre plombier peut trouver la fuite à l’aide d’appareillage étudié pour mais il se peut que nous soyons obligés de casser votre mur ou même votre bac à douche pour trouver cette fuite d’eau.
    En effet, cela peut venir de votre bac à douche en lui-même, cela peut aussi provenir de la tuyauterie ou canalisation, de votre carrelage, de votre robinetterie, de l’étanchéité de votre bac à douche…
    Une  fois l’origine de la fuite déterminée, le plombier pourra effectuer les réparations.
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 4 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.`
  },
  {
    id: "135",
    content: `Bon à savoir
    Vous rencontrez des problèmes avec votre salle de bain, vous avez une fuite d’eau au niveau de votre baignoire mais vous ne savez pas d’où vient cette fuite.
    Il arrive très souvent que ce soit votre voisin qui s’en aperçoive avant vous car il aura des infiltrations d’eau.
    Chaque jour, MesDépanneurs.fr ont des demandes pour ce type de dépannage de plomberie.
    Avant toute chose, contactez votre compagnie d’assurance afin de signaler le problème et votre assureur va alors vous demander de faire effectuer une recherche de fuite par un plombier.
    Un opérateur de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite.
    Sachez que cette intervention de plomberie peut être courte ou longue, selon votre installation car le problème peut avoir plusieurs origines.
    Si nos plombiers sont habitués à effectuer des recherches de fuite et ont du matériel adapté pour se faire, il arrive qu’ils soient obligés de casser votre mur par exemple pour trouver l’origine de cette fuite d’eau.
    La fuite peut peut venir de votre baignoire, de la tuyauterie ou canalisation, de votre carrelage, de votre robinetterie, de l’étanchéité de baignoire.
    Une fois l’origine de la fuite déterminée, le plombier pourra effectuer les réparations.
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 4 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.
    Le service plomberie de MesDépanneurs.fr diagnostiquera avec précision la nature de votre panne, sur place, et vous établira un devis détaillé pour sa prestation.`
  },
  {
    id: "136",
    content: `Problème de fuite sur tuyauterie d’évacuation
    Vous avez une fuite sur votre tuyauterie d’évacuation. Ce type de problème de plomberie est fréquent et peut se résoudre rapidement.
    Il y a bien différents tuyaux d’évacuation dans votre appartement et la première chose à faire en attendant notre plombier est de couper l’eau. Si vous n’avez pas de robinet d’arrêt dans votre logement, allez voir votre gardien par exemple afin que l’eau soit coupée en urgence.
    Une fois notre plombier sur place, il examinera votre problème afin de réparer cette fuite.
    Intervention pour réparation de fuite du tuyau d'évacuation
    Il y a des installations différentes dans chaque logement, et parfois les tuyaux sont encastrés et là malheureusement il faut casser le mur pour accéder à cette tuyauterie.
    La réparation peut consister à remplacer un joint ou à effectuer une soudure selon la nature du problème ou encore remplacer une partie de la tuyauterie endommagée.
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à cette réparation.
    Informations utiles pour la "réparation de fuite du tuyau d'évacuation"
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 2 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place. Dans le cas de tuyaux encastrés la réparation peut prendre plusieurs heures.`
  },
  {
    id: "137",
    content: `Le robinet d’arrêt de votre logement fuit.
    Il se peut que vous n’arriviez pas à le fermer pour couper l’eau et stopper la fuite ou si vous y êtes arrivés, que de l’eau continue de couler.
    Ce type de problème de plomberie est fréquent et peut se résoudre rapidement.
    Le problème peut venir du presse-étoupe (un écrou), d’un joint, de la filasse, du robinet en lui-même.
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à cette réparation.
    Le temps estimé de la réparation peut varier de 1 heure à 2 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.`
  },
  {
    id: "138",
    content: `Problème de fuite de vanne principale
    Vous avez une fuite sur votre canalisation qui est située après le robinet d’arrêt de votre habitation, le propriétaire doit prendre immédiatement les mesures qui s’imposent pour faire cesser cette fuite.
    La première chose à faire étant bien sur de fermer le robinet d’arrivée d’eau pour limiter les dégâts, la seconde étant de contacter en urgence un plombier pour un dépannage.
    La troisième chose à faire est de contacter votre assureur.
    L’origine de votre fuite peut bien sur être multiple, cela peut être un joint à remplacer, une soudure à effectuer ou une partie de la tuyauterie qui est endommagée. C’est pourquoi le plombier pourra mesurer l’ampleur des réparations à effectuer une fois sur place.
    Un plombier pour la réparation de fuite de la vanne principale d'appartement
    Une fuite d’eau c’est toujours impressionnant, et en copropriété cela peut engendrer de gros dégâts, donc agissez au plus vite afin d’éviter une longue remise en état.
    Si la fuite provient d’une canalisation apparente, la tâche sera simplifiée pour l’intervention de plomberie, si en revanche il s’agit d’une tuyauterie avec une installation encastrée, il va falloir procéder à une recherche de fuite et casser une partie de l’endroit où la fuite d’eau est supposée. Dans ce dernier cas, en général, c’est votre voisin qui s’aperçoit de votre fuite en premier car il a des écoulements d’eau sur son plafond ou son mur.
    Infos utiles pour la "réparation de fuite de la vanne principale d'appartement"
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous en urgence et réparer votre fuite.
    Le temps estimé entre la recherche de fuite et de la réparation peut varier de 1 heure à 3 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place. Dans le cas des tuyaux encastrés la réparation peut prendre plusieurs heures.`
  },
  {
    id: "139",
    content: `Identifier les origines de la fuite d'eau
    Vous remarquez des écoulements d'eau ou d'humidité dans votre local ou votre habitation.
    Vous cherchez en vain et ne voyez pas du tout d’où vient cette eau, ou bien votre voisin du dessous, si vous habitez un immeuble, vous apprend qu’il y a des infiltrations d’eau à son domicile qui semblent provenir de chez vous.
    Sachez que vous devez immédiatement le signaler à votre compagnie d’assurance qui vous demandera de faire effectuer en urgence une recherche de fuite par un professionnel afin de déterminer son origine et ainsi effectuer les réparations.
    Dans une copropriété, ce genre de problème peut engendrer des dégâts conséquents et une longue remise en état avec bien évidemment le passage d’un expert.
    Ne négligez pas le problème de la fuite d'eau, vous aurez une consommation d’eau en forte augmentation si vous ne faites rien et les dégâts seront à chaque minute plus lourds, donc agissez vite.
    Le plombier effectuera la prestatation de recherche de fuite
    La plateforme de MesDépanneurs.fr vous mettra en relation avec un plombier compétent qui va intervenir chez vous en urgence.
    Si votre installation de plomberie est accessible et apparente, la recherche de fuite sera simple et rapide. Si votre plomberie sanitaire est encastrée, c’est plus compliqué et il faudra procéder à une recherche de fuite approfondie et ainsi casser probablement une partie de l’installation pour déterminer l’origine de votre fuite.
    L’origine de votre fuite peut, bien sûr, être multiple (la fuite peut provenir des points d’eau de votre logement, des WC, de la salle de bain, de la cuisine, de la tuyauterie…), c’est pourquoi le plombier pourra mesurer l’ampleur des réparations à effectuer une fois la situation évaluée.
    Le temps estimé pour la recherche de fuite peut varier de 1 heure à 4 heures, mais il est déterminé avec précision une fois sur place.
    Information utile pour le cas "Recherche de fuite"
    Le prix de MesDépanneurs.fr indiqué à droite comprend la prestation de recherche de fuite et si besoin, la rédaction du rapport pour votre assurance, mais ne comprend pas la réparation. Le devis pour la réparation de fuite vous sera proposé gratuitement par notre plombier.`
  },
  {
    id: "140",
    content: `Vous avez une fuite d’eau sur la tuyauterie de votre lave vaisselle ou de votre machine à laver.
    Soyez bien certain que le souci ne vient pas de votre machine car nous n’intervenons pas sur les pannes concernant l’électroménager.
    Cette fuite peut se produire sur la tuyauterie et si c’est le tuyau d’évacuation de la machine qui fuit, il faut le remplacer.
    Cela peut aussi être le raccord, le joint, l’écrou, l’embout d’alimentation, le tuyau de vidange.
    Sachez que la hauteur du branchement d’évacuation doit être comprise entre 40 cm et 1 m.
    Ce type de dépannage arrive quotidiennement en plomberie.
    La plateforme de MesDépanneurs.fr va vous mettre en relation avec un plombier compétent qui va intervenir chez vous et procéder à la recherche de fuite.
    Le temps estimé pour cette réparation est de 1 heure à 2 heures la plupart du temps, mais bien sur à déterminer avec précision une fois sur place.`
  },
  {
    id: "141",
    content: `Vous avez un radiateur électrique, un convecteur ou un sèche serviette qui est en panne.
    En effet, celle-ci peut venir du raccordement électrique, de l’alimentation, de votre convecteur ou de votre sèche serviette mais aussi d’une pièce défectueuse telle que l’interrupteur, la résistance ou le thermostat.
    N’intervenez pas vous-mêmes sur votre installation électrique car cela peut être dangereux.
    Attention, le prix indiqué ci-dessous ne comprend pas le remplacement de votre radiateur électrique, convecteur ou sèche serviette, mais il comprend une simple réparation.
    Nous attirons votre attention sur le fait que si votre installation électrique n’est pas aux normes, notre électricien vous le signalera immédiatement. Si vous ne souhaitez pas procéder à la remise en conformité de votre installation électrique, la responsabilité de MesDépanneurs.fr et de l’électricien ne saurait être engagée.`
  },
  {
    id: "142",
    content: `Vous disposez d’un convecteur électrique ou d’ un sèche serviette et celui ci est à remplacer. Soit il est trop ancien, soit il consomme trop, ou encore il est irréparable.
    Notre dépanneur spécialisé en électricité va intervenir à votre domicile afin de voir quel modèle de radiateur électrique vous avez, ce que vous souhaitez en remplacement de celui ci  et quelle en est la puissance pour pouvoir vous établir un devis de remplacement.
    Si vous acceptez son devis, le remplacement sera réalisé.`
  },
  {
    id: "143",
    content: `Vous avez acheté un convecteur et souhaitez que nous l’installions. C’est tout à fait possible, mais nous ne vous garantirons que la main d’oeuvre, pas le matériel bien sur.
    Nous vous proposons un forfait d’installation compris entre 100 et 150 euros, hors eventuelles petites fournitures ou un tirage de ligne électrique par exemple.
    Dans tous les cas, nous effectuerons un devis avant l’installation de votre convecteur.`
  },
  {
    id: "144",
    content: `Problème de chauffage collectif
    Vous avez un problème sur votre radiateur ou sur plusieurs radiateurs. Vos radiateurs ne chauffent plus ou il y a une fuite.
    ATTENTION:
    Avant de continuer, il faut que vous puissiez vérifier si votre radiateur est muni d’un robinet à boisseau qui est un appareil métallique permettant l’arrêt du circuit de chauffage.
    Intervention de chauffagiste pour problème de chauffage collectif
    Si vos radiateurs sont bien équipés d’un robinet à boisseau, un chauffagiste de MesDépanneurs.fr va pouvoir vous dépanner et donc remédier à votre problème. Si vos radiateurs ne chauffent plus, il se peut qu’il y ait de l’air dans votre circuit et qu’un désembouage soit nécessaire ou si votre radiateur fuit, dans ce cas cela peut venir du robinet de votre radiateur ou du radiateur en lui-même.
    Si vos radiateurs n’ont pas de robinets à boisseau, nous ne pourrons rien faire car dans ce cas il faut contacter le chauffagiste de votre immeuble qui sera la seule personne pouvant effectuer vos réparations. (demandez ses coordonnées à votre syndic ou au gardien).
    Infos utiles pour la "réparation de chauffage collectif (avec robinet à boisseau)"
    Le prix indiqué ci-dessous comprend la réparation de radiateurs mais ne comprend pas leur remplacement.`
  },
  {
    id: "145",
    content: `Attention ! Vous avez indiqué que votre chauffage est central et que vous ne possédez pas de robinet à boisseau ou ne connaissez pas son emplacement. Afin de résoudre ce problème, il est nécessaire de couper le circuit. Sans robinet à boisseau, seul un chauffagiste attaché à votre immeuble pourra intervenir. Contactez-le au plus vite.`
  },
  {
    id: "146",
    content: `Panne de votre chaudière E.L.M.Leblanc
    Vous avez une chaudière au gaz qui est en panne ou qui fuit
    Votre chaudière peut cesser de fonctionner si une pièce est endommagée, si vos conduits sont bouchés et gênent la répartition de chaleur, ou si vous n’avez pas fait l’entretien annuel de la chaudière.
    Si vos radiateurs demeurent froids malgré la chaudière qui est mis en route, il est possible que la panne ne provienne pas obligatoirement de la chaudière mais des convecteurs utilisés. Un diagnostic précis est à effectuer sur place.
    Votre chaudière est de la marque E.L.M Leblanc, la plus répandue actuellement sur le marché.
    Intervention de chauffagiste sur chaudière E.L.M Leblanc
    Quand votre chaudière ne démarre pas ou qu'elle cesse de fonctionner, la meilleure solution est de contacter un chauffagiste, afin de s'assurer du bon fonctionnement de votre installation de chauffage.Afin de savoir quel est le problème de votre chaudière, un chauffagiste de MesDépanneurs.fr va se rendre chez vous et identifier la panne que vous rencontrez avec votre chaudière, que ce soit une fuite ou un problème de fonctionnement lié à l’eau chaude ou au chauffage.
    Le démontage de la calandre permettra d’accéder aux pièces de votre chaudière telles que le circulateur, le corps de chauffe…et d’identifier la panne. Si une pièce de votre chaudière est endommagée, un chauffagiste saura soit la réparer, soit dans la plupart des cas remplacer la pièce. Certaines pièces de votre chaudière peuvent être encrassées, le brûleur ou le gicleur peuvent nécessiter un réglage particulier selon l’utilisation que vous avez de votre installation.
    Le détartrage et la vidange sont obligatoires pour tout appareil de chauffe. Si dans le cas d'une chaudière électrique, vous pouvez effectuer ces manipulations vous-même, quand s'agit d’une chaudière au gaz, le détartrage et la vidange doivent être faits par un chauffagiste diplômé.
    Spécialistes chauffagistes de la marque ELM Leblanc
    Les chauffagistes de MesDépanneurs.fr sont spécialistes de la marque E.L.M Lebanc et interviennent chez vous afin de vous dépanner en urgence, que votre chaudière soit murale, au sol, conventionnelle, à condensation, à système solaire ou avec une solution hybride mais aussi qu’elle soit à ventouse ou cheminée.
    Pour les chaudières murales au gaz conventionnelles ou à condensation de la marque E.L.M Leblanc nous dépannons les modèles Acléis, Mégalis, Odéalis Egalis, Stellis...
    Pour les chaudières au sol à gaz de cette marque, conventionnelles ou à condensation, nous intervenons sur la Soltis, la Stellis….
    Infos utiles: "Réparation d'une chaudière au gaz de marque E.L.M Leblanc"
    Nous vous conseillons fortement de ne pas intervenir vous-mêmes sur les appareils de chauffe au gaz, laissez ce travail à un professionnel.
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour une chaudière pour des raisons de sécurité et pour une meilleure longévité de votre appareil. Vous pouvez souscrire ce contrat auprès de notre chauffagiste.
    Le prix de MesDépanneurs.fr indiqué à droite comprend la réparation mais ne comprend pas le remplacement de chaudière si le chauffagiste constate que la réparation n'est pas envisageable.`
  },
  {
    id: "147",
    content: `Problème de chaudière à gaz Frisquet
    Vous avez une chaudière au gaz de la marque FRISQUET qui est en panne ou qui fuit.
    MesDépanneurs.fr va vous envoyer un chauffagiste spécialisé dans la marque FRISQUET qui vous assistera en urgence
    Quelle que soit la chaudière que vous avez, qu’elle soit murale ou au sol, basse température ou à condensation, hybride à bi-énergie (condensation et énergie solaire) ou avec une pompe à chaleur.
    Si vous avez une chaudière murale, nous dépannons et réparons tous les modèles FRISQUET : HYDROMOTRIX, HYDROCONFORT..
    Pour les chaudières au sol à gaz de cette marque, nous réparons aussi tous les modèles dont la chaudière Prestige.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    Un chauffagiste pour réparer une chaudière au gaz de marque Frisquet
    Afin de savoir quel est la panne de votre chaudière FRISQUET, un chauffagiste de MesDépanneurs.fr va se rendre chez vous et que ce soit une fuite ou un problème de fonctionnement lié à l’eau chaude ou au chauffage, il vous proposera la réparation adéquate.
    Le démontage de la calandre permettra d’accéder aux pièces de votre chaudière telles que le circulateur, le corps de chauffe…et d’identifier la panne.
    Si votre chaudière est mixte et que donc elle chauffe l’eau et le chauffage et que vous avez un ou deux radiateurs qui ne chauffent plus, la panne va surement provenir du circuit de chauffage, ou du radiateur défectueux. Si ce sont tous vos radiateurs qui ne chauffent plus, la panne que vous rencontrez vient de votre chaudière.
    Informations utiles pour la "réparation de chaudière à gaz de marque Frisquet"
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour la chaudière que vous soyez locataire ou propriétaire pour des raisons de sécurité et pour une meilleure longévité de votre appareil.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "148",
    content: `Problème de chaudière au gaz Saunier Duval
    Vous avez une chaudière au gaz de la marque SAUNIER DUVAL qui est en panne ou qui fuit.
    Les chauffagistes de MesDépanneurs.fr sont spécialisés dans la marque SAUNIER DUVAL et vont vous dépanner en urgence, que vous ayez une chaudière murale ou au sol, traditionnelle, à condensation, hybride mais aussi qu’elle soit à ventouse ou cheminée.
    Nous réparons tous les modèle de chaudières murales ou au sol à gaz traditionnelles ou à condensation de la marque SAUNIER DUVAL: Isosplit, Thema, Thema As, ThemaClassic et ThemaPlus, Isotwin, Duotwin condens, Isomax, Isotwin, Isofast...
    Afin de savoir quel est la panne de chaudière, un chauffagiste va se rendre chez vous et identifier la panne que vous rencontrez avec votre chaudière, que ce soit une fuite ou un problème de fonctionnement lié à l’eau chaude ou au chauffage.
    Un chauffagiste pour la réparer une chaudière au gaz de marque Saunier Duval"
    Le démontage de la calandre permettra d’accéder aux pièces de votre chaudière telles que le circulateur, le corps de chauffe…et d’identifier l’origine de la panne.
    Si votre chaudière est mixte (chauffage +eau chaude) et qu’un ou deux de vos radiateurs ne chauffent plus, la panne va surement provenir du circuit de chauffage, ou du radiateur en lui-même. Si aucun radiateur ne fonctionne, la panne que vous rencontrez vient de votre chaudière.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour la chaudière que vous soyez locataire ou propriétaire pour des raisons de sécurité et pour une meilleure longévité de votre appareil.
    Infos utiles pour la "Réparation d'une chaudière au gaz de marque Saunier Duval"
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour la chaudière que vous soyez locataire ou propriétaire pour des raisons de sécurité et pour une meilleure longévité de votre appareil.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "149",
    content: `Problème de chaudière au gaz Chaffoteaux
    Votre chaudière de marque CHAFFOTEAUX est en panne, fait du bruit ou elle fuit.
    Les chauffagistes de MesDépanneurs.fr sont spécialistes de la marque CHAFFOTEAUX.
    Un dépannage de chauffage va être réalisé chez vous et ce, que votre chaudière soit à basse température, à condensation, à système solaire…
    Pour les chaudières au gaz basse température ou à condensation de la marque CHAFFOTEAUX, nous dépannons les modèles Niagara, Talia, Urbia, Mira C, Inoa, Serelia, Pharos...
    Afin de savoir exactement quel est le problème de chaudière, un chauffagiste de MesDépanneurs.fr va donc se rendre chez vous et identifier la panne que vous rencontrez, que ce soit une fuite ou unproblème de fonctionnement lié à l’eau chaude ou au chauffage.
    Intervention de chauffagiste pour réparer une chaudière au gaz Chaffoteaux
    Le démontage de la calandre permettra d’accéder aux pièces de votre chaudière telles que le circulateur, le corps de chauffe...
    Si votre chaudière est mixte et que donc elle chauffe l’eau et le chauffage et que vous avez un ou deux radiateurs qui ne chauffent plus, la panne va surement provenir du circuit de chauffage, ou du radiateur défectueux.
    Si ce sont tous vos radiateurs qui ne chauffent plus, la panne que vous rencontrez vient de votre chaudière. Un diagnostic précis ne peut être établi que sur place.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    Infos utiles pour la "réparation de chaudière au gaz de marque chaffoteaux"
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour votre chaudière que vous soyez locataire ou propriétaire pour des raisons de sécurité et pour une meilleure longévité de votre appareil.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "150",
    content: `Problème de chaudière au gaz De Dietrich
    Vous avez une chaudière au gaz de la marque DE DIETRICH qui ne fonctionne plus ou qui fuit.
    Les chauffagistes de MesDépanneurs.fr sont spécialistes de la marque DE DIETRICH et vont vous dépanner et ce, que votre chaudière soit murale, au sol, à basse température, à bois, à condensation, à système solaire ou avec une solution hybride….
    Pour les chaudières murales au gaz conventionnelles ou à condensation de la marque DE DIETRICH, nous dépannons les modèles INNOVENS, NANEO, VIVADENS, ZENA…
    Pour les chaudières au sol à gaz de cette marque, conventionnelles ou à condensation, nous intervenons sur la TWINEO, MODULENS, WINGO, GTU C 120, PREMYS, ELITEC….
    Intervention de chauffagiste pour réparer une chaudière au gaz De Dietrich
    Afin de savoir quel est le problème de chaudière DE DIETRICH, un chauffagiste de MesDépanneurs.fr va se rendre chez vous et identifier la panne.
    Le démontage de la calandre permettra d’accéder aux pièces de votre chaudière telles que le circulateur, le corps de chauffe.
    Un exemple: si votre chaudière est mixte et que donc elle chauffe l’eau et le chauffage et que vous avez un ou deux radiateurs qui ne chauffent plus, la panne va surement provenir du circuit de chauffage, ou du radiateur défectueux. Si ce sont tous vos radiateurs qui ne chauffent plus, la panne que vous rencontrez vient de votre chaudière. Un diagnostic précis ne peut être établi que sur place.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    Infos pour la "réparation de chaudière au gaz de marque De Dietrich"
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour votre chaudière que vous soyez locataire ou propriétaire pour des raisons de sécurité et pour une meilleure longévité de votre appareil.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d' appareils.`
  },
  {
    id: "151",
    content: `Problème de chaudière au gaz de marque inconnue
    Vous avez une chaudière à gaz et vous ne connaissez pas sa marque ou vous ne la voyez pas.
    Votre chaudière ne fonctionne plus, fait du bruit ou fuit.
    Sachez que les chauffagistes de MesDépanneurs.fr sont des techniciens spécialisés qui vont pouvoir vous dépanner selon le problème que vous rencontrez.
    Le seul moyen de savoir avec précision la nature de la panne que vous avez, est de se rendre chez vous afin d’identifier le type de chaudière qui est installée, si vous avez une chaudière mixte par exemple ou une chaudière en chauffage seul, si un ballon réchauffeur est couplé…la marque aura son importance si un remplacement de pièce est à prévoir. Une fois la calandre démontée, notre chauffagiste pourra diagnostiquer avec précision, la réparation qui est à effectuer en testant les pièces de votre chaudière telles que le corps de chauffe, le circulateur….
    Intervention chauffagiste réparation de chaudière au gaz de marque inconnue
    Exemple: si votre chaudière est mixte, ce qui veut dire qu’elle chauffe et l’eau et le chauffage,et que vous avez un ou deux radiateurs qui ne chauffent plus, la panne va surement provenir du circuit de chauffage, ou du radiateur défectueux. Si ce sont tous vos radiateurs qui ne chauffent plus, la panne que vous rencontrez vient de votre chaudière.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour votre chaudière que vous soyez locataire ou propriétaire. En effet, un contrat d’entretien permet donc d’entretenir et de contrôler régulièrement l’état de fonctionnement de votre appareil, en général il s’agit de un contrôle par an.
    Infos utiles pour la "réparation d'une chaudière au gaz de marque inconnue"
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "152",
    content: `Vous avez une chaudière électrique de la marque ELM LEBLANC qui est en panne de chauffage ou qui fuit.
    MesDépanneurs.fr va vous envoyer un chauffagiste spécialisé dans la marque ELM LEBLANC et plus spécialement dans les chaudières électriques.
    La gamme de chaudière électrique que propose ELM LEBLANC est la Elitis.
    Afin de savoir quel est la panne de chaudière, nous allons intervenir chez vous afin de constater quel est le souci, si c’est un réglage à effectuer, une pièce à changer… Le seul moyen d’identifier le problème sera de le constater sur place en testant la chaudière. Un diagnostic précis vous sera alors établi.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "153",
    content: `Votre chaudière électrique est de marque SAUNIER DUVAL qui ne fonctionne plus, ou qui fuit.
    Vous allez recevoir un chauffagiste de MesDépanneurs.fr spécialisé dans la marque SAUNIER DUVAL et plus spécialement dans les chaudières électriques.
    Pour que le dépanneur chauffagiste puisse identifier la panne que vous rencontrez, nous allons intervenir chez vous afin de constater quel est le souci, si c’est un réglage à effectuer, une pièce à changer…Le seul moyen d’identifier votre panne sera de constater sur place votre chaudière et d’en tester les pièces. Ainsi, un diagnostic précis vous sera alors établi.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "154",
    content: `Votre chaudière est électrique et vous ne connaissez pas sa marque ou vous n’arrivez pas à l’identifier. Votre chaudière fuit, ne fonctionne plus, ou fait du bruit.
    Sachez que les chauffagistes de MesDépanneurs.fr sont des techniciens spécialisés dans les chaudières électriques, et ils pourront vous dépanner selon le problème que vous avez. Afin de déterminer avec précision la nature de la panne que vous rencontrez, un chauffagiste devra se rendre chez vous pour identifier le modèle de chaudière qui est installée ainsi que sa marque.
    Après une inspection de votre chaudière et les pièces testées, notre chauffagiste vous proposera la réparation adéquate. La marque aura son importance si une pièce est à remplacer.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "155",
    content: `Problème de chaudière autre qu' au gaz ou à l'électricité
    Vous avez une chaudière à fioul ou bien à fluide thermique qui est en panne ou qui fuit.
    Un chauffagiste de MesDépanneurs.fr va vous dépanner. Pour se faire, il faudra qu’il se rende chez vous afin d’identifier le problème que vous rencontrez avec votre chaudière, que ce soit une fuite ou un problème de fonctionnement lié à l’eau chaude ou au chauffage.
    Quoi qu’il en soit, un démontage de la calandre permettra au chauffagiste de tester vos pièces ou d’accéder à la panne.
    Un chauffagite pour réparer une chaudière autre qu'au gaz ou à l'électricité
    Pour vous citer un exemple, si votre chaudière est mixte, c'est-à-dire qu’elle chauffe l’eau et le chauffage et que vous avez un ou deux radiateurs qui ne chauffent plus, la panne va surement provenir du circuit de chauffage, ou du radiateur défectueux. Si ce sont tous vos radiateurs qui ne chauffent plus, la panne que vous rencontrez vient de votre chaudière. Un diagnostic précis ne peut être établi que sur place.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, laissez ce travail à un professionnel.
    Infos utiles pour la "réparation de chaudière autre qu'au gaz et à l'électricité"
    Nous vous rappelons pour votre sécurité et pour la longévité de votre appareil, qu’il est obligatoire de faire réaliser un contrat d’entretien sur la chaudière.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "156",
    content: `Vous avez un ballon d’eau chaude électrique, autrement appelé un cumulus de marque SAUTER qui est en panne ou qui a une fuite d’eau.
    En ce qui concerne les ballons d’eau chaude, la panne peut concerner la plomberie/chauffage si votre ballon fuit ou si la résistance ou le thermostat sont à remplacer ou encore si un détartrage est à effectuer.
    Dans tous les cas, un dépanneur spécialisé dans la marque SAUTER va intervenir et vous dépanner, ce quel que soit le modèle de chauffe eau électrique que vous ayez et de n’importe quelle capacité, qu’il soit horizontal ou vertical dont les modèles de gammes Prodigio, Essentiel…
    Il se peut aussi que votre ballon d’eau chaude disjoncte et ne chauffe plus. Dans ce cas, un électricien devra intervenir pour voir ce qui se passe.
    Pour savoir avec précision quelle est la nature de votre panne, notre dépanneur va se rendre chez vous et tester votre ballon d’eau chaude SAUTER pour diagnostiquer sa panne et ainsi vous établir le devis correspondant.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "157",
    content: `Votre ballon d’eau chaude électrique de marque FLECK est en panne, il ne produit plus d’eau chaude ou il fuit.
    En ce qui concerne les ballons d’eau chaude, la panne peut concerner la plomberie/chauffage si votre ballon fuit ou si la résistance ou le thermostat sont à remplacer par exemple ou encore si un détartrage est à effectuer.
    Dans tous les cas, un dépanneur spécialisé dans la marque FLECK va vous dépanner en urgence que vous ayez une petite capacité ou un grand ballon, horizontal ou vertical, la réparation sera effectuée.
    Il se peut aussi que votre ballon d’eau chaude disjoncte et ne chauffe plus. Dans ce cas, un électricien devra intervenir pour voir ce qui se passe.
    Pour savoir avec précision quelle est la nature de votre panne, notre dépanneur va se rendre chez vous et tester votre ballon d’eau chaude FLECK pour diagnostiquer sapanne et ainsi vous établir le devis correspondant.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "158",
    content: `Votre chauffe eau électrique autrement appelé un ballon d’eau chaude de marque THERMOR est en panne, ou il ne produit plus d’eau chaude ou encore il fuit.
    Les pannes de ballons d’eau chaude peuvent concerner le domaine de la plomberie et du chauffage si votre ballon fuit ou si la résistance ou le thermostat sont à remplacer par exemple ou encore si un détartrage est a effectuer. Il se peut aussi que votre ballon d’eau chaude disjoncte et ne chauffe plus. Dans ce cas, un électricien devra intervenir pour voir ce qui se passe.
    Dans tous les cas, un dépanneur spécialisé dans la marque THERMOR va vous dépanner en urgence que vous ayez une petite capacité ou un grand ballon d’eau chaude, qu’il soit horizontal ou vertical.
    Pour connaître la nature de votre panne, notre dépanneur va se rendre chez vous et tester votre ballon d’eau chaude Thermor afin d’en diagnostiquer la panne et ainsi vous établir procéder aux réparation.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "159",
    content: `Vous avez un ballon d’eau chaude électrique, autrement appelé un cumulus de marque CHAFFOTEAUX qui est en panne ou qui a une fuite d’eau.
    En ce qui concerne les ballons d’eau chaude, la panne peut concerner la plomberie/chauffage si votre ballon fuit ou si la résistance ou le thermostat sont à remplacer par exemple ou encore si un détartrage est a effectuer.
    Dans tous les cas, un dépanneur spécialisé dans la marque CHAFFOTEAUX va intervenir et vous dépanner quel que soit le modèle de chauffe eau électrique que vous avez et de n’importe quelle capacité dont les modèles des gammes HPC, STEATITE, Blindé…
    Il se peut aussi que votre ballon d’eau chaude disjoncte et ne chauffe plus. Dans ce cas, un électricien devra intervenir pour voir ce qui se passe.
    Pour savoir avec précision quelle est la nature de votre panne, notre dépanneur va se rendre chez vous et tester votre ballon d’eau chaude CHAFFOTEAUX pour diagnostiquer sa panne et ainsi vous établir le devis correspondant.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "160",
    content: `Vous avez un ballon d’eau chaude de marque DE DIETRICH qui est en panne ou qui a une fuite d’eau.
    En ce qui concerne les ballons d’eau chaude, la panne peut concerner la plomberie / chauffage si votre ballon fuit ou si la résistance ou le thermostat sont à remplacer ou encore si un détartrage est à effectuer. La panne peut aussi relever de l’électricité si votre ballon d’eau chaude disjoncte par exemple.
    Un dépanneur spécialisé dans la marque DE DIETRICH va intervenir en urgence chez vous et vous dépanner quel que soit le modèle de chauffe eau électrique que vous avez et de n’importe quelle capacité.
    Pour savoir avec précision quelle est la nature de votre panne, notre dépanneur va se rendre chez vous et tester votre ballon d’eau chaude afin de diagnostiquer le problème et vous proposer la réparation adéquate.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "161",
    content: `Votre ballon d'eau chaude électrique est en panne
    Le ballon d’eau chaude sert à chauffer l'eau sanitaire et satisfait ainsi vos besoins en eau chaude pour votre habitat.
    Le ballon d'eau chaude peut être électrique ou au gaz.
    Vous avez un ballon d’eau chaude électrique, autrement appelé un cumulus qui est en panne ou qui présente une fuite d’eau.
    Le problème du ballon électrique peut relever de la plomberie/chauffage, comme pour le ballon d'eau chaude au gaz, si votre ballon fuit, si la résistance ou le thermostat sont à remplacer ou encore si un détartrage est à effectuer.
    Votre ballon d’eau chaude électrique peut en plus disjoncter. Dans ce cas, un électricien devra intervenir pour voir ce qui se passe.
    Intervention d'un dépanneur sur le ballon d'eau chaude électrique
    Pour savoir avec précision quelle est la nature de votre panne, notre dépanneur va se rendre chez vous et tester votre ballon pour diagnostiquer sa panne et ainsi vous établir le devis correspondant.
    Dans les cas où la nature de la panne n'est pas évidente, le plombier / chauffagiste va d'abord être mandaté et s'il constate que le problème ne vient pas de la plomberie, il vous recommandera de faire appel à un électricien.
    Afin d'effectuer la réparation, le dépanneur procédera au réglage ou au changement des pièces détachées selon l'origine de la panne. Certaines pannes nécessitent notamment le vidange de votre ballon d'eau chaude.
    Information utile pour le cas "Réparation d'un ballon d'eau chaude électrique"
    Parfois le dépanneur a besoin de commander les pièces détachées, il vous faudra donc attendre l'arrivée des pièces.
    Le prix de MesDépanneurs.fr indiqué à droite comprend la réparation mais ne comprend pas le remplacement de votre ballon d'eau chaude si la réparation s'avère impossible.`
  },
  {
    id: "162",
    content: `Problème de chauffe-eau, chauffe-bain ELM Le Blanc
    Votre chauffe eau ou votre chauffe bains est en panne ou fuit. Il est au gaz et de marque ELM LEBLANC.
    Quel que soit le modèle que vous avez, un chauffagiste spécialisé dans la marque ELM LEBLANC, va intervenir chez vous afin de diagnostiquer la panne de votre appareil.
    Les modèles de chauffe eau et de chauffe bains de ELM LEBLANC sont dans la gamme Ondéa : hydrosmart (existe en version condensation) LM5, Hydropower…l’Ondéa existe pour les chauffe eau thermodynamique.
    Un chauffagiste pour réparer un chauffe-eau, chauffe-bain de Marque ELM Le Blanc
    Pour permettre à notre chauffagiste de vous proposer une réparation adéquate, il devra se rendre sur place afin de voir votre chauffe eau ou chauffe-bains et ainsi diagnostiquer sa pann.
    Parfois, un réglage suffit, parfois un changement de pièces, tout dépend de l’état de votre appareil et s’il est bien entretenu.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, confiez ce travail à un professionnel.
    Infos pour la "réparation de chauffe-eau, chauffe-bain de Marque ELM Le Blanc"
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour votre chaudière que vous soyez locataire ou propriétaire.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "163",
    content: `Problème de chauffe-eau, de chauffe-bains Saunier Duval
    Votre chauffe eau ou votre chauffe bains SAUNIER DUVAL est en panne ou fuit.
    Un chauffagiste spécialisé de MesDépanneurs.fr, spécialiste de la marque SAUNIER DUVAL, va intervenir chez vous afin de diagnostiquer la panne de votre appareil.
    Les modèles de chauffe eau et de chauffe bains SAUNIER DUVAL sur lesquels nous intervenons sont: Opalia en micro accumulé ou en instantané, HelioConcept et HelioSet pour un chauffe eau solaire, et Magna Aqua pour un chauffe eau thermodynamique.
    Intervention de chauffagiste pour réparer un chauffe-eau, chauffe-bains de marque Saunier Duval
    Afin que notre chauffagiste vous propose une réparation adéquate, il se rendra chez vous afin de tester votre chauffe eau ou chauffe-bains et ainsi diagnostiquer la panne.
    Si un réglage peut suffire, il se peut aussi parfois qu’un remplacement de pièces soit la seule solution pour remédier au problème, tout dépend de l’état de votre appareil et s’il est bien entretenu.
    Infos utiles pour la "réparation de chauffe-eau, de chauffe-bains de marque Saunier Duval"
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, confiez ce travail à un professionnel.
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien et ce que vous soyez locataire ou propriétaire.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "164",
    content: `Votre chauffe eau ou votre chauffe bains est de marque CHAFFOTEAUX, il est en panne ou fuit.
    Les chauffagistes de MesDépanneurs.fr interviennent sur tous les modèles de chauffe eau et de chauffe bains de marque CHAFFOTEAUX par exemple la gamme FLUENDO, AQUANEXT….
    Pour vous proposer une réparation adéquate, notre chauffagiste devra se rendre sur place afin de voir votre chauffe eau ou chauffe-bains et ainsi diagnostiquer sa panne et effectuer un réglage ou un remplacement de pièces si besoin est.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, confiez ce travail à un professionnel.
    N’oubliez pas qu’il est obligatoire de prendre un contrat d’entretien pour la chaudière que vous soyez locataire ou propriétaire.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "165",
    content: `Vous avez un chauffe eau à gaz ou un chauffe-bains qui est en panne, qui ne produit plus d’eau chaude ou qui fuit et sa marque est DE DIETRICH.
    Nous allons pouvoir pour dépanner en vous envoyant un chauffagiste spécialisé dans la marque DE DIETRICH qui une fois sur place pourra constater quelle est la nature de la panne que vous rencontrez. Quel que soit le modèle de chauffe eau ou de chauffe bains tel que: GS, CGB- II…
    Parfois, un réglage suffit et tout dépend de l’état de votre appareil et s’il est bien entretenu, parfois des pièces sont à remplacer..
    Nous vous rappelons que de prendre un contrat d’entretien pour faire entretenir et contrôler votre appareil est obligatoire, que vous soyez locataire ou propriétaire.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, confiez ce travail à un professionnel.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement d'appareils.`
  },
  {
    id: "166",
    content: `Vous avez un chauffe eau à gaz ou un chauffe-bains qui est en panne, qui ne produit plus d’eau chaude ou qui fuit et vous ne connaissez pas la marque de votre appareil.
    Nous allons pouvoir pour dépanner en vous envoyant un chauffagiste qui est spécialisé dans les pannes de chauffe eau et chauffe-bains. Ceci étant, une visite sur place afin de constater quel est l’appareil que vous avez est obligatoire afin de diagnostiquer la panne.
    Si un changement de pièces défectueuses est à prévoir, la marque de votre appareil aura son importance.
    Parfois, un réglage suffit et tout dépend de l’état de votre appareil et s’il est bien entretenu.
    Nous vous rappelons que de prendre un contrat d’entretien pour faire entretenir et contrôler votre appareil est obligatoire, que vous soyez locataire ou propriétaire.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, confiez ce travail à un professionnel.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "167",
    content: `Problème de chauffage appareil inconnu
    Vous avez un problème de chauffage. Vous pouvez aussi avoir une panne d’eau chaude sur votre chaudière gaz, fioul ou électrique, ou sur votre chauffe eau et ballon d’eau chaude.
    Nous intervenons sur tous les appareils de chauffage et sommes spécialisés dans la majorité des marques d’appareils de chauffe.
    Que vous ayez un réglage à effectuer, une réparation ou un remplacement de chaudière à faire, un chauffagiste de MesDépanneurs.fr va vous dépanner.
    Intervention de chauffagiste pour réparation de chauffage appareil inconnu
    Vous pouvez aussi avoir un problème de radiateurs, dans ce cas nous pourrons intervenir si vous avez une chaudière individuelle ou dans le cadre d’une chaudière collective, si des robinets à boisseau sont installés sur vos radiateurs. Ces robinets permettent une réparation sur votre radiateur sans couper tout le circuit de chauffage de l’immeuble. Si vous n’êtes pas équipés de ces robinets, appelez le syndic de votre immeuble pour demander l’intervention du chauffagiste en contrat avec l’immeuble qui pourra intervenir sur la chaudière collective.
    Infos utiles pour le cas "réparation d'un appareil de chauffage inconnu"
    Le seul moyen de savoir quelle est la réparation dont vous avez besoin est que notre chauffagiste constate sur place votre installation et puisse déterminer l’origine de votre problème.
    Nous vous conseillons de ne pas intervenir vous-mêmes sur les appareils de chauffe, confiez ce travail à un professionnel.
    Le prix indiqué ci-dessous comprend la réparation mais ne comprend pas le remplacement de vos appareils.`
  },
  {
    id: "168",
    content: `Entretien d'appareils de chauffage au gaz : une obligation légale
    L'entretien annuel de la chaudière / chauffe-eau au gaz est obligatoire selon la loi française, et un contrat d'entretien doit être passé avec une entreprise certifiée pour votre appareil. L'entretien de votre appareil se fait essentiellement pour éviter tout risque d'intoxication au monoxyde de carbone. C'est au locataire de prendre à sa charge l'entretien annuel de la chaudière / chauffe-eau. Le propriétaire peut lui demander de fournir l'attestation d'entretien.
    Le contrat d'entretien de chauffage
    Nous assurons les contrats d’entretien sur tous les appareils:
    chaudières murales et au sol
    chauffe eau et chauffe bains
    de toutes les marques, telles que: Elm Leblanc, Frisquet, De Dietrich, Saunier Duval, Chaffoteaux & Maury, ….
    Le contrat d’entretien que MesDépanneurs.fr vous propose:
    Comprend
    1 visite d’entretien par an
    Le nettoyage et le contrôle de votre appareil
    Les réglages et la vérification du débit
    1 assistance en cas de panne dans l’année sur votre appareil, nous intervenons dans la journée pour vous assister (attention, le contrat n'inclut pas l'entretien des radiateurs)
    La main d’œuvre
    Le déplacement
    Il ne comprend pas:
    Le ramonage
    Le remplacement de pièces détachées
    Toute intervention sur l’appareil autre que l’entretien
    Toute intervention sur les radiateurs si cela n'a pas été prise en option
    Infos concernant le cas : "Souscription d'un contrat d'entretien de chauffage"
    Votre contrat d'entretien peut être renouvelé, il vous suffit de prendre contact avec le service client de MesDépanneurs.fr afin que ce dernier procède au renouvellement.`
  },
  {
    id: "169",
    content: `Votre ballon d'eau chaude fuit
    Votre ballon d’eau chaude fuit.
    La panne peut concerner les raccords, le groupe de sécurité ou la cuve qui peut être percée par exemple. Dans le premier cas, il suffit parfois juste de resserrer les raccords. Dans le cas du groupe de sécurité, il vous faudra le réparer et dans certaines situations le changer, par contre, si la cuve est percée, le ballon d'eau chaude sera à remplacer intégralement.
    Le plombier déterminera la panne précise
    Pour savoir avec précision quelle est la nature de votre panne, notre plombier va se rendre chez vous et tester votre ballon d’eau chaude afin de diagnostiquer sa panne et ainsi vous établir le devis correspondant pour la réparation.
    Information utile pour le cas "Réparation d'une fuite du ballon d'eau chaude"
    Le prix de MesDépanneurs.fr indiqué à droite comprend la réparation mais ne comprend pas le remplacement de votre appareil.`
  },
  {
    id: "170",
    content: `Remplacement d'une chaudière murale à gaz
    Vous avez acheté votre propre chaudière murale au gaz et vous souhaitez la faire poser? Sachez qu’une chaudière au gaz doit obligatoirement être installée par un professionnel agréé. Il est vrai que la majorité des chauffagistes préfère effectuer la pose de matériel qu’ils fournissent car concernant les appareils de gaz, il faut s’assurer de la qualité du matériel que vous achetez. Les chauffagistes garantissent, dans ce cas là, la pose ainsi que le matériel.
    Faites appel au chauffagiste professionnel
    Néanmoins, il existe des chauffagistes qui installent les chaudières murale au gaz sans fournir le matériel. Ce type d’installation durera entre 4h et 5h.
    Vous pouvez également choisir ce même chauffagiste pour signer avec lui le contrat d’entretien de chaudière murale au gaz. Attention, l’entretien assuré par un artisan chauffagiste est obligatoire selon la loi et reste à la charge du locataire.
    Information utile pour le cas “Pose d'une chaudière murale à gaz”
    Attention, comme le matériel a été acheté par vous-même, il n’est pas garanti par le chauffagiste, si votre chaudière murale tombe en panne, adressez-vous au fournisseur.`
  },
  {
    id: "171",
    content: `Remplacement d'une chaudière murale au gaz
    La chaudière est un appareil qui chauffe l’eau du circuit de chauffage. On utilise traditionnellement au choix une chaudière à gaz, électrique ou au fioul.
    La chaudière à gaz est l’un des systèmes de chauffe les plus performants et les plus utilisés aujourd’hui notamment à cause de tarif de gaz qui reste encore modéré par rapport aux autres sources d'énergie. Pour pouvoir bénéficier d'une chaudière au gaz, il est nécessaire que votre habitat soit raccordé au gaz naturel (gaz de ville).
    Une chaudière à gaz coûte assez cher à l’achat, cependant elle reste un excellent investissement car elle présente une bonne rentabilité à long terme avec une durée de vie avoisinant les 30 ans pour un modèle de qualité.
    Le chauffagiste vous conseillera le modèle le plus adapté à votre logement
    La chaudière au gaz peut être murale ou au sol. La chaudière au gaz murale est la plus répandue dans les logements : compacte et légère, elle s’installe dans n’importe quelle pièce de votre intérieur, que ce soit la cuisine, la salle de bain, etc.
    Le chauffagiste de MesDépanneurs.fr vous conseillera le modèle de chaudière murale au gaz que vous pourrez installer dans votre domicile. Il va ensuite l’installer. L’installation prendra entre 4h et 5 h.
    Vous pourrez également choisir ce même chauffagiste pour signer avec lui le contrat d’entretien de votre chaudière murale au gaz, l’entretien assuré par un artisan chauffagiste étant obligatoire selon la loi.
    Infos utiles pour le cas "Fourniture et pose d'une chaudière murale au gaz”
    Attention : le prix peut varier selon le matériel que vous choisissez, certains modèles de chaudières étant extrêmement coûteux, le prix d’une chaudière seule sans installation peut attendre plus de 3000 euros . Le prix indiqué concerne le modèle standard.`
  },
  {
    id: "172",
    content: `Remplacement d'une chaudière murale électrique
    Vous avez acheté votre propre chaudière murale électrique ? Vous avez fait le bon choix! La chaudière électrique ne possède pas de cuve de stockage donc aucune odeur ni de rejet de CO2 et son entretien est très facile par rapport à une chaudière au gaz. Vous cherchez maintenant un chauffagiste pour la poser ? Faites appel à MesDépanneurs.fr pour une pose sécurisée et rapide.
    Confier la mission à un professionnel du métier
    Le chauffagiste de notre réseau prendra contact avec vous et se déplacera à votre domicile à la date de votre convenance afin d’effectuer cette installation. Prévoyez quelques heures pour la pose de votre chaudière murale à l’électricité. Notre prestataire vous conseillera ensuite pour une utilisation optimale.
    Information utile pour le cas “Pose d'une chaudière murale à l'électricité”
    Attention, comme la chaudière a été achetée par vous-même, elle n’est pas garantie par le technicien, en cas de panne, adressez-vous au fournisseur.`
  },
  {
    id: "173",
    content: `Chaudière murale électrique à remplacer
    La chaudière est un élément central des systèmes de chauffage à combustion et grâce à une performance difficilement égalée, la chaudière connaît encore de belles heures d’activité. Il en existe différents types : la chaudière au gaz, au fioul, au bois ou encore à l'électricité.
    Les chaudières électriques se développent de plus en plus dans les foyers en France et représente une bonne alternative aux chaudières au gaz si vous n’avez pas accès au gaz de ville.
    Un des principaux avantages d’une chaudière électrique est son entretien qui est quasi nul : pas besoin de ramonage, pas de contrôles annuels obligatoires, pas de nécessité d’un second abonnement pour un compteur électrique.
    Choisir et installer votre chaudière murale à l'électricité
    La chaudière électrique classique est murale.
    Attachée au mur, elle est compacte et peut être cachée dans le coffrage afin de ne pas gêner l'esthétique de la pièce.
    Le chauffagiste faisant partie du réseau MesDépanneurs.fr va vous guider dans le choix de votre modèle de chaudière murale électrique et vous la poser.
    Comptez entre 3h et 5h pour l’installation.
    Infos utiles pour la “Fourniture et pose d'une chaudière murale à l'électricité”
    Attention : le prix indiqué concerne le modèle standard, les chaudières étant de marques et de modèles différents, les devis sont souvent établis sur mesure.`
  },
  {
    id: "174",
    content: `Pose d'une chaudière à gaz
    Vous avez acheté votre propre chaudière au sol au gaz et vous souhaitez l’installer ? Il faut être conscient qu’une chaudière au gaz doit obligatoirement être installée par un professionnel agréé. En règle générale, les artisans chauffagistes n’installent pas le matériel qu’ils ne fournissent pas, préférant garantir le matériel aussi bien que la pose.
    Obligation d'être installée par un chauffagiste professionnel
    Néanmoins, il existe des chauffagistes qui peuvent vous venir en aide et effectuer la pose de votre chaudière au sol au gaz sans fournir le matériel. Ce type d’installation durera au minimum 5h.
    Nous vous recommandons de choisir ce même chauffagiste pour le contrat d’entretien de votre chaudière au sol au gaz qui, nous vous rappelons, est obligatoire selon la législation française et reste à la charge du locataire.
    Information utile pour le cas “Pose d'une chaudière au sol à gaz”
    Attention, comme le matériel a été acheté par vous-même, il n’est pas garanti par le chauffagiste, en cas de panne, adressez-vous au fournisseur.`
  },
  {
    id: "175",
    content: `Vous avez besoin de remplacer une chaudière au sol
    La chaudière sert à chauffer les appareils de chauffe tels que les radiateurs mais aussi l’eau sanitaire. La chaudière à gaz reste l’un des systèmes de chauffe le plus utilisé aujourd’hui en France car le prix du gaz est moins élevé par rapport aux autres sources d'énergie. Afin de pouvoir en installer une, votre habitat doit être raccordé au gaz naturel.
    Ce type de chaudière représente un excellent investissement. Bien que coûteuse à l’achat, la chaudière au gaz, si son entretien est assuré régulièrement avec un professionnel, peut vous servir plusieurs dizaines d’années.
    La chaudière au gaz peut être murale (attachée à votre mur) ou au sol (posée). La chaudière au gaz au sol est beaucoup plus rare que celle murale. A cause de sa taille importante, elle est principalement installée dans le garage ou dans un cellier et sert à chauffer une maison individuelle.
    Le chauffagiste saura poser votre nouvelle chaudière
    Le chauffagiste, membre de la plate-forme MesDépanneurs.fr, vous conseillera le modèle de chaudière au sol au gaz que vous pourrez installer chez vous, en effectuera la commande et sa pose.
    La pose de chaudière au sol durera au minimum 5h.
    Nous vous recommandons de sélectionner ce même chauffagiste afin de conclure avec lui le contrat d’entretien de chaudière au sol au gaz, l’entretien assuré par un artisan chauffagiste étant obligatoire selon la loi et vous évitera les mauvaises surprises.
    Information utile pour le cas “Fourniture et pose d'une chaudière au sol à gaz”
    Attention : le prix indiqué concerne le modèle standard, les chaudières au sol étant très coûteuses et difficiles à installer, les devis sont souvent établis sur mesure.`
  },
  {
    id: "176",
    content: `Votre chaudière électrique au sol est à installer
    Vous avez votre propre chaudière électrique au sol et vous souhaitez faire appel au chauffagiste compétent pour poser une chaudière ? Vous êtes au bon endroit.
    Optez pour une pose par un professionnel
    L’un des chauffagistes de notre réseau se mettra en relation avec vous, se déplacera à votre domicile à votre convenance afin d’effectuer cette installation. L’installation de chaudière étant une opération délicate, nous vous recommandons fortement de vous adressez à un vrai artisan pour la réaliser.
    Prévoyez quelques heures pour la pose de votre chaudière au sol à l’électricité. Notre prestataire vous conseillera ensuite pour une utilisation optimale.
    Information utile pour le cas “Pose d'une chaudière au sol à l'électricité”
    Attention, le matériel fourni par vous n’est pas garanti par le chauffagiste, en cas de panne de chaudière, sa réparation sera à votre charge.`
  },
  {
    id: "177",
    content: `La chaudière électrique au sol
    La chaudière chauffe l’eau dans vos circuits et vous assure la présence d’eau chaude dans vos robinets et vos radiateurs.
    Vous pouvez opter pour une chaudière classique au gaz ou choisir une chaudière électrique qui reste une bonne alternative si votre maison ne bénéficie pas de gaz de ville ou si vous ne souhaitez pas souscrire à un nouvel abonnement énergétique.
    La chaudière électrique ne demande quasiment pas d’entretien mais reste gourmande en terme de consommation d’électricité.
    Installation d'une chaudière électrique par un professionnel
    La chaudière électrique peut être murale ou au sol. Les chaudières électrique au sol sont plus grandes et permettent de chauffer les espaces plus importants. Elles ne s’installent pas n’importe où, il faut prévoir une place spécifique dans la maison.
    Le chauffagiste faisant partie du réseau MesDépanneurs.fr vous proposera le modèle de la chaudière au sol le plus adapté à vos consommations et s’occupera de son achat et de sa pose. Comptez au minimum 5 h pour l’installation.
    Info utile: “Fourniture et pose d'une chaudière au sol à l'électricité”
    Attention : le prix à droite concerne le modèle standard, les chaudières étant de marques et de modèles différents, le chauffagiste vous fera un devis personnalisé en effectuant une pré-visite gratuite à votre domicile.`
  },
  {
    id: "178",
    content: `La chauffe-eau / chauffe-bain à gaz est à remplacer
    Le chauffe-eau et le chauffe-bain servent à chauffer l’eau sanitaire, ils fournissent donc de l’eau chaude pour vos lavabos, éviers et toilettes. Contrairement au ballon d’eau chaude, ils ne stockent pas l’eau chauffée mais ils produisent de l’eau chaude à la demande. La différence principale entre le chauffe-eau et le chauffe-bain est leur débit, le chauffe-eau étant moins puissant que le chauffe-bain, il ne chauffe l’eau que pour un seul appareil, un évier par exemple.
    Il existe différents types de chauffe-eau / chauffe-bain sur le marché. Choisir son appareil ne se prend pas à la légère, et le modèle choisi dépend de votre consommation régulière en eau, et des caractéristiques de votre habitat.
    Le chauffe-eau / chauffe-bain à gaz fonctionne soit au gaz de ville (gaz naturel) ou alors avec du gaz de type propane ou butane, généralement stocké dans une cuve. Comme pour une chaudière au gaz, le chauffe-eau au gaz fonctionne de la même manière car c’est un brûleur qui aide à chauffer l’eau.
    Confier cette mission au chauffagiste de MesDépanneurs.fr
    L’artisan de MesDépanneurs.fr vous aidera à choisir votre chauffe-eau ou chauffe-bain au gaz, il procédera à son achat et son installation. Il existe plusieurs possibilités d’installation : soit sans aucun raccordement à un conduit, soit avec un raccordement à un conduit d’évacuation comme une VMC ou alors un raccordement à un conduit tel une ventouse.
    Comptez entre 3h et 4h pour la pose.
    Notre chauffagiste saura également vous conseiller sur un entretien de votre appareil.
    L’entretien du chauffe-eau à gaz doit être effectué chaque année par un artisan chauffagiste qui vérifiera le bon fonctionnement de l’appareil, et évitera ainsi les éventuelles fuites de monoxyde de carbone.
    À noter que ces entretiens font souvent partie d’un contrat de maintenance. Vous pourrez signer ce contrat avec notre chauffagiste au moment de l’installation.
    Info pour le cas “Fourniture et pose d'un chauffe-eau / chauffe-bain à gaz”
    Attention : le prix indiqué concerne le modèle standard, les chauffe-eau / chauffe-bain étant de marques différents et au prix différent, les devis sont souvent établis sur mesure.`
  },
  {
    id: "179",
    content: `Votre chauffe-eau / chauffe-bain est électrique
    Le chauffe-eau et le chauffe-bain chauffent l’eau sanitaire de vos appareils tels que les toilettes, les douches, les lavabos de manière instantanée sans stocker l’eau chaude dans la cuve. Ils se différencient principalement par leur capacité de chauffe, celle de chauffe-eau étant moins importante.
    Vous pouvez choisir entre l’appareil au gaz ou électrique. Le chauffe-eau électrique aura l’avantage d’avoir une taille moins grande que les autres appareils de chauffe mais il est assez peu répandu en France à cause de la consommation élevée en électricité, ce qui rend son utilisation onéreuse.
    Les chauffe-eau électriques présentent différents modèles, vous pourrez choisir entre, par exemple, le chauffe-eau instantané ou celui à accumulation.
    Confier la mission de l'installation à un chauffagiste compétent
    Si vous souhaitez installer un chauffe-eau électrique, l’artisan de MesDépanneurs.fr vous aidera à le choisir et à le poser. Comptez entre 2h et 3h pour la pose.
    Info utile: cas “Fourniture et pose d'un chauffe-eau / chauffe-bain à l'électricité”
    Attention : le prix indiqué concerne le modèle standard, les chauffe-eau / chauffe-bain étant de marques différents et à des prix différents, les devis sont souvent établis sur mesure.`
  },
  {
    id: "180",
    content: `Vous avez acheté le nouveau ballon d'eau chaude
    Le ballon d’eau chaude permet de satisfaire vos besoins en eau chaude. Il en existe de différentes contenances, allant de 10 jusqu’à 500 litres, à choisir selon votre consommation d’eau et la surface disponible pour l’installer. Les ballons peuvent être à gaz ou électriques.
    Vous voulez changer votre ballon d’eau chaude et vous avez déjà acheté votre matériel? Notre prestataire se déplacera chez vous afin de poser le nouveau ballon d'eau chaude.
    Notre plombier effectuera sa pose
    Il procédera d’abord à la vidange et la dépose de votre ancien appareil, puis il va poser le nouveau ballon. Comptez entre 1h et 3h pour la pose, sans oublier le temps nécessaire à la vidange et à la dépose de votre ancien matériel.
    Information utile pour le cas “Pose d'un ballon d'eau chaude”
    Attention: Le prix indiqué concerne une installation de ballon d’eau chaude à l’identique. Si vous possédiez un autre système de ballon d’eau chaude auparavant, notre prestataire établira un devis personnalisé pour la pose.`
  },
  {
    id: "181",
    content: `Remplacement ballon d'eau chaude
    Le ballon d’eau chaude sert à chauffer l’eau sanitaire de votre habitat. Il arrive qu’un mécanisme de votre appareil soit abîmé, et l’empêche de fonctionner, ou que du calcaire se dépose sur les conduits et gêne à terme le passage de l’eau. Votre ballon d'eau chaude peut aussi tout simplement être vétuste, et ne plus fonctionner correctement.
    Quel que soit le problème qui vous empêche d'avoir de l'eau chaude, il faut faire appel à un plombier professionnel pour identifier le type de panne et vérifier si votre ballon peut encore être réparé ou s’il est nécessaire d'opter pour le remplacement de ballon d’eau chaude.
    L’artisan membre de MesDépanneurs.fr se déplace gratuitement chez vous afin de faire le diagnostic. Dans certains cas, notamment, celui de la vétusté, il vous conseillera de remplacer votre ballon d’eau chaude. Attention, si votre ballon a plus de 10 ans ou s’il s’agit d’une sous-marque achetée dans les grandes surfaces, la solution de remplacement du ballon d’eau chaude reste le seul moyen possible pour vous dépanner. .
    Choix du matériel dans le cas de changement de ballon d’eau chaude ?
    Une fois le diagnostiic confirmé, l’artisan de MesDépanneurs.fr vous proposera un devis gratuit. Si vous devez changer votre ballon d’eau chaude, il inclura le matériel dans son devis. Il existe les différentes tailles pour votre ballon d’eau chaude, variant de 10 à 500 litres, les tailles les plus répandues étant de 100 à 250 litres. Afin d’effectuer le changement de ballon d’eau chaude, il faut également savoir si votre ballon est électrique ou s’il est à gaz. Pour les appareils à gaz, l’eau est chauffée dans le réservoir, et la température est toujours constante par le biais d’un thermostat. Pour les appareils électriques, une résistance électrique et un thermostat permettent de maîtriser la température de l’eau. L’eau chaude est stockée en haut du réservoir tandis que l’eau froide reste dans la partie inférieure. Dans la grande majorité de cas de changement de ballon d’eau chaude, l’artisan vous installera le même modèle que celui déjà installé auparavant à votre domicile. .
    Intervention pour installation de ballon d'eau chaude
    Une fois le matériel choisi et le devis signé par vos soins, le plombier vous assurera l’installation de ballon d’eau chaude. Avant la pose de votre nouveau ballon, le plombier réalisera la vidange et le démontage du ballon d'eau chaude à changer. La durée d'une intervention pour l’installation de ballon d'eau chaude varie entre 2 et 4 heures, cette durée n'inclut pas le temps du démontage et du diagnostic avant travaux. Cet artisan plombier devra bien évidemment pouvoir vous conseiller sur l'utilisation de votre nouveau ballon d'eau chaude et sur son entretien.
    Prix de la fourniture et pose de ballon d'eau chaude
    Le prix indiqué concerne un changement de ballon d’eau chaude à l’identique pour les ballons d'eau chaudes verticaux. Si votre modèle de ballon d'eau chaude est un modèle horizontal, le tarif peut être plus cher du fait de certaines spécificités d'installation. Quoi qu'il en soit, si vous faites appel à Mesdépanneurs.fr, un artisan plombier vous réalisera le devis personnalisé gratuit pour le changement de ballon d’eau chaude. Attention, si votre ancien ballon est en coffrage, le plombier sera probablement amené à casser le coffre afin d’accéder au ballon.`
  },
  {
    id: "182",
    content: `Vous avez un problème de chauffage mais vous ne savez pas lequel.
    Nous allons vous assister que votre problème concerne le chauffage ou l’eau chaude, et que vous ayez une chaudière à gaz, au fioul ou électrique, un radiateur….les chauffagistes de MesDépanneurs.fr sont spécialisés dans le chauffage et pourrons vous dépanner.
    Nous intervenons sur tous les appareils de chauffage et sommes spécialisés dans la majorité des marques d’appareils de chauffe.
    Le seul moyen de savoir quelle est la réparation dont vous avez besoin est que notre chauffagiste constate sur place votre installation et puisse déterminer l’origine de votre problème.`
  },
  {
    id: "183",
    content: `L’engorgement de la colonne générale de votre immeuble nécessite l’intervention d’un camion hydrocureur à forte pression permettant le dégorgement complet de la colonne. Vous pouvez avoir un appareil sanitaire bouché chez vous tel que votre WC, ou votre évier, votre lavabo, votre baignoire… et malgré le passage d’un furet, le dépot d’un acide surpuissant permettant le débouchage ou encore un système de ventouse, rien n’y fait cela reste bouché. Dans ce cas il est fort probable que la cause soit l’engorgement de colonne générale (en dehors d’un système inexistant de contre pente sur vos tuyauteries).
    Notre dépanneur pourra vous confirmer ce diagnostic une fois sur place en ayant vu vos tuyauteries et appareils sanitaires. Il vous établiera le devis correspondant au dégorgement à effectuer.`
  },
  {
    id: "184",
    content: `Vous avez un problème de plomberie et vous ne savez pas lequel.
    Dans ce cas précis, nous n’avons pas assez d’information de votre part afin de pouvoir définir votrepanne et vous guider.
    Nous avons bien compris que votre problème concerne de la plomberie sanitaire mais veuillez nous donner davantage de précisions, notamment si le dépannage que vous souhaitez concerne un fonctionnement de votre plomberie , s’il s’agit d’un engorgement, d’une fuite ou d’un autre souci.
    Nous vous invitons à remplir le champ ci-dessous et un opérateur de MesDépanneurs.fr va vous contacter très rapidement.`
  },
  {
    id: "185",
    content: `Vous avez un problème avec votre WC et le modèle que vous avez n’est pas standard, de plus vous ne savez pas exactement d’où vient la panne.
    Nous n’avons pas assez d’information de votre part afin de pouvoir définir votre panne et vous guider.
    Nous avons bien compris que votre problème concerne votre WC non standard mais nous avons besoin que vous nous donniez davantage de précisions, notamment si le dépannage que vous souhaitez concerne le fonctionnement de vos toilettes, ou s’il s’agit d’un engorgement de WC, d’une fuite ou d’un autre souci.
    Nous vous invitons à remplir le champ ci-dessous afin qu’un opérateur de MesDépanneurs.fr vous contacte très rapidement.`
  }
];

const initialState = {
  isFetching: false,
  items,
  contents
};

export default function steps(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
