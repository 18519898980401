import firebase from "firebase";
import Firebase from "./firebase";
import CONSTANTS from "./constants";
export default class ORM {
  constructor(collection) {
    this.collection = collection;
    this.db = new Firebase(collection);
  }

  post(item) {
    return this.db.post(item);
  }

  put(item) {
    return this.db.put(item);
  }

  delete(item) {
    return this.db.delete(item);
  }

  onSnapshot(callback, errorCallback) {
    return this._onSnapshot(this.collection, callback, errorCallback);
  }

  _onSnapshot(collection, callback, errorCallback) {
    firebase
      .firestore()
      .collection(collection)
      .onSnapshot(
        function(snapshot) {
          const newItems = {};
          const deletedItems = {};
          snapshot.forEach(function(document) {
            const docId = document.ref.id;
            const doc = document.data();
            if (doc.status === CONSTANTS.status.DELETED) {
              deletedItems[docId] = doc;
            } else {
              newItems[docId] = doc;
            }
          });

          callback(newItems, deletedItems);
        },
        function(error) {
          console.log(error);
          if (errorCallback) {
            errorCallback(error);
          }
        }
      );
  }
}
