import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentCompanion, deleteCompanion } from "../../redux/actions";
import ModelInfos from "../../components/UI/Model/Infos";

class Screen extends React.Component {
  componentDidMount() {
    const { companionId } = this.props.match.params;
    this.props.setCurrentCompanion(companionId);
  }

  handleDeleteCompanion = companionId => {
    this.props.deleteCompanion(companionId);
  };

  render() {
    return (
      <section>
        <h1>Infos</h1>

        <ModelInfos model={this.props.companion} />
        <Link to={`/companions/${this.props.match.params.companionId}/edit`}>
          Editer
        </Link>
        <button
          onClick={() => {
            this.handleDeleteCompanion(this.props.match.params.companionId);
          }}
        >
          Delete
        </button>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return { companion: state.companions.item };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteCompanion: companionId => dispatch(deleteCompanion(companionId)),
    setCurrentCompanion: companionId =>
      dispatch(setCurrentCompanion(companionId))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
