import React from "react";
import { connect } from "react-redux";
import { logout } from "../redux/actions";
import { Redirect } from "react-router-dom";

class LogoutScreen extends React.Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return (
      <section>
        <h2>Se déconnecter</h2>
        {this.props.auth.user ? "" : <Redirect to="/login" />}
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutScreen);
