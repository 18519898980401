export const TEST_ACTION = "TEST_ACTION";

export const CHECK_AUTH_SESSION = "CHECK_AUTH_SESSION";
export const ON_AUTH_STATE_CHANGED_SIGNED_IN =
  "ON_AUTH_STATE_CHANGED_SIGNED_IN";
export const ON_AUTH_STATE_CHANGED_SIGNED_OUT =
  "ON_AUTH_STATE_CHANGED_SIGNED_OUT";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

/*
 * DIAGNOSTIC
 */

export const INIT_DIAGNOSTIC = "INIT_DIAGNOSTIC";

export const FETCH_DIAGNOSTICS_REQUEST = "FETCH_DIAGNOSTICS_REQUEST";
export const FETCH_DIAGNOSTICS_SUCCESS = "FETCH_DIAGNOSTICS_SUCCESS";
export const FETCH_DIAGNOSTICS_FAILURE = "FETCH_DIAGNOSTICS_FAILURE";

export const POST_DIAGNOSTIC_REQUEST = "POST_DIAGNOSTIC_REQUEST";
export const POST_DIAGNOSTIC_SUCCESS = "POST_DIAGNOSTIC_SUCCESS";
export const POST_DIAGNOSTIC_FAILURE = "POST_DIAGNOSTIC_FAILURE";

export const SUBMIT_DIAGNOSTIC_STEP = "SUBMIT_DIAGNOSTIC_STEP";
export const SUBMIT_DIAGNOSTIC = "SUBMIT_DIAGNOSTIC";

/*
 * CLIENT
 */

export const INIT_CLIENT = "INIT_CLIENT";

export const FETCH_CLIENTS_REQUEST = "FETCH_CLIENTS_REQUEST";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_FAILURE = "FETCH_CLIENTS_FAILURE";

export const POST_CLIENT_REQUEST = "POST_CLIENT_REQUEST";
export const POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS";
export const POST_CLIENT_FAILURE = "POST_CLIENT_FAILURE";

export const PUT_CLIENT_REQUEST = "PUT_CLIENT_REQUEST";
export const PUT_CLIENT_SUCCESS = "PUT_CLIENT_SUCCESS";
export const PUT_CLIENT_FAILURE = "PUT_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";

/*
 * COMPANION
 */

export const INIT_COMPANION = "INIT_COMPANION";

export const FETCH_COMPANIONS_REQUEST = "FETCH_COMPANIONS_REQUEST";
export const FETCH_COMPANIONS_SUCCESS = "FETCH_COMPANIONS_SUCCESS";
export const FETCH_COMPANIONS_FAILURE = "FETCH_COMPANIONS_FAILURE";

export const POST_COMPANION_REQUEST = "POST_COMPANION_REQUEST";
export const POST_COMPANION_SUCCESS = "POST_COMPANION_SUCCESS";
export const POST_COMPANION_FAILURE = "POST_COMPANION_FAILURE";

export const PUT_COMPANION_REQUEST = "PUT_COMPANION_REQUEST";
export const PUT_COMPANION_SUCCESS = "PUT_COMPANION_SUCCESS";
export const PUT_COMPANION_FAILURE = "PUT_COMPANION_FAILURE";

export const DELETE_COMPANION_REQUEST = "DELETE_COMPANION_REQUEST";
export const DELETE_COMPANION_SUCCESS = "DELETE_COMPANION_SUCCESS";
export const DELETE_COMPANION_FAILURE = "DELETE_COMPANION_FAILURE";

export const SET_CURRENT_COMPANION = "SET_CURRENT_COMPANION";

/*
 * SMS
 */

export const FETCH_SMSES_REQUEST = "FETCH_SMSES_REQUEST";
export const FETCH_SMSES_SUCCESS = "FETCH_SMSES_SUCCESS";
export const FETCH_SMSES_FAILURE = "FETCH_SMSES_FAILURE";

export const SEND_SMS_REQUEST = "SEND_SMS_REQUEST";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_FAILURE = "SEND_SMS_FAILURE";

export const POST_SMS_REQUEST = "POST_SMS_REQUEST";
export const POST_SMS_SUCCESS = "POST_SMS_SUCCESS";
export const POST_SMS_FAILURE = "POST_SMS_FAILURE";

export const SEND_MISSION_SMS = "SEND_MISSION_SMS";

/*
 * SMS REPLIES
 */

export const FETCH_SMS_REPLIES_REQUEST = "FETCH_SMS_REPLIES_REQUEST";
export const FETCH_SMS_REPLIES_SUCCESS = "FETCH_SMS_REPLIES_SUCCESS";
export const FETCH_SMS_REPLIES_FAILURE = "FETCH_SMS_REPLIES_FAILURE";

/*
 * MISSIONS
 */

export const INIT_MISSION = "INIT_MISSION";

export const FETCH_MISSIONS_REQUEST = "FETCH_MISSIONS_REQUEST";
export const FETCH_MISSIONS_SUCCESS = "FETCH_MISSIONS_SUCCESS";
export const FETCH_MISSIONS_FAILURE = "FETCH_MISSIONS_FAILURE";

export const POST_MISSION_REQUEST = "POST_MISSION_REQUEST";
export const POST_MISSION_SUCCESS = "POST_MISSION_SUCCESS";
export const POST_MISSION_FAILURE = "POST_MISSION_FAILURE";

export const PUT_MISSION_REQUEST = "PUT_MISSION_REQUEST";
export const PUT_MISSION_SUCCESS = "PUT_MISSION_SUCCESS";
export const PUT_MISSION_FAILURE = "PUT_MISSION_FAILURE";

export const DELETE_MISSION_REQUEST = "DELETE_MISSION_REQUEST";
export const DELETE_MISSION_SUCCESS = "DELETE_MISSION_SUCCESS";
export const DELETE_MISSION_FAILURE = "DELETE_MISSION_FAILURE";

export const SET_CURRENT_MISSION = "SET_CURRENT_MISSION";
export const SET_GO_TO = "SET_GO_TO";
export const RESET_GO_TO = "RESET_GO_TO";
