import * as ActionTypes from "../ActionTypes";
import ORM from "../../ORM";
import * as globalsActions from "./globals";

export default class Action {
  constructor(singularName, pluralName, DB_COLLECTION) {
    this.singularName = singularName;
    this.pluralName = pluralName;
    this.DB_COLLECTION = DB_COLLECTION;
    this.orm = new ORM(DB_COLLECTION);
  }

  fetch = (force = false) => {
    return (dispatch, getState) => {
      dispatch({
        type: ActionTypes["FETCH_" + this.pluralName + "_REQUEST"],
        force
      });
      this.orm.onSnapshot(
        (items, deletedItems) => {
          dispatch(this.fetchSuccess(items, deletedItems));
        },
        err => {
          dispatch(this.fetchFailure(err));
        }
      );
    };
  };

  fetchSuccess = (items, deletedItems) => {
    return {
      type: ActionTypes["FETCH_" + this.pluralName + "_SUCCESS"],
      items,
      deletedItems
    };
  };
  fetchFailure = error => {
    return {
      type: ActionTypes["FETCH_" + this.pluralName + "_FAILURE"],
      error
    };
  };

  /**
   * ==========
   * POST
   * ==========
   */
  post = (item, urlAfterSuccess) => {
    return dispatch => {
      dispatch({
        type: ActionTypes["POST_" + this.singularName + "_REQUEST"],
        item
      });
      return this.orm
        .post(item)
        .then(id => {
          item.id = id;
          dispatch(this.postSuccess(item));
          dispatch(globalsActions.setGoTo(urlAfterSuccess));
        })
        .catch(err => {
          dispatch(this.postFailure(err));
          throw Error("Fail to post item.", err);
        });
    };
  };

  postPromise = (item, urlAfterSuccess, dispatch) => {
    dispatch({
      type: ActionTypes["POST_" + this.singularName + "_REQUEST"],
      item
    });
    return this.orm
      .post(item)
      .then(id => {
        item.id = id;
        dispatch(this.postSuccess(item));
        dispatch(globalsActions.setGoTo(urlAfterSuccess));
        return item;
      })
      .catch(err => {
        dispatch(this.postFailure(err));
        throw Error("Fail to post item.", err);
      });
  };

  postSuccess = item => {
    return {
      type: ActionTypes["POST_" + this.singularName + "_SUCCESS"],
      item
    };
  };
  postFailure = error => {
    return {
      type: ActionTypes["POST_" + this.singularName + "_FAILURE"],
      error
    };
  };

  /**
   * ==========
   * PUT
   * ==========
   */
  put = (item, urlAfterSuccess) => {
    return dispatch => {
      dispatch({
        type: ActionTypes["PUT_" + this.singularName + "_REQUEST"],
        item: item
      });
      return this.orm
        .put(item)
        .then(id => {
          dispatch(this.putSuccess(item));
          dispatch(globalsActions.setGoTo(urlAfterSuccess));
        })
        .catch(err => {
          dispatch(this.putFailure(err));
        });
    };
  };

  putSuccess = item => {
    return {
      type: ActionTypes["PUT_" + this.singularName + "_SUCCESS"],
      item
    };
  };
  putFailure = error => {
    return {
      type: ActionTypes["PUT_" + this.singularName + "_FAILURE"],
      error
    };
  };

  /**
   * ==========
   * DELETE
   * ==========
   */
  delete = itemId => {
    return dispatch => {
      dispatch({
        type: ActionTypes["DELETE_" + this.singularName + "_REQUEST"],
        item: itemId
      });
      return this.orm
        .delete(itemId)
        .then(() => {
          dispatch(this.deleteSuccess(itemId));
        })
        .catch(err => {
          dispatch(this.deleteFailure(err));
        });
    };
  };

  deleteSuccess = item => {
    return {
      type: ActionTypes["DELETE_" + this.singularName + "_SUCCESS"],
      item
    };
  };
  deleteFailure = error => {
    return {
      type: ActionTypes["DELETE_" + this.singularName + "_FAILURE"],
      error
    };
  };
}
