import React from "react";
import { Field } from "formik";
import utils from "../../../utils";
import constants from "../../../constants";

export default class UserSelectField extends React.Component {
  /**
   *
   * @param {*} field
   * @param {*} field.name
   * @param {*} field.value
   * @param {*} field.index
   */
  onUserChange = field => {
    const user = this.props.users[field.index];
    this.props.onUserChange({
      field,
      user
    });
  };

  onFieldChange = (event, form) => {
    // Get data from event
    const target = event.currentTarget;
    const name = target.name;
    const index = target.selectedIndex;
    const value = target.options[target.selectedIndex].value;
    // Update Formik
    form.setFieldValue(this.props.name, value);

    // Build field variable
    /**
     * Due to options before clients options
     * 'empty' and 'new', we need to remove the delta.
     */
    const users = this.props.users || [];
    const deltaIndex = target.options.length - users.length;
    const finalIndex = index - deltaIndex;
    const field = { name, value, index: finalIndex };
    this.onUserChange(field);
  };

  render() {
    const users = this.props.users || [];
    return (
      <Field
        name={this.props.name}
        render={({ field, form }) => {
          return (
            <>
              <select
                {...field}
                onChange={event => {
                  this.onFieldChange(event, form);
                }}
              >
                <option value="" />
                <option value={constants.NEW_USER_SELECTED}>Nouveau </option>
                {users.map(user => {
                  return (
                    <option value={user.id || ""} key={user.id}>
                      {utils.getUserFullNameAndPhoneNumber(user)}
                    </option>
                  );
                })}
              </select>
            </>
          );
        }}
      />
    );
  }
}
