import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setCurrentMission,
  sendSMS,
  deleteMission,
  fetchSMSes,
  fetchSMSReplies
} from "../../redux/actions";
// import ModelInfos from "../../components/UI/Model/Infos";
import SMSForm from "../../components/UI/SMSes/Form";
import SMSTable from "../../components/UI/SMSes/Table";
import config from "../../config";
import SMSesModel from "../../models/smses";

class Screen extends React.Component {
  componentDidMount() {
    const { missionId } = this.props.match.params;
    this.props.setCurrentMission(missionId);

    this.props.fetchSMSes();
    this.props.fetchSMSReplies();
  }

  handleSMSSubmit = sms => {
    this.props.sendSMS(sms);
  };

  handleDeleteMission = missionId => {
    this.props.deleteMission(missionId);
  };

  render() {
    const missionId = (this.props.mission && this.props.mission.id) || null;
    const clientId =
      (this.props.mission && this.props.mission.clientId) || null;
    let client = null;
    if (clientId) {
      client = this.props.clients[clientId];
    }

    const missionSMSes = new SMSesModel(this.props.smses).filterByMission(
      missionId
    );
    return (
      <section>
        <h1>Infos</h1>

        {/* <ModelInfos model={this.props.mission} /> */}

        <h2>Actions</h2>
        <Link to={`/missions/${this.props.match.params.missionId}/edit`}>
          Editer
        </Link>
        <button
          onClick={() => {
            this.handleDeleteMission(this.props.match.params.missionId);
          }}
        >
          Delete
        </button>

        <h2>Envoyer un SMS</h2>
        <SMSForm
          onSubmitForm={this.handleSMSSubmit}
          client={client}
          missionId={missionId}
          isMock={!config.isProd()}
          isSubmitting={this.props.smsesActions.isSending}
          error={this.props.smsesActions.error}
        />

        <h2>Historique</h2>
        <SMSTable smses={missionSMSes} smsReplies={this.props.smsReplies} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    mission: state.missions.item,
    clients: state.clients.items,
    smsesActions: state.smsesActions,
    smses: state.smses.items,
    smsReplies: state.smsReplies.items
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSMS: sms => dispatch(sendSMS(sms)),
    deleteMission: missionId => dispatch(deleteMission(missionId)),
    setCurrentMission: missionId => dispatch(setCurrentMission(missionId)),
    fetchSMSes: force => dispatch(fetchSMSes(force)),
    fetchSMSReplies: force => dispatch(fetchSMSReplies(force))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
