import * as ActionTypes from "../ActionTypes";

const initialState = {
  isFetching: false,
  itemHasBeenSubmitted: false,
  item: null,
  items: []
};
export default function inputs(state = initialState, action) {
  let itemId = null;
  let item = null;

  switch (action.type) {
    /**
     * INIT
     */
    case ActionTypes.INIT_MISSION:
      return { ...state, current: action.item, itemHasBeenSubmitted: false };

    /**
     * FETCH
     */
    case ActionTypes.FETCH_MISSIONS_REQUEST:
      return { ...state, isFetching: true, itemHasBeenSubmitted: false };

    case ActionTypes.FETCH_MISSIONS_SUCCESS:
      return { ...state, items: action.items, isFetching: false };

    case ActionTypes.FETCH_MISSIONS_FAILURE:
      return { ...state, isFetching: false };

    /**
     * POST
     */
    case ActionTypes.POST_MISSION_REQUEST:
      return { ...state, isFetching: true };

    case ActionTypes.POST_MISSION_SUCCESS:
      state.items[action.item.id] = action.item;
      state.itemHasBeenSubmitted = true;
      state.isFetching = false;
      return { ...state };

    case ActionTypes.POST_MISSION_FAILURE:
      return { ...state, isFetching: false };

    /**
     * PUT
     */
    case ActionTypes.PUT_MISSION_REQUEST:
      return { ...state, isFetching: true, item: null };

    case ActionTypes.PUT_MISSION_SUCCESS:
      state.item = action.item;
      state.itemHasBeenSubmitted = true;
      state.isFetching = false;
      return { ...state };

    case ActionTypes.PUT_MISSION_FAILURE:
      return { ...state, isFetching: false };

    /**
     * DELETE
     */
    case ActionTypes.DELETE_MISSION_REQUEST:
      return { ...state, isFetching: true, item: null };

    case ActionTypes.DELETE_MISSION_SUCCESS:
      itemId = action.item;
      item = state.items[itemId];
      state.itemHasBeenSubmitted = true;
      state.isFetching = false;
      return { ...state, item: item };

    case ActionTypes.DELETE_MISSION_FAILURE:
      return { ...state, isFetching: false };

    /**
     * SET CURRENT
     */
    case ActionTypes.SET_CURRENT_MISSION:
      itemId = action.item;
      item = state.items[itemId];
      return { ...state, item: item };

    default:
      return state;
  }
}
