import utils from "../utils";

export default class SMSes {
  constructor(items = []) {
    this.items = items;
    this.smsReplies = null;
    this.smsesWithReplies = null;
  }

  hydrateWithSMSReplies(smsRepliesNormalized = {}) {
    this.smsReplies = smsRepliesNormalized;

    const smsReplies = utils.fromNormalizedtoArray(smsRepliesNormalized);
    // Fill SMSes with replies
    const smsesWithRepliesNormalized = smsReplies.reduce(
      (smsesNormalized, smsReplies) => {
        const smsId = smsReplies.smsId;
        if (smsId && smsesNormalized[smsId]) {
          // Get root sms
          const smsRepliesArr = smsesNormalized[smsId].smsReplies || [];
          smsRepliesArr.push(smsReplies);

          smsesNormalized[smsId].smsReplies = smsRepliesArr;
        }
        return smsesNormalized;
      },
      this.items
    );

    this.smsesWithReplies = smsesWithRepliesNormalized;
    // Convert object to array
    const smsesWithReplies = utils.fromNormalizedtoArray(
      smsesWithRepliesNormalized
    );
    return smsesWithReplies;
  }

  filterByMission(missionId) {
    if (!missionId) {
      return {};
    }
    const arr = utils.fromNormalizedtoArray(this.items);
    const filteredArr = arr.filter(sms => {
      return sms.options.missionId === missionId;
    });
    return utils.fromArraytoNormalized(filteredArr);
  }
}
