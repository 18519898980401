import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentClient, fetchClients } from "../../redux/actions";
import utils from "../../utils";
import ReactTable from "react-table";
import moment from "moment";
class Screen extends React.Component {
  componentDidMount() {
    this.props.fetchClients();
  }

  goToClientEdit(clientId) {
    this.props.history.push("/clients/" + clientId + "/edit");
  }
  goToClientInfos(clientId) {
    this.props.history.push("/clients/" + clientId + "/infos");
  }

  render() {
    const columns = [
      {
        Header: "#",
        Cell: row => row.index + 1
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: row => {
          return moment(row.value).format("DD/MM HH:mm");
        }
      },
      {
        Header: "Prénom",
        accessor: "firstName"
      },
      {
        Header: "Nom",
        accessor: "lastName"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Téléphone",
        accessor: "phoneNumber"
      },
      {
        Header: "Adresse",
        accessor: "address",
        Cell: row => {
          return utils.fromAddressToString(row.value);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 200,
        Cell: row => {
          const clientId = row.original.id;
          return (
            <div>
              {/* <button
                onClick={() => {
                  this.goToClientInfos(clientId);
                }}
              >
                See
              </button> */}
              <button
                onClick={() => {
                  this.goToClientEdit(clientId);
                }}
              >
                Edit
              </button>
            </div>
          );
        }
      }
    ];
    return (
      <section>
        <h1>Clients</h1>
        <Link to={`${this.props.match.url}/form`}>Nouveau</Link>
        <ReactTable data={this.props.clients} columns={columns} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    clients: utils.getSortedArrayCollection(state.clients.items)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentClient: clientId => dispatch(setCurrentClient(clientId)),

    fetchClients: force => dispatch(fetchClients(force))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
