import * as ActionTypes from "../ActionTypes";
import config from "../../config";
import Action from "./model";
import utils from "../../utils";
import CONSTANTS from "../../constants";
import { postClientPromise } from "./clients";

const MISSIONS_COLLECTION = config.firebase.firestore.collections.missions;

const a = new Action("MISSION", "MISSIONS", MISSIONS_COLLECTION);
export const fetchMissions = a.fetch;
// export const postMission = a.post;
export const putMission = a.put;
export const deleteMission = a.delete;

export const postMission = (item, urlAfterSuccess) => {
  return dispatch => {
    dispatch({
      type: ActionTypes["POST_MISSION_REQUEST"],
      item
    });

    if (item.clientId === CONSTANTS.NEW_USER_SELECTED) {
      const fullItem = utils.cleanObject(item);
      const { client } = fullItem;
      delete fullItem.client;
      const mission = fullItem;
      return postClientPromise(client, null, dispatch).then(newClient => {
        mission.clientId = newClient.id;

        dispatch(a.post(mission, urlAfterSuccess));
      });
    }
  };
};
/**
 * ==========
 * CURRENT MISSION
 * ==========
 */
export const setCurrentMission = missionId => {
  return {
    type: ActionTypes.SET_CURRENT_MISSION,
    item: missionId
  };
};
