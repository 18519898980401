// Render Prop
import React from "react";
import { Field } from "formik";

// const Basic = ({
//   isSubmitting,
//   mode = "",
//   isDebug = true,
//   submitButtonText = "Submit"
// }) => {
const Basic = props => {
  let isDebug = props.isDebug;
  // console.log("isDebug", isDebug);
  // if (isDebug !== false) {
  // isDebug = true;
  // }
  const isCompactMode = props.mode === "compact";
  let namespace = "";
  if (props.namespace) {
    namespace = props.namespace + ".";
  }
  return (
    <section>
      {isCompactMode ? (
        ""
      ) : (
        <>
          <div>
            <label htmlFor="id">Id</label>
            <Field type="text" name={namespace + "id"} disabled />
          </div>

          <div>
            <label htmlFor="createdAt">Créé le </label>
            <Field type="text" name={namespace + "createdAt"} disabled />
          </div>

          <div>
            <label htmlFor="status">Statut </label>
            <Field type="text" name={namespace + "status"} disabled />
          </div>
        </>
      )}

      <div className={isCompactMode ? "flex" : ""}>
        <div>
          <label htmlFor="firstName">Prénom</label>
          <Field
            type="text"
            name={namespace + "firstName"}
            placeholder="Baptiste..."
          />
        </div>
        <div>
          <label htmlFor="lastName">Nom</label>
          <Field
            type="text"
            name={namespace + "lastName"}
            placeholder="Renard..."
          />
        </div>
      </div>
      <div className={isCompactMode ? "flex" : ""}>
        <div>
          <label htmlFor="email">Email</label>
          <Field
            type="email"
            name={namespace + "email"}
            placeholder="regis@wesh.fr"
          />
        </div>

        <div>
          <label htmlFor="phoneNumber">Téléphone</label>
          <Field
            type="text"
            name={namespace + "phoneNumber"}
            placeholder="+33..."
          />
        </div>
      </div>
      <div className={isCompactMode ? "flex" : ""}>
        <div>
          <label htmlFor="address.line1">Adresse</label>
          <Field
            type="text"
            name={namespace + "address.line1"}
            placeholder="19 rue du ..."
          />
        </div>

        <div>
          <label htmlFor="address.line2">Complément</label>
          <Field
            type="text"
            name={namespace + "address.line2"}
            placeholder="Appt, étage, "
          />
        </div>
      </div>
      <div className={isCompactMode ? "flex" : ""}>
        <div>
          <label htmlFor="address.postalCode">Code Postal</label>
          <Field
            type="text"
            name={namespace + "address.postalCode"}
            placeholder="31..."
          />
        </div>

        <div>
          <label htmlFor="address.city">Ville</label>
          <Field
            type="text"
            name={namespace + "address.city"}
            placeholder="Toul..."
          />
        </div>
      </div>
      {isDebug ? (
        <div>
          <label htmlFor="isDebug">Is debug ?</label>
          <Field
            name={namespace + "isDebug"}
            type="checkbox"
            defaultChecked={isDebug}
          />
        </div>
      ) : (
        ""
      )}
      {isCompactMode ? (
        ""
      ) : (
        <div>
          <label htmlFor="notes">Notes</label>
          <Field
            component="textarea"
            name={namespace + "notes"}
            cols="25"
            rows="5"
          />
        </div>
      )}
    </section>
  );
};

export default Basic;
