export default {
  api: {
    URL: "https://us-central1-plombiers-toulousains.cloudfunctions.net/",
    endpoints: {
      sendSMS: "sendSMS"
    }
  },
  firebase: {
    config: {
      apiKey: "AIzaSyDR0Bu92YOkbOCBfaPU7anbCy_Hq5EqcUY",
      authDomain: "plombiers-toulousains.firebaseapp.com",
      databaseURL: "https://plombiers-toulousains.firebaseio.com",
      projectId: "plombiers-toulousains",
      storageBucket: "plombiers-toulousains.appspot.com",
      messagingSenderId: "797492534168"
    },
    database: { references: { webhooks: "webhooks" } },
    firestore: {
      collections: {
        sms: "sms",
        smsReplies: "smsReplies",
        clients: "clients",
        companions: "companions",
        missions: "missions"
      }
    }
  },
  sms: {
    types: {
      WITH_BRAND: "WITH_BRAND",
      WITH_REPLIES: "WITH_REPLIES"
    }
  }
};
