import React from "react";
import { connect } from "react-redux";
import { postClient, resetGoTo } from "../../redux/actions";
import ModelForm from "../../components/UI/Model/Form";
import config from "../../config";

class ScreenLogin extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.goTo) {
      this.props.history.push(nextProps.goTo);
      this.props.resetGoTo();
    }
    return true;
  }

  handleFormSubmit = form => {
    this.props.postClient(form);
  };
  render() {
    return (
      <section>
        <h1>Nouveau client</h1>

        <ModelForm
          onSubmitForm={this.handleFormSubmit}
          isDebug={!config.isProd()}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    goTo: state.globals.goTo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postClient: form => dispatch(postClient(form, "/clients")),
    resetGoTo: () => dispatch(resetGoTo())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenLogin);
