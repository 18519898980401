import config from "./config";
import axios from "axios";
// Required for side-effects
require("firebase/functions");

export default class FirebaseFunctions {
  /**
   * @param {string} recipient - recipient's phonenumber. Any format:
   *  "06 01 02 03 04", "+33601020304", "00336 01 020304" ==> +33601020304
   *
   * @param {string} body  - sms's body. Max 459 characters
   * @param {Object} options
   * @param {string} options.type - FR / WITH_RESPONSE - Default: FR
   * @param {string} options.sender - Default: Selfcity
   * @param {boolean} options.isMock - false if PROD
   *
   * @throws {SMSFormatPhoneNumberError}
   * @throws {SMSBodyTooLong}
   * @throws {PROVIDER_ERROR}
   * @throws {Error}
   */
  static _sendSMS(recipient, body, options = { isMock: true }) {
    const url = config.api.URL + config.api.endpoints.sendSMS;
    return axios
      .post(url, { recipient, body, options })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        console.log(e);
        throw Error("Error sending sms", e);
      });
  }

  static sendSMS(recipient, body, options) {
    const smsTypes = config.sms.types;
    if (
      options &&
      (options.type === smsTypes.WITH_BRAND ||
        options.type === smsTypes.WITH_REPLIES)
    ) {
      return FirebaseFunctions._sendSMS(recipient, body, options);
    }
    throw Error("No type is defined for SMS.");
  }
}
