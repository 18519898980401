// Render Prop
import React from "react";
import config from "../../../config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneNumberHelper from "../../../helpers/PhoneNumberHelper";

const defaultItem = {
  isMock: true,
  type: config.sms.types.WITH_BRAND,
  submitButtonText: "Envoyer"
};

export default class SMSForm extends React.Component {
  constructor() {
    super();
    this.state = {
      body: null,
      success: false,
      successMessage: "SMS envoyé avec succès."
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    console.log(this.props.isSubmitting, nextProps.isSubmitting);
    // Detect when submit is success
    if (
      this.props.isSubmitting &&
      !nextProps.isSubmitting &&
      !nextProps.error
    ) {
      this.setState({ success: true, body: " " });
    } else if (this.props.isSubmitting !== nextProps.isSubmitting) {
      this.setState({ success: false, body: null });
    }
    return true;
  }

  validateRecipient = value => {
    const number = new PhoneNumberHelper(value);
    if (number.isValid()) {
      return null;
    } else {
      return "Mauvais format de numéro de téléphone : 05 61 05 01 01";
    }
  };

  render() {
    let {
      recipient,
      body,
      client,
      clients,
      type = defaultItem.type,
      isMock = defaultItem.isMock,
      missionId,
      onSubmitForm,
      submitButtonText = defaultItem.submitButtonText,
      error
    } = this.props;

    let clientId = null;
    let clientName = null;
    let isUniqueClient = false;
    let isMultipleClients = false;

    if (client) {
      clientId = client.id;
      recipient = client.phoneNumber;
      clientName =
        client.name || (client.firstName || "") + " " + (client.lastName || "");
      client.name = clientName;
      isUniqueClient = true;
      clients = [client];
    }

    if (clients) {
      isMultipleClients = true;
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          recipient,
          body: this.state.body || body,
          type,
          clientId,
          isMock
        }}
        onSubmit={(values, { setSubmitting }) => {
          const { body, clientId, type, isMock } = values;
          let { recipient } = values;

          if (clientId) {
            let clientFound = clients.reduce((arr, val) => {
              if (val.id === clientId) {
                arr = val;
              }
              return arr;
            }, null);
            recipient = clientFound.phoneNumber;
          }
          recipient = new PhoneNumberHelper(recipient).parse();

          const data = {
            recipient,
            body,
            options: {
              type,
              clientId,
              missionId,
              isMock
            }
          };
          onSubmitForm(data);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {isMultipleClients || isUniqueClient ? (
              ""
            ) : (
              <div>
                <div>{clientName}</div>
                <label htmlFor="recipient">Destinataire</label>
                <Field
                  type="text"
                  name="recipient"
                  placeholder="06 ..."
                  validate={this.validateRecipient}
                />
                <ErrorMessage
                  name="recipient"
                  component="div"
                  className="error"
                />
              </div>
            )}

            {isMultipleClients ? (
              <div>
                <label htmlFor="clientId">Client</label>
                <Field component="select" name="clientId" value={clientId}>
                  <option value="" />
                  {clients.map(({ id, name, phoneNumber }) => {
                    return (
                      <option value={id} key={id || name}>
                        {name} - {new PhoneNumberHelper(phoneNumber).format()}
                      </option>
                    );
                  })}
                </Field>
              </div>
            ) : (
              ""
            )}

            <div>
              <label htmlFor="type">Type</label>
              <Field component="select" name="type">
                <option
                  value={config.sms.types.WITH_BRAND}
                  key={config.sms.types.WITH_BRAND}
                >
                  Selfcity
                </option>
                <option
                  value={config.sms.types.WITH_REPLIES}
                  key={config.sms.types.WITH_REPLIES}
                >
                  SMS de satisfaction
                </option>
              </Field>
            </div>

            {isMock ? (
              <div>
                <label htmlFor="isMock">Is mock ?</label>
                <Field name="isMock" type="checkbox" defaultChecked={isMock} />
                {/* <input type="checkbox" /> */}
                {/* </Field> */}
              </div>
            ) : (
              ""
            )}
            <div>
              <label htmlFor="body">SMS</label>
              <Field
                component="textarea"
                name="body"
                cols="25"
                rows="5"
                maxLength="459"
              />
            </div>
            {error ? <div className="error">{error.message}</div> : ""}
            {this.state.success ? (
              <div className="success">{this.state.successMessage}</div>
            ) : (
              ""
            )}
            <div>
              <button
                type="submit"
                disabled={isSubmitting || this.props.isSubmitting}
              >
                {submitButtonText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
