import * as ActionTypes from "../ActionTypes";

export default (COLLECTION, pluralCollection) => {
  const COLLECTION_PLURAL = pluralCollection || COLLECTION + "S";

  const initialState = {
    isFetching: false,
    isSending: false,
    itemHasBeenSubmitted: false,
    item: null,
    items: [],
    deletedItems: [],
    error: {}
  };

  return function inputs(state = initialState, action) {
    let itemId = null;
    let item = null;

    switch (action.type) {
      /**
       * INIT
       */
      case ActionTypes["INIT_" + COLLECTION]:
        return { ...state, current: action.item, itemHasBeenSubmitted: false };

      /**
       * FETCH
       */
      case ActionTypes["FETCH_" + COLLECTION_PLURAL + "_REQUEST"]:
        return { ...state, isFetching: true, itemHasBeenSubmitted: false };

      case ActionTypes["FETCH_" + COLLECTION_PLURAL + "_SUCCESS"]:
        return {
          ...state,
          items: action.items,
          deletedItems: action.deletedItems,
          isFetching: false
        };

      case ActionTypes["FETCH_" + COLLECTION_PLURAL + "_FAILURE"]:
        return { ...state, isFetching: false };

      /**
       * POST
       */
      case ActionTypes["POST_" + COLLECTION + "_REQUEST"]:
        return { ...state, isFetching: true };

      case ActionTypes["POST_" + COLLECTION + "_SUCCESS"]:
        state.items[action.item.id] = action.item;
        state.itemHasBeenSubmitted = true;
        state.isFetching = false;
        return { ...state };

      case ActionTypes["POST_" + COLLECTION + "_FAILURE"]:
        return { ...state, isFetching: false, error: action.error };

      /**
       * PUT
       */
      case ActionTypes["PUT_" + COLLECTION + "_REQUEST"]:
        return { ...state, isFetching: true, item: null };

      case ActionTypes["PUT_" + COLLECTION + "_SUCCESS"]:
        state.item = action.item;
        state.itemHasBeenSubmitted = true;
        state.isFetching = false;
        return { ...state };

      case ActionTypes["PUT_" + COLLECTION + "_FAILURE"]:
        return { ...state, isFetching: false, error: action.error };

      /**
       * DELETE
       */
      case ActionTypes["DELETE_" + COLLECTION + "_REQUEST"]:
        return { ...state, isFetching: true, item: null };

      case ActionTypes["DELETE_" + COLLECTION + "_SUCCESS"]:
        itemId = action.item;
        item = state.items[itemId];
        state.itemHasBeenSubmitted = true;
        state.isFetching = false;
        return { ...state, item: item };

      case ActionTypes["DELETE_" + COLLECTION + "_FAILURE"]:
        return { ...state, isFetching: false, error: action.error };

      /**
       * SET CURRENT
       */
      case ActionTypes["SET_CURRENT_" + COLLECTION]:
        itemId = action.item;
        item = state.items[itemId];
        return { ...state, item: item };

      default:
        return state;
    }
  };
};
