import React from "react";
import { connect } from "react-redux";
import { setCurrentClient, putClient, resetGoTo } from "../../redux/actions";
import ModelForm from "../../components/UI/Model/Form";
import config from "../../config";

class Screen extends React.Component {
  componentDidMount() {
    const { clientId } = this.props.match.params;

    this.props.setCurrentClient(clientId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.goTo) {
      this.props.history.push(nextProps.goTo);
      console.log("nextProps.goto", nextProps.goTo);
      this.props.resetGoTo();
    }
    return true;
  }

  handleFormSubmit = form => {
    this.props.putClient(form);
  };

  render() {
    return (
      <section>
        <h1>Mettre à jour</h1>

        <ModelForm
          onSubmitForm={this.handleFormSubmit}
          model={this.props.client}
          isDebug={!config.isProd()}
          submitButtonText="Mettre à jour"
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return { client: state.clients.item, goTo: state.globals.goTo };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentClient: clientId => dispatch(setCurrentClient(clientId)),
    putClient: form => dispatch(putClient(form, "/clients")),
    resetGoTo: () => dispatch(resetGoTo())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
