import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setCurrentMission,
  fetchMissions,
  fetchClients,
  fetchCompanions,
  resetGoTo
} from "../../redux/actions";
import utils from "../../utils";
import ReactTable from "react-table";

class Screen extends React.Component {
  componentDidMount() {
    this.props.fetchMissions();
    this.props.fetchClients();
    this.props.fetchCompanions();
  }

  goToMissionEdit(missionId) {
    this.props.history.push("/missions/" + missionId + "/edit");
  }

  goToMissionInfos(missionId) {
    this.props.history.push("/missions/" + missionId + "/infos");
  }

  render() {
    const columns = [
      {
        Header: "#",
        Cell: row => row.index + 1,
        width: 25
      },
      {
        Header: "Date",
        style: { whiteSpace: "pre-line" },
        Cell: row => {
          const mission = row.original;
          let res = "";
          if (mission.timing) {
            const { startPlannedAt, endPlannedAt } = mission.timing;
            res = utils.formatDatetime(startPlannedAt, "rsd");
            res += " - " + utils.formatDatetime(startPlannedAt, "t");
            res += " - " + utils.formatDatetime(endPlannedAt, "t");
          }
          return res;
        }
      },
      {
        Header: "Titre",
        style: { whiteSpace: "pre-line" },
        accessor: "title"
      },
      {
        Header: "Next step",
        accessor: "nextStep",
        style: { whiteSpace: "pre-line" }
      },
      {
        Header: "Client",
        accessor: "clientId",
        Cell: row => {
          let res = "N/A";
          if (row.value) {
            const client = this.props.clients[row.value];
            res = utils.getUserFullName(client);
          }
          return res;
        }
      },
      {
        Header: "Créée le",
        accessor: "createdAt",

        Cell: row => {
          let res = "";
          res = utils.formatDatetime(row.value, "rs");
          return res;
        }
      },
      {
        Header: "Statut",
        accessor: "status",
        Cell: row => {
          const missionId = row.original.id;
          return (
            <div>
              <button
                onClick={() => {
                  this.goToMissionInfos(missionId);
                }}
              >
                <span role="img" aria-label="See">
                  👁️
                </span>
              </button>
              <button
                onClick={() => {
                  this.goToMissionEdit(missionId);
                }}
              >
                <span role="img" aria-label="Edit">
                  ✏️
                </span>
              </button>
            </div>
          );
        }
      }
    ];
    return (
      <section>
        <h1>Missions</h1>
        <Link to={`${this.props.match.url}/form`}>Nouveau</Link>
        <ReactTable data={this.props.missions} columns={columns} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    missions: utils.getSortedArrayCollection(state.missions.items),
    clients: state.clients.items,
    companions: state.companions.items,
    goto: state.missions.goto
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentMission: missionId => dispatch(setCurrentMission(missionId)),
    fetchMissions: force => dispatch(fetchMissions(force)),
    fetchClients: force => dispatch(fetchClients(force)),
    fetchCompanions: force => dispatch(fetchCompanions(force)),
    resetGoTo: () => dispatch(resetGoTo())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
