import { parsePhoneNumberFromString } from "libphonenumber-js";

export default class PhoneNumberHelper {
  constructor(number) {
    if (number) {
      this.originalNumber = number.trim();
    } else {
      this.originalNumber = "";
    }
    this.phoneNumber = parsePhoneNumberFromString(this.originalNumber);
    if (!this.phoneNumber) {
      this.phoneNumber = parsePhoneNumberFromString(this.originalNumber, "FR");
    }
  }

  isValid() {
    if (this.phoneNumber) {
      return this.phoneNumber.isValid();
    } else {
      return false;
    }
  }

  format(mode) {
    let res = "";
    if (this.phoneNumber && this.isValid()) {
      res = this.phoneNumber.formatInternational();
      if (mode === "national") {
        res = this.phoneNumber.formatNational();
      } else if (mode === "iso") {
        res = this.phoneNumber.number;
      }
    }
    return res;
  }

  parse() {
    return this.format("iso");
  }
}
