import * as ActionTypes from "../ActionTypes";
import Action from "./model";
import config from "../../config";

const CLIENTS_COLLECTION = config.firebase.firestore.collections.clients;

const a = new Action("CLIENT", "CLIENTS", CLIENTS_COLLECTION);
export const fetchClients = a.fetch;
export const postClient = a.post;
export const postClientPromise = a.postPromise;
export const putClient = a.put;
export const deleteClient = a.delete;

/**
 * ==========
 * CURRENT USER
 * ==========
 */
export const setCurrentClient = clientId => {
  return {
    type: ActionTypes.SET_CURRENT_CLIENT,
    item: clientId
  };
};
