import * as ActionTypes from "../ActionTypes";
import FirebaseFunctions from "../../FirebaseFunctions";
import config from "../../config";
import Action from "./model";

class action extends Action {
  /**
   * ==========
   * SEND_SMS
   * ==========
   */
  sendSMS = sms => {
    return dispatch => {
      dispatch({
        type: ActionTypes.SEND_SMS_REQUEST,
        item: sms
      });
      const { recipient, body, options } = sms;
      return FirebaseFunctions.sendSMS(recipient, body, options)
        .then(res => {
          dispatch(this.sendSMSSuccess(sms));
        })
        .catch(err => {
          dispatch(this.sendSMSFailure(err));
        });
    };
  };

  sendSMSSuccess = item => {
    return {
      type: ActionTypes.SEND_SMS_SUCCESS,
      item
    };
  };
  sendSMSFailure = error => {
    return {
      type: ActionTypes.SEND_SMS_FAILURE,
      error
    };
  };
}

const SMS_COLLECTION = config.firebase.firestore.collections.sms;
const a = new action("SMS", "SMSES", SMS_COLLECTION);
export const fetchSMSes = a.fetch;
export const sendSMS = a.sendSMS;
