// Render Prop
import React from "react";
import FormUI from "./FormUI";
import { Formik, Form } from "formik";
const defaultUser = {
  id: "",
  createdAt: "",
  status: "",
  firstName: "",
  lastName: "",
  address: { line1: "", line2: "", postalCode: "", city: "" },
  phoneNumber: "",
  email: ""
};
const Basic = ({
  mode = "",
  model = defaultUser,
  isDebug = true,
  onSubmitForm,
  submitButtonText = "Submit"
}) => {
  model = { ...defaultUser, ...model };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...model,
        isDebug
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormUI
            isSubmitting={isSubmitting}
            mode={mode}
            submitButtonText={submitButtonText}
            isDebug={isDebug}
          />
          <div>
            <button type="submit" disabled={isSubmitting}>
              {submitButtonText}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Basic;
