import React from "react";
import { Link } from "react-router-dom";
import config from "../../config";
class Header extends React.Component {
  render() {
    const isConnected = this.props.auth.user !== null;
    return (
      <header>
        <nav className="nav">
          {isConnected ? (
            <PrivateHeaderLinks auth={this.props.auth} />
          ) : (
            <PublicHeaderLinks />
          )}
        </nav>
      </header>
    );
  }
}

const PublicHeaderLinks = () => (
  <ul className="no-style inline">
    <li>
      <Link to="/">Accueil</Link>
    </li>
    <li>
      <Link to="/login">Se connecter</Link>
    </li>
  </ul>
);

const PrivateHeaderLinks = ({ auth }) => (
  <ul className="no-style inline">
    <li>
      <Link to="/">Accueil</Link>
    </li>
    <li>
      <Link to="/missions">Missions</Link>
    </li>
    <li>
      <Link to="/clients">Clients</Link>
    </li>
    <li>
      <Link to="/companions">Plombiers</Link>
    </li>
    {/*
      HIDDEN because must be reserved to admin 
    <li>
      <Link to="/smses">SMS</Link>
    </li> */}
    <li>{config.getEnv()}</li>
    <li>v{config.version}</li>
    <li>{auth.user.email}</li>
    <li>
      <Link to="/logout">Se déconnecter</Link>
    </li>
  </ul>
);

export default Header;
