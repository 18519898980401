import * as ActionTypes from "../ActionTypes";
import config from "../../config";
import Action from "./model";

const COMPANIONS_COLLECTION = config.firebase.firestore.collections.companions;

const a = new Action("COMPANION", "COMPANIONS", COMPANIONS_COLLECTION);
export const fetchCompanions = a.fetch;
export const postCompanion = a.post;
export const putCompanion = a.put;
export const deleteCompanion = a.delete;

/**
 * ==========
 * CURRENT COMPANION
 * ==========
 */
export const setCurrentCompanion = companionId => {
  return {
    type: ActionTypes.SET_CURRENT_COMPANION,
    item: companionId
  };
};
