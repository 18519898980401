import firebase from "firebase";
import config from "./config";
import utils from "./utils";
import moment from "moment";
import CONSTANTS from "./constants";

const SMS_COLLECTION = config.firebase.firestore.collections.sms;
const SMS_REPLIES_COLLECTION = config.firebase.firestore.collections.smsReplies;
export default class Firebase {
  constructor(collection) {
    this.collection = collection;
  }
  static init() {
    firebase.initializeApp(config.firebase.config);
  }
  /**
   * callback: function(user){}
   */
  static onAuthStateChanged(callback) {
    return firebase.auth().onAuthStateChanged(user => {
      callback(user ? user.toJSON() : null);
    });
  }
  static signIn(email, password) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        return res.user.toJSON();
      });
  }
  static signOut() {
    return firebase.auth().signOut();
  }
  static add() {
    const db = firebase.firestore();
    db.collection("diagnostics")
      .add({
        first: "Ada",
        last: "Lovelace",
        born: 1815
      })
      .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }
  static fetchDiagnostics() {
    const db = firebase.firestore();
    return db
      .collection("diagnostics")
      .get()
      .then(function(querySnapshot) {
        const items = {};
        querySnapshot.forEach(doc => {
          items[doc.id] = doc.data();
        });
        return items;
      });
  }
  static postDiagnostic(diagnostic) {
    const db = firebase.firestore();
    let id = null;
    return db
      .collection("diagnostics")
      .add(diagnostic)
      .then(docRef => {
        id = docRef.id;
        return db
          .collection(config.db.rootCollection)
          .doc(config.db.envCollection)
          .collection("diagnostics")
          .doc(id)
          .set(
            {
              id
            },
            { merge: true }
          );
      })
      .then(() => {
        return id;
      });
  }
  /**
   * GENERIC
   */
  post(item) {
    const db = firebase.firestore();
    let id = null;
    let newItem = this._addCreationMetadata(item, true);
    return db
      .collection(this.collection)
      .add(newItem)
      .then(docRef => {
        id = docRef.id;
        return db
          .collection(this.collection)
          .doc(id)
          .set(
            {
              id
            },
            { merge: true }
          );
      })
      .then(() => {
        return id;
      });
  }

  put(item) {
    const db = firebase.firestore();
    let newItem = this._addUpdateMetadata(item);

    return db
      .collection(this.collection)
      .doc(item.id)
      .update(newItem)
      .then(() => {
        return item.id;
      });
  }

  delete(itemId) {
    const db = firebase.firestore();
    const emptyItem = { id: itemId };
    let item = this._addDeletionMetadata(emptyItem, true);
    return db
      .collection(this.collection)
      .doc(itemId)
      .update(item)
      .then(() => {
        return item;
      });
  }

  /**
   * SMS
   */
  static fetchSMSes() {
    const db = firebase.firestore();
    return db
      .collection(SMS_COLLECTION)
      .get()
      .then(function(querySnapshot) {
        const items = {};
        querySnapshot.forEach(doc => {
          items[doc.id] = doc.data();
        });
        return items;
      });
  }
  static postSMS(sms, res) {
    const db = firebase.firestore();
    let id = null;

    const createdAt = new Date().toISOString();
    const smsToSave = {
      ...sms,
      rawResponse: res,
      createdAt
    };
    // Remove undefined vlaues
    const cleanSMS = utils.cleanObject(smsToSave);
    return db
      .collection(SMS_COLLECTION)
      .add(cleanSMS)
      .then(docRef => {
        id = docRef.id;
        return db
          .collection(SMS_COLLECTION)
          .doc(id)
          .set(
            {
              id
            },
            { merge: true }
          );
      })
      .then(() => {
        return id;
      });
  }
  /**
   * SMS_REPLIES
   */
  static fetchSMSReplies() {
    const db = firebase.firestore();
    return db
      .collection(SMS_REPLIES_COLLECTION)
      .get()
      .then(function(querySnapshot) {
        const items = {};
        querySnapshot.forEach(doc => {
          items[doc.id] = doc.data();
        });
        return items;
      });
  }
  static connectNotifications(callback) {
    const ref = firebase.database().ref("notifications/");
    ref.on("child_added", function(snapshot) {
      callback();
    });
  }

  _addUpdateMetadata(body) {
    const updatedAt = moment().format();
    const withMetadata = {
      ...body,
      updatedAt
    };

    return withMetadata;
  }

  _addCreationMetadata(body, withHistory = false) {
    const createdAt = moment().format();
    const status = CONSTANTS.status.CREATED;
    const withMetadata = {
      ...body,
      createdAt,
      status,
      statusId: CONSTANTS.statusIds[status]
    };
    if (withHistory) {
      const createdStatus = {
        status: CONSTANTS.status.CREATED,
        date: createdAt
      };
      const statusHistory = [createdStatus];
      withMetadata.statusHistory = statusHistory;
    }

    return withMetadata;
  }

  _addDeletionMetadata(body, withHistory = false) {
    const deletedAt = moment().format();
    const status = CONSTANTS.status.DELETED;

    const withMetadata = {
      ...body,
      deletedAt,
      status,
      statusId: CONSTANTS.statusIds[status]
    };
    if (withHistory) {
      const newStatus = {
        status: status,
        date: deletedAt
      };
      withMetadata.statusHistory = firebase.firestore.FieldValue.arrayUnion(
        newStatus
      );
    }
    return withMetadata;
  }
}
