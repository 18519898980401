import * as ActionTypes from "../ActionTypes";

const initialState = {
  user: null,
  isFetching: false,
  onAuthStateChanged: false,
  error: null
};
export default function auth(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CHECK_AUTH_SESSION:
      return { ...state, user: null };

    case ActionTypes.ON_AUTH_STATE_CHANGED_SIGNED_IN:
      return { ...state, user: action.user, onAuthStateChanged: true };

    case ActionTypes.ON_AUTH_STATE_CHANGED_SIGNED_OUT:
      return { ...state, user: null, onAuthStateChanged: true };

    case ActionTypes.LOGIN_REQUEST:
      return { ...state, user: null, isFetching: true, error: null };

    case ActionTypes.LOGIN_SUCCESS:
      return { ...state, user: action.user, isFetching: false };

    case ActionTypes.LOGIN_FAILURE:
      return { ...state, user: null, isFetching: false, error: action.error };

    case ActionTypes.LOGOUT:
      return { ...state, user: null, isFetching: false };

    default:
      return state;
  }
}
