import utils from "../../../utils";

// Render Prop
import React from "react";
import { Formik, Form, Field } from "formik";
import UserSelectField from "./UserSelectField";
import moment from "moment";
import CONSTANTS from "../../../constants";
import ModelFormUI from "../Model/FormUI";

const nextSteps = CONSTANTS.missions.nextStepsArray;

const defaultItem = {
  id: "",
  createdAt: "",
  status: "",
  title: "",
  nextStep: "",
  client: {},
  clientId: "",
  companionId: "",
  diagnostic: {
    notes: ""
  },
  timing: {
    tmp: {
      date: moment().format("YYYY-MM-DD"),
      startPlannedAtTime: "",
      endPlannedAtTime: ""
    },
    startPlannedAt: "",
    endPlannedAt: "",
    startedAt: "",
    endedAt: ""
  },
  invoicing: {
    totalAmountDutyFree: "",
    totalAmount: "",
    notes: ""
  },
  supplying: {
    notes: ""
  },
  notes: ""
};

export default class MissionForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isNewClientSelected: false,
      client: null,
      companion: null
    };
  }

  /**
   * When props update, need to update state.
   * Useful when editing a profile.
   */
  shouldComponentUpdate(nextProps, nextState) {
    const clientId = nextProps.model && nextProps.model.clientId;
    const companionId = nextProps.model && nextProps.model.companionId;
    if (
      clientId &&
      nextProps.clients &&
      nextProps.clients.length > 0 &&
      !nextState.client
    ) {
      const clients = nextProps.clients || [];
      const client = clients.filter(client => {
        return client.id === clientId;
      })[0];
      this.handeClientChange({ user: client });
    }

    if (
      companionId &&
      nextProps.companions &&
      nextProps.companions.length > 0 &&
      !nextState.companion
    ) {
      const companions = nextProps.companions || [];
      const companion = companions.filter(companion => {
        return companion.id === companionId;
      })[0];
      this.handeCompanionChange({ user: companion });
    }

    return true;
  }

  handeClientChange = ({ user, field }) => {
    const isNewClientSelected =
      field && field.value === CONSTANTS.NEW_USER_SELECTED;

    this.setState({ client: user, isNewClientSelected });
  };

  handeCompanionChange = ({ user }) => {
    this.setState({ companion: user });
  };

  render() {
    let {
      model = defaultItem,
      isDebug = this.props.isDebug || true,
      submitButtonText = "Submit",
      clients = [],
      companions = []
    } = this.props;

    // FIXME
    // If companionId not defined
    // AND companions has only one value,
    // --> setup this default value
    // if (!model.companionId && companions.length === 1) {
    //   model.companionId = companions[0].id;
    //   if (this.state.companion === null) {
    //     this.handeCompanionChange({ user: companions[0] });
    //   }
    // }

    // Process temporatory dates and times
    // To fill appropriate inputs
    let timing = model && model.timing;

    if (timing && timing.startPlannedAt) {
      const date = utils.formatDate(model.timing.startPlannedAt);
      const startPlannedAtTime = utils.formatTime(model.timing.startPlannedAt);
      const endPlannedAtTime = utils.formatTime(model.timing.endPlannedAt);
      timing = {
        tmp: { date, startPlannedAtTime, endPlannedAtTime }
      };
    } else {
      timing = defaultItem.timing;
    }

    // Fill the default model

    model = { ...model, timing };
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...model,
            isDebug
          }}
          onSubmit={(values, actions) => {
            // Because we use delete after, clean references
            values = utils.cleanObject(values);
            // Delete isDebug attribut if false
            if (!isDebug) {
              delete values.isDebug;
            }
            // * Manage timing date + time
            const {
              date,
              startPlannedAtTime,
              endPlannedAtTime
            } = values.timing.tmp;
            const startPlannedAt = utils.formatDateWithTime(
              date,
              startPlannedAtTime
            );
            const endPlannedAt = utils.formatDateWithTime(
              date,
              endPlannedAtTime
            );
            delete values.timing.tmp;

            const fullValues = {
              ...values,
              timing: { ...values.timing, startPlannedAt, endPlannedAt }
            };

            console.log("fullValues", fullValues);
            this.props.onSubmitForm(fullValues);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <h4>Raccourcis</h4>
              <nav>
                <ul>
                  <li>
                    <a href="#scheduling">Planification</a>
                  </li>
                  <li>
                    <a href="#invoicing">Facturation</a>
                  </li>
                  <li>{/* <a href="#supplying">Matériel</a> */}</li>
                  <li>
                    <a href="#clientId">Client</a>
                  </li>
                  <li>
                    <a href="#companionId">Plombier</a>
                  </li>
                </ul>
              </nav>

              <section className="flex">
                <div>
                  <h2>Titre</h2>
                  <div>
                    <Field type="text" name="title" />
                  </div>
                </div>
                <div>
                  <h2>Etat</h2>
                  <div>
                    <Field component="select" name="nextStep">
                      <option value="" />
                      {nextSteps.map(nextStep => {
                        return (
                          <option value={nextStep} key={nextStep}>
                            {nextStep}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </div>
              </section>
              <section>
                <h2>Diagnostic</h2>
                <div>
                  <label htmlFor="diagnostic.notes">Notes</label>
                  <Field
                    component="textarea"
                    name="diagnostic.notes"
                    cols="80"
                    rows="7"
                  />
                </div>
              </section>
              <section>
                <h2 id="scheduling">Planification</h2>
                <div className="flex">
                  <div>
                    <label htmlFor="timing.tmp.date">Date</label>
                    <Field type="date" name="timing.tmp.date" />
                  </div>
                  <div>
                    <label htmlFor="timing.tmp.startPlannedAtTime">
                      Début prévu à
                    </label>
                    <Field
                      type="text"
                      name="timing.tmp.startPlannedAtTime"
                      placeholder="13h30"
                    />
                  </div>
                  <div>
                    <label htmlFor="timing.tmp.endPlannedAtTime">
                      Fin prévue à
                    </label>
                    <Field
                      type="text"
                      name="timing.tmp.endPlannedAtTime"
                      placeholder="15h00"
                    />
                  </div>
                </div>
              </section>
              {/* 
              <section id="invoicing">
                <h2>Facturation</h2>
                <div className="flex">
                  <div>
                    <label htmlFor="invoicing.totalAmountDutyFree">
                      Prix € HT
                    </label>
                    <Field type="number" name="invoicing.totalAmountDutyFree" />
                  </div>
                  <div>
                    <label htmlFor="invoicing.totalAmount">Prix € TTC</label>
                    <Field type="number" name="invoicing.totalAmount" />
                  </div>
                  <div>
                  <label htmlFor="invoicing.notes">Détails</label>
                  <Field
                    component="textarea"
                    name="invoicing.notes"
                    cols="60"
                    rows="10"
                  />
                </div>
                </div>
              </section>
                 */}
              {/* <section id="supplying">
                <h2>Matériel</h2>
                <div>
                  <label htmlFor="supplying.notes">Matériel utilisé</label>
                  <Field
                    component="textarea"
                    name="supplying.notes"
                    cols="60"
                    rows="10"
                  />
                </div>
              </section> */}

              <section>
                <h2 id="clientId">Client</h2>
                <UserSelectField
                  name="clientId"
                  users={clients}
                  onUserChange={this.handeClientChange}
                />
                {this.state.isNewClientSelected ? (
                  <ModelFormUI
                    mode="compact"
                    namespace="client"
                    isDebug={isDebug}
                    // onSubmitForm={this.handleNewClientFormSubmit}
                    submitButtonText="Créer un nouveau client"
                  />
                ) : (
                  ""
                )}
                <p style={{ whiteSpace: "pre-line" }}>
                  {utils.getUserInfos(this.state.client)}
                </p>
              </section>

              <section>
                <h2 id="companionId">Plombier</h2>
                <UserSelectField
                  name="companionId"
                  users={companions}
                  onUserChange={this.handeCompanionChange}
                />
                <p style={{ whiteSpace: "pre-line" }}>
                  {utils.getUserInfos(this.state.companion)}
                </p>
              </section>
              <div>
                <label htmlFor="notes">
                  <h3>Compte-rendu</h3>
                </label>
                <Field component="textarea" name="notes" cols="80" rows="10" />
              </div>
              {model.id ? (
                <section>
                  <h2>Infos</h2>
                  <div>
                    <label htmlFor="id">Id</label>
                    <Field type="text" name="id" disabled />
                  </div>

                  <div>
                    <label htmlFor="createdAt">Créé le </label>
                    <Field type="text" name="createdAt" disabled />
                  </div>

                  <div>
                    <label htmlFor="status">Statut </label>
                    <Field type="text" name="status" disabled />
                  </div>
                </section>
              ) : (
                ""
              )}

              {isDebug ? (
                <div>
                  <label htmlFor="isDebug">Is debug?</label>
                  <Field
                    name="isDebug"
                    type="checkbox"
                    defaultChecked={isDebug}
                  />
                </div>
              ) : (
                ""
              )}
              <div>
                <button type="submit" disabled={isSubmitting}>
                  {submitButtonText}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
