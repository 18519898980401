import React from "react";
import Header from "./Header";

class Layout extends React.Component {
  render() {
    return (
      <main>
        <Header auth={this.props.auth} />
        {this.props.children}
      </main>
    );
  }
}

export default Layout;
