import React, { Component } from "react";
import "./App.css";
import "./water.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Layout from "./components/UI/Layout";
import { connect } from "react-redux";
import ScreenLogin from "./screens/Login";
import ScreenLogout from "./screens/Logout";
import PrivateRoute from "./components/UI/PrivateRoute";
import AdminScreen from "./screens/Admin";
import SMSScreen from "./screens/SMS/List";
// Clients
import ClientsScreen from "./screens/Clients/List";
import ScreenClientForm from "./screens/Clients/Form";
import ScreenClientInfos from "./screens/Clients/Infos";
import ScreenClientEdit from "./screens/Clients/Edit";
// Companions
import CompanionsScreen from "./screens/Companions/List";
import ScreenCompanionForm from "./screens/Companions/Form";
import ScreenCompanionInfos from "./screens/Companions/Infos";
import ScreenCompanionEdit from "./screens/Companions/Edit";

// Missions
import MissionsScreen from "./screens/Missions/List";
import ScreenMissionForm from "./screens/Missions/Form";
import ScreenMissionInfos from "./screens/Missions/Infos";
import ScreenMissionEdit from "./screens/Missions/Edit";

class App extends Component {
  render() {
    return (
      <BrowserRouter className="App">
        <Layout auth={this.props.auth}>
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/admin" />} />
            <Route path="/login" component={ScreenLogin} />
            <PrivateRoute
              path="/admin"
              exact
              component={AdminScreen}
              auth={this.props.auth}
            />
            {/* ===== MISSIONS ===== */}
            <PrivateRoute
              path="/missions"
              exact
              component={MissionsScreen}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/missions/form"
              exact
              component={ScreenMissionForm}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/missions/:missionId/infos"
              exact
              component={ScreenMissionInfos}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/missions/:missionId/edit"
              exact
              component={ScreenMissionEdit}
              auth={this.props.auth}
            />
            {/* ===== CLIENTS ===== */}
            <PrivateRoute
              path="/clients"
              exact
              component={ClientsScreen}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/clients/form"
              exact
              component={ScreenClientForm}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/clients/:clientId/infos"
              exact
              component={ScreenClientInfos}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/clients/:clientId/edit"
              exact
              component={ScreenClientEdit}
              auth={this.props.auth}
            />
            {/* ===== COMPANIONS ===== */}
            <PrivateRoute
              path="/companions"
              exact
              component={CompanionsScreen}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/companions/form"
              exact
              component={ScreenCompanionForm}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/companions/:companionId/infos"
              exact
              component={ScreenCompanionInfos}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/companions/:companionId/edit"
              exact
              component={ScreenCompanionEdit}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/smses"
              exact
              component={SMSScreen}
              auth={this.props.auth}
            />
            <Route path="/logout" component={ScreenLogout} />
            <Redirect to="/login" />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};
export default connect(mapStateToProps)(App);
