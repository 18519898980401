import React from "react";
import { connect } from "react-redux";
import { sendSMS, fetchSMSes, fetchSMSReplies } from "../../redux/actions";
import config from "../../config";
import SMSForm from "../../components/UI/SMSes/Form";
import SMSTable from "../../components/UI/SMSes/Table";
class ScreenLogin extends React.Component {
  componentDidMount() {
    this.props.fetchSMSes();
    this.props.fetchSMSReplies();
  }

  handleSMSSubmit = sms => {
    this.props.sendSMS(sms);
  };

  render() {
    return (
      <section>
        <h1>SMS</h1>
        <SMSForm
          onSubmitForm={this.handleSMSSubmit}
          isMock={!config.isProd()}
          isSubmitting={this.props.isSending}
          error={this.props.error}
        />
        <SMSTable smses={this.props.smses} smsReplies={this.props.smsReplies} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    smses: state.smses.items,
    smsReplies: state.smsReplies.items,
    isSending: state.smsesActions.isSending,
    error: state.smsesActions.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSMS: sms => dispatch(sendSMS(sms)),
    fetchSMSes: force => dispatch(fetchSMSes(force)),
    fetchSMSReplies: force => dispatch(fetchSMSReplies(force))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenLogin);
