import { combineReducers } from "redux";
import globals from "./globals";
import stages from "./stages";
import auth from "./auth";
import smses from "./smses";
import smsesActions from "./smsesActions";
import smsReplies from "./smsReplies";
import clients from "./clients";
import companions from "./companions";
import missions from "./missions";

export default combineReducers({
  globals,
  stages,
  auth,
  smses,
  smsesActions,
  companions,
  clients,
  smsReplies,
  missions
});
