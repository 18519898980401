import * as ActionTypes from "../ActionTypes";

export const setGoTo = goTo => {
  return {
    type: ActionTypes.SET_GO_TO,
    goTo: goTo
  };
};

export const resetGoTo = () => {
  return {
    type: ActionTypes.RESET_GO_TO
  };
};
