import React from "react";
import ReactTable from "react-table";
import utils from "../../../utils";
import SMSesModel from "../../../models/smses";
import PhoneNumberHelper from "../../../helpers/PhoneNumberHelper";
import config from "../../../config";
export default class Table extends React.Component {
  render() {
    const smses = new SMSesModel(this.props.smses);
    const smsesWithReplies = smses.hydrateWithSMSReplies(this.props.smsReplies);

    const columns = [
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: row => {
          return utils.formatDatetime(row.value, "rs");
        }
      },
      {
        Header: "Destinataire",
        accessor: "recipient",
        Cell: row => {
          return new PhoneNumberHelper(row.value).format();
        }
      },
      {
        Header: "Contenu",
        accessor: "body",
        style: { whiteSpace: "unset" }
      },
      {
        Header: "Type",
        accessor: "options.type",
        Cell: row => {
          let res = "N/A";
          if (row.value === config.sms.types.WITH_BRAND) {
            res = "Selfcity";
          } else if (row.value === config.sms.types.WITH_REPLIES) {
            res = "Satisfation";
          }
          return res;
        }
      },
      {
        Header: "Statut",
        accessor: "status",
        width: 200
      },
      {
        Header: "Réponse",
        accessor: "smsReplies",
        style: { whiteSpace: "unset" },
        Cell: row => {
          let res = "";
          if (row.value && row.value.length > 1) {
            res = row.value
              .map(val => {
                return (
                  val.body +
                  " (" +
                  utils.formatDatetime(val.answeredAt, "rs") +
                  ")"
                );
              })
              .join("\n");
          }
          return res;
        }
      }
    ];
    return (
      <ReactTable
        data={smsesWithReplies}
        columns={columns}
        defaultPageSize={100}
      />
    );
  }
}
