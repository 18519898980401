import React from "react";

export default class Screen extends React.Component {
  goTo(path) {
    this.props.history.push(path);
  }
  render() {
    return (
      <section>
        <h2>Admin</h2>
        <button
          onClick={() => {
            this.goTo("/missions/form");
          }}
        >
          Nouvelle mission
        </button>
      </section>
    );
  }
}
