import * as ActionTypes from "../ActionTypes";

const initialState = {
  goTo: null
};
export default function inputs(state = initialState, action) {
  switch (action.type) {
    /**
     * GO TO
     */
    case ActionTypes.SET_GO_TO:
      return { ...state, goTo: action.goTo };
    case ActionTypes.RESET_GO_TO:
      return { ...state, goTo: null };
    default:
      return state;
  }
}
