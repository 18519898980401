import * as ActionTypes from "../ActionTypes";
import firebase from "../../firebase";

export const checkAuthSession = () => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.CHECK_AUTH_SESSION });

    const onAuthStateChanged = getState().auth.onAuthStateChanged;

    if (!onAuthStateChanged) {
      firebase.onAuthStateChanged(user => {
        if (user) {
          dispatch(onAuthStateChangedSignedIn(user));
        } else {
          dispatch(onAuthStateChangedSignedOut());
        }
      });
    }
  };
};

const onAuthStateChangedSignedIn = user => {
  return {
    type: ActionTypes.ON_AUTH_STATE_CHANGED_SIGNED_IN,
    user
  };
};
const onAuthStateChangedSignedOut = () => {
  return {
    type: ActionTypes.ON_AUTH_STATE_CHANGED_SIGNED_OUT
  };
};

export const logout = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.LOGOUT
    });
    return firebase.signOut();
  };
};

export const login = (email, password) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.LOGIN_REQUEST,
      email
    });

    return firebase
      .signIn(email, password)
      .then(user => {
        dispatch(loginSuccess(user));
      })
      .catch(err => {
        dispatch(loginFailure(err));
      });
  };
};

const loginSuccess = user => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    user
  };
};
const loginFailure = error => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    error
  };
};
