import * as ActionTypes from "../ActionTypes";

const initialState = {
  isFetching: false,
  itemHasBeenSubmitted: false,
  item: null,
  items: []
};
export default function inputs(state = initialState, action) {
  let clientId = null;
  let client = null;

  switch (action.type) {
    /**
     * INIT
     */
    case ActionTypes.INIT_CLIENT:
      return { ...state, current: action.item, itemHasBeenSubmitted: false };

    /**
     * FETCH
     */
    case ActionTypes.FETCH_CLIENTS_REQUEST:
      return { ...state, isFetching: true, itemHasBeenSubmitted: false };

    case ActionTypes.FETCH_CLIENTS_SUCCESS:
      return { ...state, items: action.items, isFetching: false };

    case ActionTypes.FETCH_CLIENTS_FAILURE:
      return { ...state, isFetching: false };

    /**
     * POST
     */
    case ActionTypes.POST_CLIENT_REQUEST:
      return { ...state, isFetching: true };

    case ActionTypes.POST_CLIENT_SUCCESS:
      state.items[action.item.id] = action.item;
      state.itemHasBeenSubmitted = true;
      state.isFetching = false;
      return { ...state };

    case ActionTypes.POST_CLIENT_FAILURE:
      return { ...state, isFetching: false };

    /**
     * PUT
     */
    case ActionTypes.PUT_CLIENT_REQUEST:
      return { ...state, isFetching: true, item: null };

    case ActionTypes.PUT_CLIENT_SUCCESS:
      state.item = action.item;
      state.itemHasBeenSubmitted = true;
      state.isFetching = false;
      return { ...state };

    case ActionTypes.PUT_CLIENT_FAILURE:
      return { ...state, isFetching: false };

    /**
     * DELETE
     */
    case ActionTypes.DELETE_CLIENT_REQUEST:
      return { ...state, isFetching: true, item: null };

    case ActionTypes.DELETE_CLIENT_SUCCESS:
      clientId = action.item;
      client = state.items[clientId];
      state.itemHasBeenSubmitted = true;
      state.isFetching = false;
      return { ...state, item: client };

    case ActionTypes.DELETE_CLIENT_FAILURE:
      return { ...state, isFetching: false };

    /**
     * SET CURRENT
     */
    case ActionTypes.SET_CURRENT_CLIENT:
      clientId = action.item;
      client = state.items[clientId];
      return { ...state, item: client };

    default:
      return state;
  }
}
