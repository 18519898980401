import React from "react";
import { connect } from "react-redux";
import { setCurrentMission, putMission, resetGoTo } from "../../redux/actions";
import MissionForm from "../../components/UI/Missions/Form";
import utils from "../../utils";
import config from "../../config";

class Screen extends React.Component {
  componentDidMount() {
    const { missionId } = this.props.match.params;
    this.props.setCurrentMission(missionId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log("nextProps.goto", nextProps.goTo);
    if (nextProps.goTo) {
      this.props.history.push(nextProps.goTo);
      this.props.resetGoTo();
    }
    return true;
  }

  handleFormSubmit = form => {
    this.props.putMission(form);
  };

  render() {
    return (
      <section>
        <h1>Mettre à jour</h1>

        <MissionForm
          onSubmitForm={this.handleFormSubmit}
          model={this.props.mission}
          clients={this.props.clients}
          companions={this.props.companions}
          isDebug={!config.isProd()}
          submitButtonText="Mettre à jour"
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    mission: state.missions.item,
    clients: utils.getSortedArrayCollection(state.clients.items),
    companions: utils.getSortedArrayCollection(state.companions.items),
    goTo: state.globals.goTo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentMission: missionId => dispatch(setCurrentMission(missionId)),
    putMission: form => dispatch(putMission(form, "/missions")),
    resetGoTo: () => dispatch(resetGoTo())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
