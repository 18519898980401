import defaultConfig from "./default";
import production from "./production";
import development from "./development";
import local from "./local";
import version from "./version";

const CONFIG_PRODUCTION = "PRODUCTION";
const CONFIG_DEVELOPMENT = "DEVELOPMENT";
const CONFIG_LOCAL = "LOCAL";

let config = defaultConfig;
const env = process.env.REACT_APP_SELFCITY_ENV;

if (env === CONFIG_PRODUCTION) {
  config = production;
} else if (env === CONFIG_DEVELOPMENT) {
  config = development;
} else if (env === CONFIG_LOCAL) {
  config = local;
}

config.isProd = () => {
  return process.env.REACT_APP_SELFCITY_ENV === CONFIG_PRODUCTION;
};

config.isDev = () => {
  return process.env.REACT_APP_SELFCITY_ENV === CONFIG_DEVELOPMENT;
};

config.isLocal = () => {
  return process.env.REACT_APP_SELFCITY_ENV === CONFIG_LOCAL;
};

config.getEnv = () => {
  return process.env.REACT_APP_SELFCITY_ENV;
};

export default { ...config, ...version };
