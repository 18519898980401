import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentClient, sendSMS, deleteClient } from "../../redux/actions";
import ModelInfos from "../../components/UI/Model/Infos";
import SMSForm from "../../components/UI/SMSes/Form";

class Screen extends React.Component {
  componentDidMount() {
    const { clientId } = this.props.match.params;
    this.props.setCurrentClient(clientId);
  }

  handleSMSSubmit = sms => {
    this.props.sendSMS(sms);
  };

  handleDeleteClient = clientId => {
    this.props.deleteClient(clientId);
  };

  render() {
    return (
      <section>
        <h1>Infos</h1>

        <ModelInfos model={this.props.client} />
        <Link to={`/clients/${this.props.match.params.clientId}/edit`}>
          Editer
        </Link>
        <button
          onClick={() => {
            this.handleDeleteClient(this.props.match.params.clientId);
          }}
        >
          Delete
        </button>
        <SMSForm
          onSubmitForm={this.handleSMSSubmit}
          client={this.props.client}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return { client: state.clients.item };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSMS: sms => dispatch(sendSMS(sms)),
    deleteClient: clientId => dispatch(deleteClient(clientId)),
    setCurrentClient: clientId => dispatch(setCurrentClient(clientId))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
