import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentCompanion, fetchCompanions } from "../../redux/actions";
import utils from "../../utils";
import ReactTable from "react-table";
import moment from "moment";
class Screen extends React.Component {
  componentDidMount() {
    this.props.fetchCompanions();
  }

  goToCompanionEdit(companionId) {
    this.props.history.push("/companions/" + companionId + "/edit");
  }
  goToCompanionInfos(companionId) {
    this.props.history.push("/companions/" + companionId + "/infos");
  }

  render() {
    const columns = [
      {
        Header: "#",
        Cell: row => row.index + 1
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: row => {
          return moment(row.value).format("DD/MM HH:mm");
        }
      },
      {
        Header: "Prénom",
        accessor: "firstName"
      },
      {
        Header: "Nom",
        accessor: "lastName"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Téléphone",
        accessor: "phoneNumber"
      },
      {
        Header: "Adresse",
        accessor: "address",
        Cell: row => {
          return utils.fromAddressToString(row.value);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 200,
        Cell: row => {
          const companionId = row.original.id;
          return (
            <div>
              {row.value}
              <button
                onClick={() => {
                  this.goToCompanionInfos(companionId);
                }}
              >
                See
              </button>
              <button
                onClick={() => {
                  this.goToCompanionEdit(companionId);
                }}
              >
                Edit
              </button>
            </div>
          );
        }
      }
    ];
    return (
      <section>
        <h1>Plombiers</h1>
        <Link to={`${this.props.match.url}/form`}>Nouveau</Link>
        <ReactTable data={this.props.companions} columns={columns} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    companions: utils.getSortedArrayCollection(state.companions.items)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentCompanion: companionId =>
      dispatch(setCurrentCompanion(companionId)),

    fetchCompanions: force => dispatch(fetchCompanions(force))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
