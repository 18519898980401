import React from "react";
import { connect } from "react-redux";
import {
  postMission,
  fetchClients,
  fetchCompanions,
  resetGoTo
} from "../../redux/actions";
import MissionForm from "../../components/UI/Missions/Form";
import utils from "../../utils";
import config from "../../config";
class Screen extends React.Component {
  componentDidMount() {
    this.props.fetchClients();
    this.props.fetchCompanions();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.goTo) {
      this.props.history.push(nextProps.goTo);
      this.props.resetGoTo();
    }
    return true;
  }

  handleFormSubmit = form => {
    this.props.postMission(form);
  };

  render() {
    return (
      <section>
        <h1>Nouvelle mission</h1>

        <MissionForm
          onSubmitForm={this.handleFormSubmit}
          clients={this.props.clients}
          companions={this.props.companions}
          isDebug={!config.isProd()}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    clients: utils.getSortedArrayCollection(state.clients.items),
    companions: utils.getSortedArrayCollection(state.companions.items),
    goTo: state.globals.goTo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postMission: form => dispatch(postMission(form, "/missions")),
    fetchClients: force => dispatch(fetchClients(force)),
    fetchCompanions: force => dispatch(fetchCompanions(force)),
    resetGoTo: () => dispatch(resetGoTo())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
