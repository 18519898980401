module.exports = {
  status: {
    CREATED: "CREATED",
    BANNED: "BANNED",
    DELETED: "DELETED"
  },
  missions: {
    nextStepsArray: [
      "Devis en cours",
      "Devis envoyé",
      "Devis refusé",
      "Intervention planifiée",
      "Intervention terminée",
      "Intervention payée"
    ]
  },
  NEW_USER_SELECTED: "NEW_USER_SELECTED",
  statusIds: {
    CREATED: 1,
    BANNED: -1,
    DELETED: -2
  }
};
