import * as ActionTypes from "../ActionTypes";

const initialState = {
  isSending: false,
  item: null,
  error: null
};
export default function inputs(state = initialState, action) {
  switch (action.type) {
    /**
     * SEND SMS
     */
    case ActionTypes.SEND_SMS_REQUEST:
      return { ...state, isSending: true, item: null, error: null };

    case ActionTypes.SEND_SMS_SUCCESS:
      return { ...state, item: action.item, isSending: false };

    case ActionTypes.SEND_SMS_FAILURE:
      return { ...state, isSending: false, error: action.error };

    default:
      return state;
  }
}
