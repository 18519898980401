import moment from "moment";
export default class Utils {
  // Remove undefined values
  static cleanObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * {
   *  id1: {...},
   *  id2: {...}
   * }
   * ==>
   * [ { id: "id1", ...}, { id: "id2", ...} ]
   *
   * @param {Object[]} items
   */
  static fromNormalizedtoArray(items) {
    const keys = Object.keys(items);
    const array = keys.map(k => {
      return { id: k, ...items[k] };
    });
    const sortedArray = array.sort((a, b) => {
      return a.createdAt < b.createdAt;
    });
    return sortedArray;
  }
  static fromArraytoNormalized(array) {
    const result = {};
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const id = item.id;
      result[id] = item;
    }
    return result;
  }

  static sortArrayCollection(arrayCollection) {
    const groupedByStatus = arrayCollection.reduce(
      (arr, val) => {
        /**
         * arr[0] contains the first values
         * arr[1] the last one (deleted and banned)
         */
        if (val.statusId < 0) {
          arr[1].push(val);
        } else {
          arr[0].push(val);
        }
        return arr;
      },
      [[], []]
    );
    // Sort separately the two arrays
    groupedByStatus[0] = Utils.sortBy(groupedByStatus[0], "createdAt");
    groupedByStatus[1] = Utils.sortBy(groupedByStatus[1], "createdAt");
    const sortedArray = [...groupedByStatus[0], ...groupedByStatus[1]];
    return sortedArray;
  }

  static sortByStatus(arr) {
    return arr.sort((a, b) => {
      return a.status > b.status;
    });
  }

  static sortBy(arr, field) {
    return arr.sort((a, b) => {
      return a[field] > b.field;
    });
  }

  static getSortedArrayCollection(collection) {
    const array = Utils.fromNormalizedtoArray(collection);
    const sortedArray = Utils.sortArrayCollection(array);

    return sortedArray;
  }

  static getRandomItem(items) {
    const length = items.length;
    const idx = Math.floor(Math.random() * length);
    return items[idx];
  }

  static fromAddressToString(address) {
    let res = "";
    if (address) {
      res = `${address.line1 || ""}
      ${address.line2 || ""}
      ${address.postalCode || ""}
      ${address.city || ""}`;
    }
    return res;
  }

  /**
   * DEPRECATED
   */
  static fromUserToString(user) {
    let res = "";
    if (user) {
      res = `${user.firstName} ${user.lastName}`;
    }
    return res;
  }

  static getUserFullName(user) {
    let res = "";
    if (user) {
      res = user.name || (user.firstName || "") + " " + (user.lastName || "");
    }
    return res;
  }

  static getUserFullNameAndPhoneNumber(user) {
    let res = "";
    if (user) {
      res = Utils.getUserFullName(user) + " - " + user.phoneNumber;
    }
    return res;
  }

  static getUserInfos(user) {
    let res = "";
    if (user) {
      res = Utils.getParamPlusBreakLine(Utils.getUserFullName(user));
      res += Utils.getParamPlusBreakLine(user.email);
      res += Utils.getParamPlusBreakLine(user.phoneNumber);
      res += Utils.getParamPlusBreakLine(
        Utils.fromAddressToString(user.address)
      );
    }
    return res;
  }

  static getParamPlusBreakLine(param) {
    if (param) {
      return param + "\n";
    }
    return "";
  }
  static formatDateWithTime(date, time) {
    let res = "";
    if (date && time) {
      const parsedTime = Utils.parseTime(time);
      res = moment(date + " " + parsedTime).format();
    }
    return res;
  }

  /**
   * Return 14:35
   */
  static formatTime(date) {
    let res = "";
    if (date) {
      res = moment(date).format("HH[:]mm");
    }
    return res;
  }

  static formatDate(date, mode) {
    let res = "";
    if (date) {
      res = moment(date).format("YYYY-MM-DD");
      if (mode === "r") {
        res = moment(date).format("DD-MM-YYYY");
      } else if (mode === "s") {
        res = moment(date).format("MM-DD");
      } else if (mode === "sr" || mode === "rs") {
        res = moment(date).format("DD-MM");
      }
    }
    return res;
  }

  /**
   *
   * @param {*} date
   * @param {*} mode
   * - default  - 2019-04-30 14h35
   * - s        - 04-30 14h35
   * - r        - 14h35 30-04-2019
   * - rs       - 30-04 14h35
   * - iso      - 2019-04-30T14:35:00+002
   * - rsd      - 30-04
   * - t        - 14h35
   */
  static formatDatetime(date, mode) {
    let res = "";
    if (date) {
      let format = "YYYY-MM-DD HH:mm";
      if (mode === "r") {
        format = "HH:mm DD-MM-YYYY";
      } else if (mode === "s") {
        format = "MM-DD HH:mm";
      } else if (mode === "sr" || mode === "rs") {
        format = "DD-MM HH:mm";
      } else if (mode === "iso") {
        format = "";
      } else if (mode === "rsd") {
        // Reverse Short Date
        format = "DD-MM";
      } else if (mode === "t") {
        // Time
        format = "HH:mm";
      }
      try {
        res = moment(date).format(format);
      } catch (e) {
        console.log(e);
        res = "";
      }
    }
    return res;
  }

  /**
   * From
   * - 13
   * - 13h
   * - 13h00
   *
   * Returns
   * -13:00
   * @param {*} time
   */
  static parseTime(time) {
    let res = "";
    if (time) {
      res = time;
      if (time.length === 2) {
        res += "h00";
      } else if (time.length === 3) {
        res += "00";
      }
      res = res.replace("h", ":");
    }
    return res;
  }
}
